import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/User/Header";
import UserFooter from "../../../components/User/UserFooter";
import { X, ArrowLeft } from "lucide-react";
import EditButton from "../../../components/General/EditButton";
import AccountInfoForm from "../../../components/User/AccountInfoForm";
import SideNavUserProfile from "../../../components/User/SideNavUserProfile";

const AccountInfo: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleEditClick = () => {
    //logic here
  };

  return (
    <>
      {/* Desktop (>=780px) */}
      <div className="hidden mq780:flex flex-col">
        <Header />
        <div className="flex flex-row flex-1">
          <SideNavUserProfile activeItem="Payment Methods"/>
          <div style={{ width: 'calc(100vw - 350px)' }}>
            <div className="min-h-[90vh] mr-10 flex flex-col font-subhead mt-16">
                <div className="w-full flex flex-col items-center justify-center">
                    <h2 className="w-auto max-w-[80vw] text-5xl text-blue-900 font-semibold text-center leading-9">You have no payment methods saved.</h2>
                    <p className="w-auto max-w-[400px] text-lg mx-8 font-semilight text-zinc-500 text-center leading-7">Make bookings with one easy click when you have a payment method saved to your account.</p>
                    <button className="w-64 mt-6 font-subhead text-lg border border-solid border-[#478abf] text-[#478abf] bg-white font-semibold px-4 py-3 rounded-lg">+ Add Payment Method</button>
                </div>
            </div>
          </div>
        </div>
        <footer>
          <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>
      </div>

      {/* Mobile (<780px) */}
      <div className="mq780:hidden w-full relative bg-white overflow-hidden flex flex-col justify-between px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead min-h-screen">
        <div className="flex justify-between items-center px-4 mt-3 mb-1">
            <button onClick={handleBackClick} className="text-gray-500 pt-2 bg-white">
            <ArrowLeft className='w-7 h-7 stroke-gray-300' />
            </button>
            <h2 className="text-xl font-semibold">Payments</h2>
            <ArrowLeft className='w-7 h-7 stroke-white' />
        </div>
        <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>

        <div className="min-h-[90vh] flex flex-col mt-16">
            <div className="w-full flex flex-col items-center justify-center">
                <h2 className="w-auto max-w-[80vw] text-5xl text-blue-900 font-semibold text-center leading-9">You have no payment methods saved.</h2>
                <p className="w-auto max-w-[400px] text-lg mx-8 font-semilight text-zinc-500 text-center leading-7">Make bookings with one easy click when you have a payment method saved to your account.</p>
                <button className="w-64 mt-6 font-subhead text-lg border border-solid border-[#478abf] text-[#478abf] bg-white font-semibold px-4 py-3 rounded-lg">+ Add Payment Method</button>
            </div>
        </div>
        <footer>
            <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
            />
        </footer>
      </div>
    </>
  );
};

export default AccountInfo;