import { FunctionComponent, useState } from "react";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import SideNav from "../../../components/User/SideNav";
import UserFooter from "../../../components/User/UserFooter";
import Header from "../../../components/User/Header";
import Rounder from "../../../components/General/Rounder";
import MobileRounderDynamic from "../../../components/Mobile/MobileRounderDynamic";
import Map from "../../../components/General/Map";
import { ChevronDown, SlidersHorizontal } from "lucide-react";
import AccountItems from "../../../components/User/AccountItems";

interface SearchBarProps {
  placeholder: string;
  iconSrc: string;
  id?: string,
  onClick: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  iconSrc,
  id,
  onClick,
}) => {
  return (
    <div className="flex-grow min-w-[150px] flex items-center mr-2 px-2 mt-1 bg-white rounded-lg border border-blue-900 border-solid">
      <img
        loading="lazy"
        src={iconSrc}
        alt="Search icon"
        className="shrink-0 w-6 h-6 mt-0 [border:none] [outline:none]"
        crossOrigin="anonymous"
      />
      <input
        type="text"
        placeholder={placeholder}
        className="cursor-text [border:none] [outline:none] pt-1 flex-grow px-2 text-base leading-7 text-blue-900 bg-transparent outline-none min-w-[100px]"
        id={id}
        name="search"
      />
      <SlidersHorizontal onClick={onClick} className="stroke-blue-900 w-6 h-6 mq780:hidden" />
    </div>
  );
};

const UserSettings: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sortOption, setSortOption] = useState('Sort by');
  const [isMapVisible, setIsMapVisible] = useState(true);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  const offers = [
    { id: 1, name: 'Offer', photo:'apple-picking@2x.png', price: 19.99, lat: 26.4433, lng: -82.1115, categories:"Nature • Outdoors" },
    { id: 2, name: 'Karting', photo:'Karting.png', price: 59.99, lat: 26.4363, lng: -82.1260, categories:"Sports • Outdoors" },
    // More locations
  ]

  return (
    <>
    {/* Desktop (>=780px) */}
    <div className="hidden mq780:flex flex flex-col">
      <Header />
      <main className="flex-1 flex min-h-screen font-subhead flex-row">

      </main>
      <footer className="mt-auto">
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>
    </div>








    {/* Mobile (<780px) */}
    <div className="mq780:hidden w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
      <AccountItems />
      <footer className="mt-auto">
        {/* This will push the footer to the bottom */}
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>
    </div>
    </>
  );
};

export default UserSettings;
