import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { fetchMerchantAnalytics } from '../../api/stripe/stripeApi';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { loadConnectAndInitialize } from '@stripe/connect-js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "");

const StripeDashboard: React.FC = () => {
  const [analyticsData, setAnalyticsData] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState('');
  const [stripeConnectInstance, setStripeConnectInstance] = useState<any>(null);

  useEffect(() => {
    const loadAnalytics = async () => {
      try {
        const data = await fetchMerchantAnalytics();
        setAnalyticsData(data);
      } catch (error) {
        console.error('Failed to load analytics:', error);
      }
    };
    loadAnalytics();

    const fetchClientSecret = async () => {
      const jwtToken = localStorage.getItem('jwtToken');
      try {
        const response = await fetch(`${API_BASE_URL}/v1/merchants/account-session`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            components: {
              payments: {
                enabled: true
              }
            }
          })
        });
        if (!response.ok) {
          throw new Error('Failed to fetch account session');
        }
        const data = await response.text();
        // // console.log("data.trim(): ",data.trim())
        return data.trim();
      } catch (error) {
        console.error('Failed to fetch account session:', error);
        return '';
      }
    };

    const initializeStripeConnect = async () => {
      const instance = await loadConnectAndInitialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
        fetchClientSecret: fetchClientSecret,
        appearance: {
          variables: {
            colorPrimary: '#478abf', // Set accent color to #478abf
            fontFamily: "'font-subhead', sans-serif", // Use font-subhead class
          },
        },
      });
      setStripeConnectInstance(instance);
    };

    initializeStripeConnect();
  }, []);

  useEffect(() => {
    if (stripeConnectInstance) {
      const container = document.getElementById('stripe-connect-container');
      if (container) {
        const paymentComponent = stripeConnectInstance.create('payments');
        container.appendChild(paymentComponent);
      }
    }
  }, [stripeConnectInstance]);

  if (!analyticsData) {
    return <div className="font-subhead">Loading analytics...</div>;
  }

  // // console.log("clientSecret: ",clientSecret)

  return (
    <Elements stripe={stripePromise}>
      <div className="p-4 font-subhead text-left"> {/* Added font-subhead and text-left classes */}
        <h2 className="text-2xl font-bold mb-6">Merchant Analytics Dashboard</h2>
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
          {/* Revenue Over Time */}
          <div className="lg:col-span-2">
            <h3 className="text-xl font-semibold mb-4">Revenue Over Time</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={analyticsData.revenue}>
                <XAxis dataKey="date" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="amount" name={`Amount (${analyticsData.defaultCurrency})`} stroke="#478abf" /> {/* Changed stroke color to #478abf */}
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Stripe Connect Component */}
          <div className="lg:col-span-1">
            <h3 className="text-xl font-semibold mb-4">Payments</h3>
            <div id="stripe-connect-container"></div>
          </div>
        </div>
      </div>
    </Elements>
  );
};

export default StripeDashboard;