import React from 'react';
import { Trash } from 'lucide-react';

type ConfirmationPopupProps = {
  title?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({ title, onConfirm, onCancel }) => {
  return (
    <div className="font-subhead fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-sm w-full">
        <div className="w-16 h-16 bg-blue-500 rounded-full mx-auto mb-4 items-center justify-center flex">
            <Trash className='stroke-white h-10 w-10' />
        </div>
        <h2 className="text-2xl text-center text-blue-500 mb-4">{title ? title : "Are you sure you want to delete this offer?"}</h2>
        <div className="w-full flex items-center justify-center gap-4 pt-6">
          <button onClick={onCancel} className="px-4 cursor-pointer font-semibold w-32 py-3 border border-blue-900 bg-white text-blue-900 rounded">No</button>
          <button onClick={onConfirm} className="px-4 cursor-pointer font-semibold w-32 py-3 bg-blue-900 text-white rounded">Yes</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;