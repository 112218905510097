import React, { createContext, useState, useContext, useCallback } from 'react';
import { BusinessSignUpData } from '../../../api/BusinessSignup';

interface MerchantSignUpContextType {
    formData: Partial<BusinessSignUpData>;
    updateFormData: (data: Partial<BusinessSignUpData>) => void;
  }

const MerchantSignUpContext = createContext<MerchantSignUpContextType | undefined>(undefined);

export const MerchantSignUpProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [formData, setFormData] = useState<Partial<BusinessSignUpData>>({});

  const updateFormData = useCallback((newData: Partial<BusinessSignUpData> | ((prevData: Partial<BusinessSignUpData>) => Partial<BusinessSignUpData>)) => {
    setFormData(prevData => {
      const updatedData = typeof newData === 'function' ? newData(prevData) : { ...prevData, ...newData };
      return updatedData;
    });
  }, []);

  return (
    <MerchantSignUpContext.Provider value={{ formData, updateFormData }}>
      {children}
    </MerchantSignUpContext.Provider>
  );
};

export const useMerchantSignUp = () => {
  const context = useContext(MerchantSignUpContext);
  if (context === undefined) {
    throw new Error('useMerchantSignUp must be used within a MerchantSignUpProvider');
  }
  return context;
};