import React, { useState } from 'react';

interface CheckBoxProps {
    text: string;
  }

const CheckBoxText: React.FC<CheckBoxProps> = ({text}) => {
  const [selected, setSelected] = useState<boolean>(false);

  const handleRadioChange = () => {
    setSelected(!selected);
  };

  return (
    <div className="flex items-center space-x-2">
      <div className="relative pb-5 pr-7">
        <input
          type="checkbox"
          checked={selected}
          onChange={handleRadioChange}
          className="cursor-pointer absolute w-4 h-4 opacity-100"
        />
      </div>
      <span>{text}</span>
    </div>
  );
};

export default CheckBoxText;