import { FunctionComponent, useCallback, useEffect, useState, memo } from "react";
import { useNavigate } from 'react-router-dom';
import { useWindowWidth } from "../../hooks/useWindowWidth";

const QuizBlock: FunctionComponent = memo(() => {
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const imq1050obile = windowWidth < 1050;
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = '/background@2x.png';
    img.onload = () => setBackgroundImage('/background@2x.png');
  }, []);

  const onNavigateToMap = useCallback(() => {
    navigate("/map");
  }, [navigate]);

  const commonClasses = "px-[5%] flex flex-col items-start justify-start py-[20px] relative gap-[20px] text-left text-white font-subhead";
  const desktopClasses = `hidden mq1050:flex ${commonClasses} mq750:gap-[20px] mq750:py-[40px] mq750:px-[25px] mq1225:px-[50px]`;
  const mobileClasses = `mq1050:hidden ${commonClasses} py-[20px]`;

  const backgroundStyle = backgroundImage
    ? {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
      }
    : {
        backgroundColor: 'rgb(228, 228, 231)',
      };

  const DesktopContent = (
    <div className="flex flex-col items-start justify-start gap-[31px] max-w-full mq750:gap-[15px_31px] py-4 relative z-10">
      <h1 className="m-0 w-full relative text-41xl leading-[80px] font-bold tracking-tight mq1050:text-[48px] mq1050:leading-[60px] mq750:text-[50px] mq750:leading-[50px] mq450:text-[40px] mq450:leading-[40px]">
        Personalized adventures<br/>await
      </h1>
      <div className="flex flex-row gap-3">
        <h2 className="m-0 w-[70%] relative text-11xl leading-[50px] font-semibold tracking-[-0.01em] mq1050:text-[30px] mq1050:leading-[40px] mq750:text-[25px] mq750:leading-[35px] mq450:text-[20px] mq450:leading-[30px]">
          Discover more while<br/>spending less!
        </h2>
        <button
          className="flex cursor-pointer border-none py-[10px] ml-[5vw] mt-0 pr-[73px] pl-[74px] bg-coral rounded-md items-center justify-center whitespace-nowrap z-2 hover:bg-chocolate mq450:pl-5 mq450:pr-5"
          onClick={onNavigateToMap}
        >
          <b className="relative py-0 text-5xl font-bold text-white mq450:text-[20px]">
            Start
          </b>
        </button>
      </div>
    </div>
  );

  const MobileContent = (
    <div className="flex flex-col items-start justify-start gap-8 max-w-full relative z-10">
      <h1 className="m-0 w-full relative text-6xl leading-tight font-bold tracking-tight">
        Personalized adventures await
      </h1>
      <div className="flex flex-col mq780:flex-row gap-8">
        <h2 className="m-0 w-full relative text-xl leading-snug font-semibold tracking-[-0.01em]">
          Discover more while spending less!
        </h2>
        <button
          className="cursor-pointer border-none font-subhead py-3 px-6 bg-coral rounded-md flex items-center justify-center whitespace-nowrap hover:bg-chocolate transition-colors duration-300 text-mq1050 font-bold text-white w-full"
          onClick={onNavigateToMap}
        >
          Start
        </button>
      </div>
    </div>
  );

  return (
    <section className={`${imq1050obile ? mobileClasses : desktopClasses} relative`} style={backgroundStyle}>
      <div className="absolute inset-0 bg-black bg-opacity-40" aria-hidden="true"></div>
      {imq1050obile ? MobileContent : DesktopContent}
    </section>
  );
});

QuizBlock.displayName = 'QuizBlock';

export default QuizBlock;