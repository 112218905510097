import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, MapPin, ArrowLeft, ChevronRight } from "lucide-react";

interface AccountItem {
  label: string;
  onClick: () => void;
}


const AccountMenuMobile: React.FC = ({}) => {

  const navigate = useNavigate();  

  const onSupportClick = () => {
    window.location.href = `mailto:andrew@doyouweekend.com?subject=USER%20SUPPORT%20REQUEST`;
  };

  const [AccountItems, setAccountItems] = useState<AccountItem[]>([
    { label: 'Profile', onClick: () => navigate("/account/profile") },
    { label: 'Bookings', onClick: () => navigate("/account/bookings") },
    // { label: 'Notifications', onClick: () => navigate("/account/notifications") },
    //{ label: 'Preferences', onClick: () => navigate("/account/preferences") },
    // { label: 'Payment Methods', onClick: () => navigate("/account/payments") },
    { label: 'Support', onClick: onSupportClick },
    { label: 'Privacy', onClick: () => navigate("/account/privacy") },
    { label: 'Close Account', onClick: () => navigate("/account/close-account") },
  ]);

  const handleBackClick = () => {
    navigate("/");
  };

  const toggleOption = (index: number) => {
    setAccountItems(prevOptions =>
      prevOptions.map((option, i) =>
        i === index ? { ...option, isOpen: !option.onClick } : option
      )
    );
  };

  const handleSignOutClick = () => {
    localStorage.removeItem('jwtToken');
    navigate("/");
  };

  return (
    <div className="w-screen bg-white p-0 pb-8 shadow-md relative overflow-y-auto absolute">
      <div className="flex justify-between items-center px-4 mb-2 mt-3">
        <button onClick={handleBackClick} className="text-gray-500 pt-2 bg-white">
          <ArrowLeft className='w-7 h-7 stroke-gray-300' />
        </button>
        <h2 className="text-xl font-semibold">Account</h2>
        <ArrowLeft className='w-7 h-7 stroke-white' />
      </div>
      <div className="space-y-6 px-4">
        {AccountItems.map((option, index) => (
          <>
          <div className='h-[0.5px] bg-zinc-300 w-full' />
          <div onClick={option.onClick} key={option.label} className="text-xl bg-white pb-0">
            <button
              className="flex justify-between items-center bg-white w-full text-left"
              onClick={() => toggleOption(index)}
            >
              <span className="text-main-teal text-5xl font-semibold">{option.label}</span>
              <ChevronRight className={`cursor-pointer text-main-teal transition-transform`} size={45} />
            </button>
          </div>
          </>
        ))}
      </div>
      <button onClick={handleSignOutClick} className="w-50 mt-16 mb-8 bg-white text-cyan-600 border border-blue-400 rounded-md border-2 py-2 text-xl font-semibold px-5">
        Sign out
      </button>
    </div>
  );
};

export default AccountMenuMobile;