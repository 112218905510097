import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactNode } from "react";

export type CarousselType = {
  title?: string;
  filterButton?: boolean;
  activities: ReactNode[];
  onActivityClick?: () => void;
  onLoadMore?: () => Promise<void>;
  hasMore?: boolean;
};

const Caroussel: FunctionComponent<CarousselType> = ({
  title,
  filterButton = false,
  activities,
  onActivityClick,
  onLoadMore,
  hasMore = false,
}) => {
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(0);
  const [visibleActivities, setVisibleActivities] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const activityWidth = 285;
      const containerWidth = window.innerWidth - 230;
      const newVisibleActivities = Math.floor(containerWidth / activityWidth);
      setVisibleActivities(newVisibleActivities);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onArrowFrontClick = async () => {
    if (startIndex + visibleActivities >= activities.length && hasMore && onLoadMore) {
      setIsLoading(true);
      await onLoadMore();
      setIsLoading(false);
      setStartIndex(startIndex + 1);
    } else {
      setStartIndex((prevIndex) =>
        prevIndex + 1 < activities.length - visibleActivities + 1 ? prevIndex + 1 : 0
      );
    }
  };

  const onArrowBackClick = () => {
    setStartIndex((prevIndex) =>
      prevIndex - 1 >= 0 ? prevIndex - 1 : activities.length - visibleActivities
    );
  };

  return (
    <div className="flex flex-col justify-center items-center gap-5 max-w-full overflow-x-none">
      <div className="w-full px-5 lg:px-10"> {/* Container for alignment */}
        <div className="ml-9 flex gap-16 items-center pt-8"> {/* Flex container for title and button */}
          <h1 className="mb-0 text-main-teal text-29xl tracking-[-0.01em] leading-[72px] font-semibold font-inherit _mq1340:text-[40px] _mq1050:text-[30px] _mq1050:leading-[40px] _mq750:text-[25px] _mq750:leading-[35px] _mq450:text-[24px] _mq450:leading-[30px]">
            {title}
          </h1>
          {filterButton && (
            <button 
              onClick={() => navigate("/map")} 
              className="cursor-pointer bg-transparent"
            >
              <div className="mt-10 rounded-lg bg-white flex items-center justify-center py-[8px] px-[25px] border-[1px] border-solid border-steelblue">
                <div className="relative text-base tracking-[-0.01em] leading-[15px] font-medium text-main-teal text-center">
                  View All
                </div>
              </div>
            </button>
          )}
        </div>
      </div>

      <div className="caroussel-container flex flex-row max-w-full gap-5">
        <div className="h-[141.6px] w-5 shrink-0 flex flex-col items-start justify-start pt-[107px] px-0 pb-0 box-border">
          <img
            className={`${startIndex > 0 ? "" : "opacity-0"} cursor-pointer w-5 h-[34.6px] relative z-[3]`}
            loading="lazy"
            alt=""
            src="/arrow-back.svg"
            onClick={onArrowBackClick}
            crossOrigin="anonymous"
          />
        </div>
        {activities
          .slice(startIndex, startIndex + visibleActivities)
          .map((activity, index) => (
            <div
              key={index}
              className="activity-item"
              onClick={onActivityClick}
            >
              {activity}
            </div>
          ))}
        <div className="h-[141.6px] w-5 shrink-0 flex flex-col items-start justify-start pt-[107px] px-0 pb-0 box-border">
          {isLoading ? (
            <div className="w-5 h-[34.6px] flex items-center justify-center">
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-main-teal" />
            </div>
          ) : (
            <img
              className="cursor-pointer w-5 h-[34.6px] relative z-[3]"
              loading="lazy"
              alt=""
              src="/arrow-front.svg"
              onClick={onArrowFrontClick}
              crossOrigin="anonymous"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Caroussel;