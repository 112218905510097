import React, { useState, useEffect } from "react";
import EmptyCarouselState, { CarouselVariant } from "./EmptyCarouselState";

const RounderSkeleton: React.FC = () => (
  <div className="py-2 self-stretch w-[285px] shrink-0 flex flex-col items-start justify-start box-border">
    <div className="self-stretch flex-1 flex flex-col items-start justify-start">
      <div className="animate-pulse bg-zinc-300 rounded-sm h-[250px] w-full" />
      <div className="self-stretch flex flex-col items-start justify-start py-2 px-0 gap-[8px]">
        <div className="animate-pulse bg-zinc-300 rounded h-6 w-3/4" />
        <div className="animate-pulse bg-zinc-300 rounded h-4 w-1/2" />
        <div className="animate-pulse bg-zinc-300 rounded h-4 w-1/4" />
      </div>
    </div>
  </div>
);

interface CarouselContentProps {
    isLoading: boolean;
    error: string | null;
    data: any[];
    emptyStateType: CarouselVariant;
    renderItems: (data: any[]) => React.ReactNode;
}

const CarouselContent: React.FC<CarouselContentProps> = ({
    isLoading,
    error,
    data,
    emptyStateType,
    renderItems,
}) => {
    const [visibleSkeletons, setVisibleSkeletons] = useState(5);

    useEffect(() => {
        const handleResize = () => {
            const activityWidth = 285; // Width of a single activity item
            const containerWidth = window.innerWidth - 230;
            const newVisibleSkeletons = Math.floor(containerWidth / activityWidth);
            setVisibleSkeletons(newVisibleSkeletons);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const contentWrapper = (content: React.ReactNode) => (
        <div className="flex-1 flex flex-col items-center justify-start py-8 px-0 box-border gap-[26px] max-w-full text-left text-mini-2">
            <div className="self-stretch flex flex-col items-center justify-center gap-[19px] max-w-full">
                {content}
            </div>
        </div>
    );

    if (isLoading) {
        return contentWrapper(
            <div className="caroussel-container flex flex-row max-w-full gap-5">
                <div className="h-[141.6px] w-5 shrink-0" />
                {Array.from({ length: visibleSkeletons }).map((_, index) => (
                    <RounderSkeleton key={index} />
                ))}
                <div className="h-[141.6px] w-5 shrink-0" />
            </div>
        );
    }

    if (error) {
        return contentWrapper(
            <div className="w-full min-h-[280px] flex items-center justify-center">
                <div className="text-gray-600 text-center">
                    <span>{error}</span>
                </div>
            </div>
        );
    }

    if (!data || data.length === 0) {
        return contentWrapper(<EmptyCarouselState type={emptyStateType} />);
    }

    return contentWrapper(renderItems(data));
};

export default CarouselContent;