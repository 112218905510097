import React, { FunctionComponent, useState, useEffect, useCallback } from "react";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import SideNavPost from "../../components/Merchant/SideNavPost";
import { useNavigate, useParams } from "react-router-dom";
import CompanyCard from "../../components/Merchant/CompanyCard";
import { ChevronLeft, X } from "lucide-react";
import { CreateOfferRequest } from "../../api/OfferCreation";
import { CreatePromotionRequest } from "../../api/PromotionCreation";
import axios from "axios";
import imageCompression from 'browser-image-compression';
import { FullScreenImagePreview } from "../../components/General/FullScreenImagePreview";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

const MerchantHubPost2: FunctionComponent = () => {
  const navigate = useNavigate();
  const [offerData, setOfferData] = useState<Partial<CreateOfferRequest>>({});
  const [promotionData, setPromotionData] = useState<Partial<CreatePromotionRequest>>({});
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [dragOver, setDragOver] = useState(false);
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);
  const { offerId } = useParams<{ offerId?: string }>();
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const fetchOfferData = useCallback(async () => {
    if (offerId) {
      const savedImages = localStorage.getItem("uploadedImages");
      let offerData;
      try {
        if (!savedImages) {
          const offerResponse = await axios.get(`${API_BASE_URL}/v1/catalog/offers/${offerId}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
          });
          setUploadedImages(offerResponse.data.images);
        } else {
          offerData = localStorage.getItem("offerData");
        }
      } catch (error) {
        console.error('Error fetching offer data:', error);
      }
    }
  }, [offerId]);

  useEffect(() => {
    const savedPromotionData = localStorage.getItem('promotionData');
    const savedOfferData = localStorage.getItem('offerData');
    const savedImages = localStorage.getItem('uploadedImages');

    try {
      if (savedPromotionData) setPromotionData(JSON.parse(savedPromotionData));
    } catch {
      console.error("Could not load valid promotion Data")
    }

    if (savedImages) {
      const parsedImages = JSON.parse(savedImages);
      setUploadedImages(parsedImages);
    } else {
      const loadData = async () => {
        if (offerId) {
          try {
            const response = await axios.get(`${API_BASE_URL}/v1/catalog/offers/${offerId}`, {
              headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
            });
            setUploadedImages(response.data.images)  
            setUploadedImages(response.data.displayImageReferences || []);
          } catch (error) {
            console.error('Error fetching offer data:', error);
          }
        } 
      };
      loadData();
    }
  }, [offerId]);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => setDragOver(false);

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setDragOver(false);
    handleFiles(e.dataTransfer.files);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFiles(e.target.files);
    }
  };

  const compressImage = async (file: File): Promise<File> => {
    const options = {
      maxSizeMB: 0.95,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Error compressing image:', error);
      return file;
    }
  };

  const handleFiles = async (files: FileList) => {
    const maxSize = 0.95 * 1024 * 1024; // 1MB in bytes
    const remainingSlots = 5 - uploadedImages.length;
    
    // Convert FileList to array and slice to only process allowed number of files
    const filesArray = Array.from(files).slice(0, remainingSlots);
    
    if (files.length > remainingSlots) {
      setError(`You can only upload ${remainingSlots} more image${remainingSlots === 1 ? '' : 's'}`);
    }
  
    for (let i = 0; i < filesArray.length; i++) {
      let file = filesArray[i];
      if (file.size > maxSize) {
        file = await compressImage(file);
        if (file.size > maxSize) {
          setFileSizeError("Image must be <1MB even after compression");
          continue;
        }
      }
  
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setUploadedImages(prev => {
            const newImages = [...prev, event.target!.result as string];
            try {
              localStorage.setItem('uploadedImages', JSON.stringify(newImages));
            } catch (e) {
              console.error('Error saving to localStorage:', e);
            }
            setOfferData(prevData => ({
              ...prevData,
              images: newImages
            }));
            return newImages;
          });
          setFileSizeError(null);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const deleteImage = (index: number) => {
    setUploadedImages(prev => {
      const newImages = prev.filter((_, i) => i !== index);
      localStorage.setItem('uploadedImages', JSON.stringify(newImages));
      setOfferData(prevData => ({
        ...prevData,
        images: newImages
      }));
      return newImages;
    });
  };

  localStorage.setItem('previousPath', '/merchant-hub-post-2');
  const onGoBackClick = () => {
    if (offerId) {
      navigate(`/merchant-hub-post-1/${offerId}`);
    }
    else {
      navigate("/merchant-hub-post-1");
    }
  };

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  
    if (uploadedImages.length === 0) {
      setError("You have to upload at least one picture");
    } else {
      setError(null);
  
      const updatedOfferData = {
        ...offerData,
        images: uploadedImages,
      };
      localStorage.setItem('uploadedImages', JSON.stringify(uploadedImages));
      
      if (offerId) {
        navigate(`/merchant-hub-post-3/${offerId}`);
      }
      else {
        navigate(`/merchant-hub-post-3`);
      }
    }
  };

  const handleImageClick = (imageUrl: string) => {
    setPreviewImage(imageUrl);
  };

  const UploadArea = ({ isMain = false }: { isMain?: boolean }) => {
    const remainingSlots = isMain ? 1 - uploadedImages.length : 5 - uploadedImages.length;
  
    if (isMain && uploadedImages.length > 0) {
      return (
        <div className="flex justify-start mb-4 relative">
          <div className="relative flex justify-start items-start max-w-96">
            <img 
              src={uploadedImages[0]} 
              alt="Main uploaded" 
              className="h-80 w-full object-cover rounded-md cursor-pointer" 
              onClick={() => handleImageClick(uploadedImages[0])}
              crossOrigin="anonymous"
            />
            <button
              className="absolute top-2 right-2 cursor-pointer hover:opacity-80 transition-opacity"
              onClick={(e) => {
                e.stopPropagation();
                deleteImage(0);
              }}
            >
              <X className="bg-white rounded-full p-1" />
            </button>
          </div>
        </div>
      );
    }
  
  
    return (
      <div
        className={`flex justify-center items-center p-4 rounded-md border-2 border-solid max-w-[min(600px, 85vw)] ${
          dragOver ? 'border-blue-500' : 'border-zinc-300'
        } ${isMain ? 'h-72' : 'h-24 w-24'}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="flex flex-col items-center">
          <img
            src="/camera.svg"
            alt="Upload icon"
            className="w-8 h-8 mb-2"
            crossOrigin="anonymous"
          />
          <div className="text-sm text-center">
            {isMain 
              ? "Drag & drop a photo here or click to upload" 
              : `Upload (max ${remainingSlots})`}
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
            id={isMain ? "mainFileInput" : "additionalFileInput"}
            multiple={!isMain}
            {...(!isMain && { max: 5 - uploadedImages.length })}
          />
          <label 
            htmlFor={isMain ? "mainFileInput" : "additionalFileInput"} 
            className={`mt-2 cursor-pointer text-blue-500 underline text-sm ${remainingSlots === 0 ? 'opacity-50 pointer-events-none' : ''}`}
          >
            Browse files
          </label>
        {isMain && fileSizeError && (
          <p className="text-red-500 mt-2">{fileSizeError}</p>
        )}
        </div>
    </div>
    );
  };

  return (
    <div className="flex flex-col">
      <MerchantHubHeader />
      <main className="flex-1 flex min-h-screen font-subhead flex-row">
        <SideNavPost selected="Upload photos" />
        <div className="flex flex-row p-8 mq780:w-[85vw]">
          <form className="flex flex-col font-subhead w-full mb-20">
            <div className="flex flex-col pt-0 pb-3 w-full font-subhead">
              <div className="flex flex-row justify-start mq780:hidden">
                <ChevronLeft onClick={onGoBackClick} className="cursor-pointer w-6 h-6 pt-2.5 pr-3 stroke-black" />
                <p className="bg-transparent mt-3 font-semibold">Create Deal</p>
              </div>
            </div>
            <div className="self-stretch m-0 mt-0 text-19xl mq780:text-29xl font-semibold tracking-tight leading-[50.04px] text-zinc-700 max-md:max-w-full">
              Upload Photos
            </div>
            <p className="mt-4 pt-5 pb-w-full text-base leading-7 text-zinc-800 max-md:max-w-full">
              Add a photo for your post
            </p>
            
            {uploadedImages.length > 0 ? (
              <div className="flex items-start justify-start">
                <div className="relative mb-4 w-auto max-w-[min(800px,80vw)] flex justify-center">
                  <div className="relative w-auto max-w-[min(800px,80vw)]" style={{ maxHeight: '50vh' }}>
                    <img 
                      src={uploadedImages[0]} 
                      alt="Main uploaded" 
                      className="w-auto max-w-[min(800px,80vw)] h-full max-h-[50vh] object-contain rounded-md cursor-pointer" 
                      onClick={() => handleImageClick(uploadedImages[0])}
                      crossOrigin="anonymous"
                    />
                    <X 
                      className="absolute top-2 right-2 cursor-pointer bg-white rounded-full p-1" 
                      onClick={() => deleteImage(0)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <UploadArea isMain />
            )}

            <div className="border-t border-zinc-300 my-4" />
            
            <div className="flex flex-wrap gap-3 gap-4">
              {uploadedImages.slice(1).map((img, index) => (
                <div key={index} className="relative w-32 h-32">
                  <img 
                    src={img} 
                    alt={`Uploaded ${index + 2}`} 
                    className="w-full h-full object-cover rounded-md"
                    onClick={() => handleImageClick(uploadedImages[index+1])}
                    crossOrigin="anonymous"
                  />
                  <div className="absolute top-0 right-0 p-1">
                    <X 
                      className="w-6 h-6 cursor-pointer text-white bg-black bg-opacity-50 rounded-full p-1 hover:bg-opacity-70 transition-colors"
                      onClick={() => deleteImage(index + 1)}
                    />
                  </div>
                </div>
              ))}
              {uploadedImages.length < 5 && <UploadArea />}
            </div>
            <div className="flex flex-col items-end mt-8">
              <button
                onClick={handleNext}
                className="cursor-pointer px-6 py-3 bg-blue-900 text-white rounded-lg hover:bg-blue-800"
              >
                Review Deal
              </button>
              {error && (
                <p className="text-red-500 mt-2">{error}</p>
              )}
            </div>
          </form>
          <div className="hidden mq1050:flex flex-col grow h-auto">
            <CompanyCard />
            <div className=""/>
          </div>
        </div>
      </main>
      <footer className="mt-auto">
        <Footer
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>
      {previewImage && (
        <FullScreenImagePreview
          imageUrl={previewImage}
          onClose={() => setPreviewImage(null)}
        />
      )}
    </div>
  );
};

export default MerchantHubPost2;