import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { EyeOff, Eye } from 'lucide-react';
import axios from 'axios';
import ReactDOM from 'react-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface MerchantLogInFormProps {
  onGoogleSignUp: () => void;
  onFacebookSignUp: () => void;
}

interface ResetPasswordModalProps {
  resetEmail: string;
  setResetEmail: React.Dispatch<React.SetStateAction<string>>;
  resetSuccess: boolean;
  onClose: () => void;
  onSend: () => void;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  resetEmail,
  setResetEmail,
  resetSuccess,
  onClose,
  onSend
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSend = async () => {
    setIsButtonDisabled(true);
    setErrorMessage('');
    try {
      await onSend();
    } catch (error) {
      setErrorMessage('Email not found. Please enter a correct email.');
    }
    setTimeout(() => setIsButtonDisabled(false), 3000);
  };

  return ReactDOM.createPortal(
    <div className="font-subhead fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg min-w-[250px] max-w-[420px] mx-3">
        <h2 className="text-xl mb-4">Reset Password</h2>
        <div className="flex flex-col mb-4">
          <input
            type="email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-[95%] p-2 border rounded mb-2"
          />
          {errorMessage && (
            <p className="text-red-500 text-sm mb-2">{errorMessage}</p>
          )}
          {resetSuccess && (
            <p className="text-green text-sm mb-4">Password Reset Email sent!</p>
          )}
          <button
            onClick={handleSend}
            disabled={!resetEmail || isButtonDisabled}
            className={`px-4 py-2 rounded ${
              resetEmail && !isButtonDisabled
                ? 'bg-main-teal text-white cursor-pointer'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Send
          </button>
        </div>
        <button
          onClick={onClose}
          className="w-full ml-1 text-center text-zinc-500 pl-0 ml-0 bg-white cursor-pointer"
        >
          Close
        </button>
      </div>
    </div>,
    document.body
  );
};

function MerchantLogInForm({ onGoogleSignUp, onFacebookSignUp }: MerchantLogInFormProps) {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isReCaptchaReady, setIsReCaptchaReady] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);

  const handleSignUpClick = () => navigate('/merchant-signup');
  const handleToSClick = () => navigate("/terms-of-service");
  const handlePPClick = () => navigate("/privacy-policy");

  useEffect(() => {
    if (executeRecaptcha) {
      setIsReCaptchaReady(true);
    }
  }, [executeRecaptcha]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');

    if (!executeRecaptcha) {
      setErrorMessage('ReCaptcha not yet available');
      return;
    }
    try {
      const recaptchaToken = await executeRecaptcha('login');
      // // console.log('ReCaptcha token:', recaptchaToken);

      const response = await axios.post(`${API_BASE_URL}/v1/users/auth/login`, {
        email,
        password,
        recaptchaResponse: recaptchaToken,
      });

      if (response.status === 200 || response.status === 201) {
        const { token, userType } = response.data;
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('userType', userType);
        if (userType === "MERCHANT") {
          navigate('/merchant-hub-home');
        } else {
          setErrorMessage('Invalid user type for merchant login.');
        }
      }
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('Login failed. Please check your credentials and try again.');
    }
  };

  const sendPasswordResetRequest = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/users/auth/password-reset/request`,
        { email: resetEmail }
      );

      if (response.status === 200 || response.status === 204) {
        setResetSuccess(true);
      }
    } catch (error) {
      console.error('Failed to request password reset:', error);
      throw error; // Propagate the error to be caught in the modal
    }
  };

  return (
    <div className="min-h-[80%] bg-gray-200 flex justify-center items-center transform pb-20 pt-8 mq780:pt-20">
      <div className="bg-white shadow-lg rounded-lg flex overflow-hidden py-10 max-w-[90vw]">
        {/* Profile Image */}
        <div className="hidden lg:block lg:w-1/2 pl-10" style={{ maxWidth: '512px' }}>
          <img
            alt="Small Business Owner"
            src="small-business-owner.png"
            className="w-full h-full object-cover"
            style={{ borderRadius: '50%', width: '400px', height: '400px' }}
            crossOrigin="anonymous"
          />
        </div>
        
        {/* Form Section */}
        <div className="lg:w-96 w-full pt-15 px-6 mq480:px-20">
          <h2 className="text-4xl font-black text-center mb-2">Merchant Login</h2>
          <div className="text-center font-bold text-sm mb-4">
            New User? <a href="#" className="text-blue-900 hover:underline" onClick={handleSignUpClick}>Sign up</a>
          </div>
          <div className="text-center text-xs mb-5 mt-10">
            By Logging in, you agree to DoYouWeekend's{' '}
            <a onClick={handleToSClick} className="cursor-pointer text-blue-300">Terms of Service</a> and{' '}
            <a onClick={handlePPClick} className="cursor-pointer text-blue-300">Privacy Policy</a>
          </div>

          {/* Form Fields */}
          <form onSubmit={handleLogin} className="w-full">
            <div className="mb-6 flex flex-col gap-3 mr-5">
              <input 
                type="email" 
                id="email-input" 
                placeholder="Email" 
                className="w-full p-2 border border-light-gray rounded-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="w-full relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  className="w-full p-2 border border-light-gray rounded-md"
                  autoComplete="new-password"
                  id="new-password"
                  required
                />
                <span
                  className="absolute inset-y-0 right-0 -pr-4 flex items-center text-sm leading-5 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff className="stroke-grey-600 w-4 h-4" /> : <Eye className="stroke-grey-600 w-4 h-4" />}
                </span>
              </div>
            </div>
            {resetSuccess ? (
              <p className="text-blue-500 text-xs ml-1 -mt-4 text-start cursor-pointer mb-8">Password reset email has been sent</p>
            ) : (
              <p className="text-blue-500 text-xs ml-1 -mt-4 text-start cursor-pointer mb-8" onClick={() => setShowResetModal(true)}>
                Forgot Password?
              </p>
            )}
            {/* Log in Button */}
            <button
              type="submit"
              disabled={!isReCaptchaReady}
              className="cursor-pointer font-bold w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-700"
            >
              Log in
            </button>
            {errorMessage && (
              <p className="text-red-500 text-sm mt-2 text-center">{errorMessage}</p>
            )}
          </form>
        </div>
      </div>
      {showResetModal && (
      <ResetPasswordModal
        resetEmail={resetEmail}
        setResetEmail={setResetEmail}
        resetSuccess={resetSuccess}
        onClose={() => {
          setShowResetModal(false);
          setResetSuccess(false);
          setResetEmail("");
        }}
        onSend={sendPasswordResetRequest}
      />
    )}
    </div>
  );
}

export default MerchantLogInForm;