import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface CarAnimationProps {
  percentage: string;
}

const DottedLine = ({ width="min(700px, 85vw)" }: { width?: string }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 780);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 780);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      style={{
        width,
        height: '2px',
        backgroundImage: 'linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%)',
        backgroundPosition: 'top',
        backgroundSize: isMobile ? '25px 8px' : '35px 10px', // Ternary operator for background size
        backgroundRepeat: 'repeat-x'
      }}
    ></div>
  );
};

function CarAnimation({ percentage }: CarAnimationProps) {
  const navigate = useNavigate();

  return (
    <div style={{ width: 'min(700px, 85vw)' }} className="flex gap-0 justify-end items-center max-md:flex-wrap border-t-2 border-b-2 border-black border-solid">
      {/* Car */}
      <div className="flex gap-0 items-center">
        <img
          style={{ maxWidth: 'min(103px, 15vw)' }}
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/009eb2cf3af7a517c5e468a166ea9aeaf950f6464aa29e88027acb22a3017507?apiKey=0d607e805d95499aa95fa0a93ed5f083&"
          className="shrink-0 w-auto aspect-[1.33]"
          crossOrigin="anonymous"
        />
      </div>
      {/* Dotted Line */}
      <DottedLine width={percentage}/>
      {/* End marker */}
      <div className="flex items-center">
        <img
          style={{ maxWidth: 'min(130px, 8vw)' }}  
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/9cd722f141037cd5126336afa9f67786bb75de7a984ad91cc6e985144ab4ff73?apiKey=0d607e805d95499aa95fa0a93ed5f083&"
          className="pl-2 shrink-0 w-9 aspect-[0.82]"
          crossOrigin="anonymous"
        />
      </div>
    </div>
  );
}

export default CarAnimation;
