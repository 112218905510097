import React, { useState, useRef, useCallback } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { X } from 'lucide-react';
import imageCompression from 'browser-image-compression';

interface ImageUploaderProps {
  onImageChange: (imageData: string | undefined) => void;
  maxSize?: number; // in bytes
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageChange, maxSize = 15 * 1024 * 1024 }) => {
  const [image, setImage] = useState<string | undefined>();
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [isCropping, setIsCropping] = useState(false);
  const [imageSize, setImageSize] = useState<string>('');
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const compressImage = async (file: File): Promise<File> => {
    const options = {
      maxSizeMB: 15,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Error compressing image:', error);
      return file;
    }
  };

  const calculateImageSize = (file: File) => {
    const size = file.size / 1024; // Convert to KB
    if (size < 1024) {
      return `${size.toFixed(2)} KB`;
    } else {
      return `${(size / 1024).toFixed(2)} MB`;
    }
  };

  const processImage = async (file: File) => {
    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      setFileSizeError("Image must be of type JPG, PNG, or GIF");
      return;
    }

    let processedFile = file;
    if (file.size > maxSize) {
      processedFile = await compressImage(file);
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const imageData = event.target?.result as string;
      setImage(imageData);
      setImageSize(calculateImageSize(processedFile));
      setFileSizeError(null);
      onImageChange(imageData);
    };
    reader.readAsDataURL(processedFile);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      await processImage(files[0]);
    }
  };

  const onImageLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(undefined); // Reset crop
    setCompletedCrop(undefined);
    // Set initial crop to a centered square
    const size = Math.min(width, height);
    const x = (width - size) / 2;
    const y = (height - size) / 2;
    setCrop({ unit: '%', width: 100, height: 100, x: 0, y: 0 });
  }, []);

  const removeImage = () => {
    setImage(undefined);
    setImageSize('');
    setIsCropping(false);
    onImageChange(undefined);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      await processImage(files[0]);
    }
  };

  const toggleCropping = () => {
    setIsCropping(!isCropping);
    if (isCropping) {
      setCrop(undefined);
    }
  };

  const getCroppedImg = useCallback(() => {
    if (!completedCrop || !imgRef.current || !canvasRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob((blob) => {
      if (!blob) {
        console.error('Canvas is empty');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setImage(base64data);
        onImageChange(base64data);
        setIsCropping(false);
      };
    }, 'image/jpeg');
  }, [completedCrop, onImageChange]);

  return (
    <div
      style={{ maxWidth: 'min(624px, 90vw)', minWidth: '200px' }}
      className="flex justify-center items-center px-0 py-5 mt-9 w-inherit text-base leading-7 text-center rounded-md border border-solid border-zinc-800 text-zinc-800 max-md:px-5 max-md:max-w-full relative"
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      {image ? (
        <div className="w-full h-full relative flex flex-col px-4">
          {isCropping ? (
            <div className='flex items-center justify-center'>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={1}
              >
                <img ref={imgRef} src={image} alt="Upload preview" onLoad={onImageLoad} crossOrigin="anonymous" />
              </ReactCrop>
              <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>
          ) : (
            <img src={image} alt="Preview" className="w-full h-auto" crossOrigin="anonymous" />
          )}
          <X
            onClick={removeImage}
            className="absolute top-2 right-2 bg-white rounded-full p-1 cursor-pointer"
          />
          {imageSize && (
            <div className="absolute bottom-2 left-2 bg-white rounded px-2 py-1 text-sm">
              {imageSize}
            </div>
          )}
          <button 
            type="button" 
            onClick={isCropping ? getCroppedImg : toggleCropping} 
            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
          >
            {isCropping ? 'Finish Cropping' : 'Crop Image'}
          </button>
        </div>
      ) : (
        <div className="flex flex-col max-w-full w-[186px]">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6c7c97d7821c53ae0a76f061fb2c2ac41d67592fc162ea0f98664d14dd49d52?apiKey=0d607e805d95499aa95fa0a93ed5f083&"
            alt="Upload icon"
            className="self-center w-12 aspect-square"
          />
          <div className="mt-6">Drag & drop a photo here or click to upload</div>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
            id="fileInput"
          />
          <label htmlFor="fileInput" className="mt-4 cursor-pointer text-blue-500 underline">
            Browse files
          </label>
        </div>
      )}
      {fileSizeError && <div className="text-red-500 text-sm mt-1">{fileSizeError}</div>}
    </div>
  );
};

export default ImageUploader;