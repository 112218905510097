import React, { useState, useEffect, useRef  } from 'react';
import { CalendarClock, BadgeDollarSign, BadgeCheck, TriangleAlert, QrCode } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import CompanyCard from './CompanyCard';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface OnboardingStatus {
  status: string;
  emailVerified: boolean;
  detailsSubmitted: boolean;
  payoutsEnabled: boolean;
  requirements: string[];
  showOnboardingLink: boolean;
  showUpdateLink: boolean;
  showDashboardLink: boolean;
  notificationMessage: string;
}

interface MerchantProfile {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  status: string;
  userType: string;
  preferences: {
    wantsPromotionalEmails: boolean;
  };
  createdAt: string;
  businessName: string;
  highlightIds: string[];
  categoryIds: string[];
  description: string;
  displayImageReferences: string[];
  profilePictureReference: string;
  openingHours: {
    schedule: {
      [key: string]: {
        openTime: {
          hour: number;
          minute: number;
        };
        closeTime: {
          hour: number;
          minute: number;
        };
        closed: boolean;
      };
    };
  };
  hasOffers: boolean;
}

type MainContentProps = {
  onDealClick?: () => void;
  onInfoClick?: () => void;
  onUpdateListingClick?: () => void;
  onAddToCalendarClick?: () => void;
};

const MainContent: React.FC<MainContentProps> = ({
  onDealClick,
  onInfoClick,
  onUpdateListingClick,
  onAddToCalendarClick,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessCard, setShowSuccessCard] = useState(false);
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus | null>(null);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [verificationError, setVerificationError] = useState<string | null>(null);
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState("");
  const [merchantProfile, setMerchantProfile] = useState<MerchantProfile | null>(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 780);
  const [onboardingLinkClicked, setOnboardingLinkClicked] = useState(false);
  const [stripeLink, setStripeLink] = useState<string | null>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();

  const sendEmailVerificationRequest = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/users/auth/email-confirmation/request`,
        { email: merchantProfile?.email },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        }
      );

      if (response.status === 200 || response.status === 204) {
        setEmailVerificationSent(true);
        setVerificationError(null);
        setTimeout(() => setShowEmailVerificationModal(false), 2000);
      }
    } catch (error) {
      console.error('Failed to send email verification:', error);
      setVerificationError('Failed to send email verification. Please try again.');
    }
  };

  const fetchMerchantProfile = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/v1/users/merchant/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      setMerchantProfile(response.data);
    } catch (error) {
      console.error('Error fetching merchant profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOnboardingStatus = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/merchants/onboarding/status`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      setOnboardingStatus(response.data);
    } catch (error) {
      console.error('Error fetching onboarding status:', error);
    }
  };

  useEffect(() => {
    fetchMerchantProfile();
    fetchOnboardingStatus();
  }, []);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 780);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleStripeAction = async (actionType: 'onboarding' | 'update' | 'dashboard') => {
    try {
      let response;
      if (actionType === 'dashboard') {
        response = await axios.post(
          `${API_BASE_URL}/v1/merchants/dashboard-link`,
          {}, 
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
            }
          }
        );
      } else {
        response = await axios.post(
          `${API_BASE_URL}/v1/merchants/account-link`,
          {}, 
          {
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
              'Content-Type': 'application/json'
            }
          }
        );
      }
  
      if (response && response.status === 200 && response.data) {
        setStripeLink(response.data); // Set the stripeLink state
        const newWindow = window.open(response.data, '_blank');
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          alert("Popup blocked. Please use the link below to complete the onboarding process.");
        } else {
          if (actionType === 'onboarding') {
            setOnboardingLinkClicked(true);
          }
        }
      } else {
        console.error('Invalid response from server');
      }
    } catch (error) {
      console.error(`Error fetching Stripe ${actionType} link:`, error);
    }
  };

  const handleEmailVerification = () => {
    setShowEmailVerificationModal(true);
    setVerificationEmail(merchantProfile?.email || "");
  };

  useEffect(() => {
    if (onboardingLinkClicked) {
      const timer = setInterval(() => {
        fetchOnboardingStatus();
        setOnboardingLinkClicked(false);
      }, 2000); // Check every 5 seconds
  
      return () => clearInterval(timer);
    }
  }, [onboardingLinkClicked]);

  useEffect(() => {
    if (!merchantProfile?.hasOffers) {
      const timer = setTimeout(() => {
        setShowSuccessCard(true);
      }, 1000);
  
      return () => clearTimeout(timer);
    }
  }, [merchantProfile]);

  return (
    <div className="flex items-start flex-col mb-20 bg-white w-full max-w-full">
      <div className="w-full">
        <div className="flex flex-col w-full">
          <div className="flex flex-col grow mt-12 w-full">
            <div className="self-center w-full">
              <div className="flex flex-col w-full mq780:flex-row">
                <div className="flex flex-col pr-0 w-full mq780:pr-20">
                  <div className="flex flex-col grow pt-4 w-full pl-8 font-semibold max-w-[85vw]">
                    <div className="text-11xl mq480:text-29xl leading-[50.04px] tracking-tight text-zinc-800">
                      Hi there {merchantProfile?.businessName}!
                    </div>
                    <div className="mt-4 font-medium text-3xl tracking-tight leading-9 text-zinc-600">
                      Welcome to your personalized business dashboard.
                    </div>
                    {onboardingStatus && (
                      <div className="flex flex-col pb-6 pt-2 pl-8 pr-3 my-5 mt-10 text-base leading-7 rounded-xl border border-solid border-zinc-300 max-md:pl-5 max-md:max-w-full max-w-[85vw]">
                        {!onboardingStatus.payoutsEnabled && <div className="flex flex-row text-red-400 gap-2 w-[100px] items-start mt-2 justify-start bg-red-200/30 border border-solid border-red-500 rounded-lg px-2 py-0">
                          <TriangleAlert className='stroke-red-400 w-3 h-3 pl-1 m-0 p-0 mb-1 mt-1' />
                          <p className='text-red-400 font-subhead m-0 p-0 text-xxs h-[20px] mb-1 -mt-[4px] pr-2'>Important</p>
                        </div>}
                        <div className="text-3xl mq480:text-6xl tracking-tight leading-9 text-zinc-800 mt-2 max-md:max-w-full">
                          Stripe Account 
                        </div>
                        {onboardingStatus.notificationMessage && <p>{onboardingStatus.notificationMessage}</p>}
                        <div className="flex gap-5 mt-4 max-md:flex-wrap">
                          <img src="/stripe-logo.png" className="w-[60px] h-[60px]" crossOrigin="anonymous" />
                          <div className="flex flex-col flex-1 max-md:max-w-full">
                            {onboardingStatus.showOnboardingLink && (
                              <div>
                                <button
                                  onClick={() => handleStripeAction('onboarding')}
                                  className="cursor-pointer bg-white text-left font-bold pt-2 mr-2 text-lg mt-2 text-blue-400 underline max-md:max-w-full"
                                >
                                  {onboardingLinkClicked ? "Refreshing..." : "Complete Stripe Onboarding"}
                                </button>
                                {stripeLink && (
                                  <>
                                  <p className='ml-2 mb-0 text-sm w-full text-neutral items-center justify-center'>or just follow this link:</p>
                                  <div className="mt-1">
                                    <a
                                      href={stripeLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="Etext-sm text-blue-500 hover:text-blue-700 break-all"
                                    >
                                      {stripeLink}
                                    </a>
                                  </div>
                                  </>
                                )}
                              </div>
                            )}
                            {onboardingStatus.showUpdateLink && (
                              <button
                                onClick={() => handleStripeAction('update')}
                                className="cursor-pointer bg-white text-left font-bold pt-2 mr-2 text-lg mt-2 text-blue-400 underline max-md:max-w-full"
                              >
                                Update Stripe Account
                              </button>
                            )}
                            {onboardingStatus.showDashboardLink && (
                              <button
                                onClick={() => handleStripeAction('dashboard')}
                                className="cursor-pointer bg-white text-left font-bold pt-2 mr-2 text-lg mt-2 text-blue-400 underline max-md:max-w-full"
                              >
                                View Stripe Dashboard
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {merchantProfile?.hasOffers && (
                      <div className="lg:hidden flex flex-col pb-6 pt-6 -mb-5 pl-8 pr-3 mt-6 text-base leading-7 rounded-xl border border-solid border-zinc-300 max-md:pl-5 max-md:max-w-full max-w-[85vw]">
                        <div className="text-3xl mq480:text-6xl tracking-tight leading-9 text-zinc-800 max-md:max-w-full">
                          Scan QR Code
                        </div>
                        <div className="text-base font-semilight tracking-tight leading-6 text-zinc-800 max-md:max-w-full">
                          Let your customers redeem their orders
                        </div>
                        <div className="flex gap-5 mt-8 max-md:flex-wrap">
                          <QrCode className="shrink-0 my-auto w-14 h-14 stroke-blue-300" />
                          <div className="flex flex-col flex-1 max-md:max-w-full">
                            <button
                              onClick={() => navigate("/merchant-scan-qr-code")}
                              className="cursor-pointer bg-white text-left font-bold pt-2 mr-2 text-lg mt-2 text-blue-400 underline max-md:max-w-full"
                            >
                              Scan QR Code
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {merchantProfile?.status === "UNVERIFIED" && (
                      <div className="flex flex-col pb-8 pl-8 pr-3 mb-5 text-base leading-7 rounded-xl border border-solid border-zinc-300 max-md:pl-5 max-md:max-w-full max-w-[85vw] mt-10 lg:mt-0">
                        <div className="hidden flex my-2 flex-row text-red-400 gap-2 w-[100px] items-start mt-4 justify-start bg-red-200/30 border border-solid border-red-500 rounded-lg px-2 py-0">
                          <TriangleAlert className='stroke-red-400 w-3 h-3 pl-1 m-0 p-0 mb-1 mt-1' />
                          <p className='text-red-400 font-subhead m-0 p-0 text-xxs h-[20px] mb-1 -mt-[4px] pr-2'>Important</p>
                        </div>
                        <div className="text-3xl mt-5 mq480:text-6xl tracking-tight leading-9 text-zinc-800 max-md:max-w-full">
                          Verify your Profile 
                        </div>
                        <div className='mt-4 mb-2 text-neutral text-sm'>This is recommended, but not mandatory to create offers.</div>
                        <div className="flex gap-5 mt-4 max-md:flex-wrap">
                          <BadgeCheck className={`shrink-0 my-auto w-14 h-14 ${emailVerificationSent ? "stroke-green" : "stroke-blue-300"}`} />
                          <div className="flex flex-col flex-1 max-md:max-w-full">
                            {!emailVerificationSent && !verificationError && (
                              <button
                                onClick={sendEmailVerificationRequest}
                                className="cursor-pointer bg-white text-left font-bold pt-2 mr-2 text-lg mt-2 text-blue-400 underline max-md:max-w-full"
                              >
                                Send verification Email
                              </button>
                            )}
                            {emailVerificationSent && (
                              <p className="text-green font-bold pt-2 mr-2 text-lg mt-2">Verification Email sent!</p>
                            )}
                            {verificationError && (
                              <p className="text-red-500 font-bold pt-2 mr-2 text-lg mt-2">{verificationError}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {!merchantProfile?.hasOffers && onboardingStatus && onboardingStatus.payoutsEnabled &&showSuccessCard && (
                      <div className="flex flex-col pb-8 pt-6 mb-2 pl-8 pr-3 mt-0 text-base leading-7 rounded-xl border border-solid border-zinc-300 max-md:pl-5 max-md:max-w-full max-w-[85vw]">
                        <div className="text-3xl mq480:text-6xl tracking-tight leading-9 text-zinc-800 max-md:max-w-full">
                          Success
                        </div>
                        <div className="text-base font-semilight tracking-tight leading-6 text-zinc-800 max-md:max-w-full">
                          You have successfully been registered!
                        </div>
                        <div className="flex gap-5 mt-8 max-md:flex-wrap">
                          <BadgeDollarSign className="shrink-0 my-auto w-14 h-14 stroke-blue-300" />
                          <div className="flex flex-col flex-1 max-md:max-w-full">
                            <button
                              onClick={() => navigate("/merchant-hub-post-1")}
                              className="cursor-pointer bg-white text-left font-bold pt-2 mr-2 text-lg mt-2 text-blue-400 underline max-md:max-w-full"
                            >
                              Add your first Offer
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!isMobileView && 
                  <div className="hidden mq1050:flex flex-col items-center justify-center mb-[45vh] mx-5 w-full">
                    <CompanyCard />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;