import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useUnsavedChangesWarning = (hasUnsavedChanges: boolean) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const navigateWithWarning = useCallback((path: string) => {
    if (hasUnsavedChanges) {
      const userConfirmed = window.confirm('You have unsaved changes. Do you really want to leave?');
      if (!userConfirmed) {
        return;
      }
    }
    navigate(path);
  }, [hasUnsavedChanges, navigate]);

  return navigateWithWarning;
};

export default useUnsavedChangesWarning;