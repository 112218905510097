import React, { useState } from 'react';

const LimitSelector1: React.FC = () => {
  const [limit, setLimit] = useState<number>(1);
  const [giftLimit, setGiftLimit] = useState<number>(1);
  const [selected, setSelected] = useState<boolean>(false);

  const handleLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(e.target.value, 10) || 1);
  };

  const handleGiftLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGiftLimit(parseInt(e.target.value, 10) || 1);
  };

  const handleRadioChange = () => {
    setSelected(!selected);
  };

  return (
    <div className="flex items-center space-x-2 max-w-inherit">
      <div className="relative pb-5 pr-7">
        <input
          type="checkbox"
          checked={selected}
          onChange={handleRadioChange}
          className="cursor-pointer absolute w-4 h-4 opacity-100"
        />
      </div>
      <span>Limit</span>
      <input
        type=""
        value={limit}
        onChange={handleLimitChange}
        className="w-5 text-[16px] mt-[2px] text-blue-500 text-center font-subhead [text-decoration:underline] border-none outline-none"
      />
      <span>per person</span>
      <input
        type=""
        value={giftLimit}
        onChange={handleGiftLimitChange}
        className="hidden w-5 text-[16px] mt-[2px] text-blue-500 text-center font-subhead [text-decoration:underline] border-none outline-none"
      />
      <span className='hidden'>additional as gift(s).</span>
    </div>
  );
};

export default LimitSelector1;