import React from 'react';

type DayProps = {
  day: number;
  isCurrentMonth: boolean;
  isHighlighted: boolean;
  onClick: () => void;
  isDisabled: boolean;
};

const Day: React.FC<DayProps> = ({ day, isCurrentMonth, isHighlighted, onClick, isDisabled }) => (
  <div
    onClick={isDisabled ? undefined : onClick}
    className={`flex items-center justify-center px-3 py-2.5 cursor-pointer w-6 h-6 ${
      isHighlighted
        ? 'font-bold text-white bg-blue-500 shadow-sm'
        : isCurrentMonth
        ? 'bg-white'
        : 'bg-white text-transparent pointer-events-none'
    } ${isDisabled ? 'cursor-not-allowed opacity-50' : ''}`}>
    {day}
  </div>
);

const generateWeeks = (year: number, month: number) => {
  const weeks = [];
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  let day = 1 - firstDayOfMonth;

  for (let i = 0; i < 6; i++) {
    const week = [];
    for (let j = 0; j < 7; j++) {
      week.push(new Date(year, month, day));
      day++;
    }
    weeks.push(week);
  }

  return weeks;
};

const Calendar: React.FC<{
  setwidth?: string,
  title: string;
  selectedDate: Date | null;
  setSelectedDate: (date: Date) => void;
  minDate?: Date;
  highlightedRange?: [Date, Date];
  year: number;
  month: number;
  onMonthChange: (offset: number) => void;
  onYearChange: (year: number) => void;  // New prop for year change
  isEndDatePicker?: boolean;
  startDate?: Date | null;
}> = ({
  setwidth,
  title,
  selectedDate,
  setSelectedDate,
  minDate,
  highlightedRange,
  year,
  month,
  onMonthChange,
  onYearChange,  // New prop
  isEndDatePicker = false,
  startDate,
}) => {
  const weeks = generateWeeks(year, month);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <section className={`${setwidth ? setwidth : "max-w-[min(75vw,300px)]"} flex flex-col grow px-2.5 pt-3.5 pb-3 text-sm leading-4 text-center rounded-xl border border-solid border-zinc-300 text-neutral-900 max-md:mt-`}>
      <header className='flex gap-5 justify-between pr-4 text-base font-bold text-blue-500 shadow-sm'>
        <button type="button" onClick={() => onMonthChange(-1)} className='bg-transparent cursor-pointer'>
          <img loading='lazy' src='https://cdn.builder.io/api/v1/image/assets/TEMP/e937e54b9c34971e2874b8d51e86fec2b6469afa4af1c5d695aee8e399c4d7e8?apiKey=0d607e805d95499aa95fa0a93ed5f083&' alt='' className='shrink-0 bg-transparent w-6 aspect-square' crossOrigin="anonymous" />
        </button>
        <div className='my-auto flex items-center'>
          <span>{title}</span>
          <select 
            value={year}
            onChange={(e) => onYearChange(parseInt(e.target.value))}
            className="ml-2 bg-transparent text-blue-500 text-base cursor-pointer font-bold"
          >
            {[...Array(10)].map((_, i) => (
              <option key={i} value={today.getFullYear() + i}>
                {today.getFullYear() + i}
              </option>
            ))}
          </select>
        </div>
        <button type="button" onClick={() => onMonthChange(1)} className='bg-transparent cursor-pointer'>
          <img loading='lazy' src='https://cdn.builder.io/api/v1/image/assets/TEMP/b442efdc5803a7a84c4d6182fcdfc881e6003b15118f2eb530b9db420f0a1cee?apiKey=0d607e805d95499aa95fa0a93ed5f083&' alt='' className='shrink-0 bg-transparent w-6 aspect-square' crossOrigin="anonymous" />
        </button>
      </header>
      <nav className='flex gap-0 mt-8 text-xs font-bold whitespace-nowrap shadow-sm text-zinc-500'>
        <span className="flex-1">SUN</span>
        <span className="flex-1">MON</span>
        <span className="flex-1">TUE</span>
        <span className="flex-1">WED</span>
        <span className="flex-1">THU</span>
        <span className="flex-1">FRI</span>
        <span className="flex-1">SAT</span>
      </nav>
      {weeks.map((week, weekIndex) => (
        <div key={weekIndex} className='flex gap-0 justify-center mt-1 whitespace-nowrap max-md:mr-1.5 max-md:ml-2'>
          {week.map((date, dayIndex) => {
            const isCurrentMonth = date.getMonth() === month;
            const isHighlighted = highlightedRange
              ? date >= highlightedRange[0] && date <= highlightedRange[1] && isCurrentMonth
              : selectedDate !== null && date.getTime() === selectedDate.getTime();
            const isDisabled = isEndDatePicker
              ? (startDate ? date < startDate : date < today) || !isCurrentMonth
              : date < today || !isCurrentMonth;
            return (
              <Day
                key={dayIndex}
                day={date.getDate()}
                isCurrentMonth={isCurrentMonth}
                isHighlighted={isHighlighted}
                onClick={() => isCurrentMonth && setSelectedDate(date)}
                isDisabled={isDisabled}
              />
            );
          })}
        </div>
      ))}
    </section>
  );
};

export default Calendar;