import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

export const verifyEmailCode = async (verificationCode: string, jwtToken: string) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/email/confirm?verificationCode=${verificationCode}`,
      null,
      {
        headers: {
          'Authorization': `Bearer ${jwtToken}`
        }
      }
    );

    // // console.log("Verification response:", response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 409) {
      // // console.log("User already verified");
      return { message: "User already verified" };
    }
    console.error('Error verifying email code:', error);
    throw error;
  }
};