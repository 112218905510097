import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { Facebook, Instagram, Youtube } from "lucide-react";
import { useNavigate } from "react-router-dom";

export type FooterType = {
  dYWLogoW1?: string;
  propAlignSelf?: CSSProperties["alignSelf"];
  propPosition?: CSSProperties["position"];
  propTop?: CSSProperties["top"];
  propLeft?: CSSProperties["left"];
  propWidth?: CSSProperties["width"];
};

const Footer: FunctionComponent<FooterType> = ({
  dYWLogoW1,
  propAlignSelf,
  propPosition,
  propTop,
  propLeft,
  propWidth,
}) => {
  const footerStyle: CSSProperties = useMemo(() => ({
    alignSelf: propAlignSelf,
    position: propPosition,
    top: propTop,
    left: propLeft,
    width: propWidth,
  }), [propAlignSelf, propPosition, propTop, propLeft, propWidth]);

  const navigate = useNavigate();

  const onNavigate = (path: string) => () => navigate(path);
  const onExternalLink = (url: string) => () => window.open(url, '_blank');
  const onSupport = () => {
    const businessName = "";
    window.location.href = `mailto:andrew@doyouweekend.com?subject=USER%20SUPPORT%20REQUEST%20from%20${businessName}`;
  };
  const onSuggestFeature = () => {
    navigate("/suggest-feature")
  };

  const footerLinks = [
    { title: "My Account", onClick: onNavigate("/account/profile") },
    { title: "Previous Bookings", onClick: onNavigate("/account/bookings")},
    // { title: "Likes", onClick: undefined }, // LIKES onNavigate("/likes")
    { title: "Itineraries", onClick: onNavigate("/itineraries") },
    { title: "Support", onClick: onNavigate("/support") },
    // { title: "About us", onClick: undefined },
    { title: "FAQ", onClick: onNavigate("/user-faq") },
    // { title: "Suggest a Feature", onClick: onSuggestFeature },
    { title: "Locations", onClick: onNavigate("/map") },
    { title: "Merchant Login", onClick: onNavigate("/merchant-login") },
    { title: "Privacy", onClick: onNavigate("/privacy-policy") },
    { title: "Terms of Service", onClick: onNavigate("/terms-of-service") },
  ];

  const socialLinks = [
    { Icon: Facebook, alt: "Facebook", onClick: undefined },
    { Icon: Instagram, alt: "Instagram", onClick: onExternalLink('https://www.instagram.com/doyouweekend/') },
    { Icon: Youtube, alt: "YouTube", onClick: undefined },
  ];

  return (
    <footer className="w-full bg-dark-navy text-white font-subhead px-4 py-8 md:px-8 lg:px-16" style={footerStyle}>
      <div className="max-w-7xl mx-auto">
        <div className="flex lg:items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="text-center items-center lg:text-start lg:items-start">
            <img className="h-12 w-auto" src={dYWLogoW1} alt="DoYouWeekend Logo" crossOrigin="anonymous" />
          </div>
          {[...Array(3)].map((_, index) => (
            <div key={index} className="space-y-4 text-center items-center lg:text-start lg:items-start">
              {footerLinks.slice(index * 3, (index + 1) * 3).map((link, linkIndex) => (
                <p
                  key={linkIndex}
                  onClick={link.onClick}
                  className={`${link.onClick !== undefined ? 'cursor-pointer hover:underline' : 'cursor-default'}`}
                >
                  {link.title}
                </p>
              ))}
            </div>
          ))}
        </div>
        <div className="mt-8 flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            © DoYouWeekend {new Date().getFullYear()}.
          </div>
          <div className="flex space-x-4">
            {socialLinks.map((link, index) => (
              <link.Icon
                key={index}
                className={`h-6 w-6 ${link.onClick !== undefined ? 'cursor-pointer hover:text-gray-300' : 'cursor-default'}`}
                onClick={link.onClick}
                aria-label={link.alt}
              />
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;