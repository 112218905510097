import React from 'react';
import { Edit3Icon } from 'lucide-react';

interface EditButtonProps {
  onButtonClick?: () => void;
}

const EditButton: React.FC<EditButtonProps> = ({
  onButtonClick
}) => {
  return (
    <div onClick={onButtonClick} className="w-auto h-5 flex flex-row gap-2 cursor-pointer">
       <Edit3Icon className='stroke-gray-500 w-5 h-5' />
       <p className='m-0 text-gray-500 pl-1 font-semilight text-base pt-[2px]'>Edit</p>
    </div>
  );
};

export default EditButton;