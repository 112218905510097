import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';7
import CarAnimation from './CarAnimation';

interface QuizComponent1Props {
  onInputClick?: () => void;
}

function QuizComponent1({ onInputClick }: QuizComponent1Props) {

    const navigate = useNavigate();

    const [inputValue, setInputValue] = useState('');

    const handleFocus = () => {
      setInputValue('');
    };

    const onNextClick = () => {
        navigate("/h72-quiz-page");
    };

    const onExitClick = () => {
        navigate("/");
    };

  return ( 
    <div className="flex flex-col items-center px-5 min-h-screen">
      <CarAnimation percentage="80%" />
      <div className="pt-2 text-xl font-semibold tracking-tight text-center text-zinc-800">
        STEP 1 OF 6
      </div>
      <div className="mt-16 text-19xl font-semibold text-center leading-[54.4px] text-zinc-800 max-md:mt-5 max-md:max-w-full max-md:text-4xl">
        Where are you headed?
      </div>
        <input
          type="text"
          placeholder={inputValue === '' ? "Enter a City, State or Zip" : ''}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onFocus={handleFocus}
          className="justify-center px-4 py-6 mt-10 text-lg font-medium text-center bg-white text-blue-900 border border-black border-solid max-md:mt-10"
          id="DestinationInput"
          name="search"
          onClick={onInputClick}
        />
      <div className="mt-4 text-base font-medium leading-8 text-center text-zinc-500 max-w-[400px] max-md:max-w-full 580px:text-sm 580px:leading-6 580px:w-[90vw]">
        Hint: If you're looking for recommendations for a trip, give us the location of your
        trip
      </div>
      <div className= "flex gap-5 justify-between self-stretch mt-16 w-inherit text-2xl font-bold tracking-tight text-center whitespace-nowrap max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
        <button
          className="cursor-pointer justify-center px-7 py-4 rounded-lg border text-base font-bold bg-white font-subhead text-blue-900 border-blue-900 border-solid max-md:px-5"
          onClick={onExitClick}
        >
          Exit
        </button>
        <div className="bg-white">
          <button onClick={onNextClick} className="cursor-pointer bg-white">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c1e1114e9b232c385299242371a03e522235c9d963adfca02707c077051d049d?apiKey=0d607e805d95499aa95fa0a93ed5f083&" className="shrink-0 w-20 aspect-[1.41]" crossOrigin="anonymous" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuizComponent1;