import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface UserProfile {
  userType: string;
}

const DesktopDropdown: React.FC = () => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const jwt = localStorage.getItem('jwtToken');
    setIsLoggedIn(!!jwt);

    const fetchUserProfile = async () => {
      if (jwt) {
        try {
          const response = await axios.get(`${API_BASE_URL}/v1/users/merchant/profile`, {
            headers: {
              Authorization: `Bearer ${jwt}`
            }
          });
          setUserProfile(response.data);
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, []);

  const handleMerchantPortalClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const jwt = localStorage.getItem('jwtToken');
    if (jwt && userProfile?.userType === 'MERCHANT') {
      navigate('/merchant-hub-home');
    } else {
      navigate('/merchant-hub');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    setIsLoggedIn(false);
    navigate("/");
  };

  return (
    <div className="w-[200px] shadow-lg bg-white p-4 flex flex-col relative z-50 overflow-y-auto rounded-sm">
      <nav className="space-y-4">
        {/* <a href="/map" className="block no-underline pl-0 text-center text-sm text-zinc-900 font-subhead pb-2 hover:font-semibold">Search</a> */}
        <a href="/map" className="block no-underline pl-0 text-center text-sm text-zinc-900 font-subhead pb-2 hover:font-semibold">Explore</a>
        <a href="/itineraries" className="block no-underline pl-0 text-center text-sm text-zinc-900 font-subhead pb-2 hover:font-semibold">Itineraries</a>
        {/* <a href="/likes" className="block no-underline pl-0 text-center text-sm text-zinc-900 font-subhead pb-2 hover:font-semibold">My Likes</a> */} {/* LIKES */}
        <a href={`${window.innerWidth < 780 ? '/account' : '/account/profile'}`} className="block no-underline pl-0 text-center text-sm text-zinc-900 font-subhead pb-2 hover:font-semibold">My Account</a>
        <a href="/map" className="hidden block no-underline pl-0 text-center text-sm text-zinc-900 font-subhead pb-2 hover:font-semibold">Map</a>
      </nav>
      <div className='h-[0.1px] mx-4 mt-6 my-3 bg-zinc-400'/>
      <div className="mt-6 w-inherit px-4 flex justify-between space-x-4 pb-2 text-sm">
        {isLoggedIn ? (
          <button onClick={handleLogout} className="w-full py-2 px-4 bg-[#0098DA] font-semibold rounded text-white hover:bg-[#0082B8] transition duration-200">
            Log out
          </button>
        ) : (
          <>
            <button onClick={() => navigate("/login")} className="text-sm cursor-pointer whitespace-nowrap flex-1 py-2 px-4 border border-blue-900 hover:font-bold hover:border-blue-700 font-semibold rounded text-blue-900 bg-white hover:bg-gray-100 transition duration-200">
              Log in
            </button>
            <button onClick={() => navigate("/signup")} className="text-sm cursor-pointer whitespace-nowrap flex-1 py-2 px-4 bg-[#0098DA] font-semibold rounded text-white hover:bg-[#0082B8] transition duration-200">
              Sign up
            </button>
          </>
        )}
      </div>
      <div className='h-[0.1px] mx-4 mt-6 my-3 bg-zinc-400'/>
      <div className="mt-6 pb-5 text-center mr-3">
        <a 
          href="#" 
          onClick={handleMerchantPortalClick}
          className="no-underline pl-4 text-center text-base text-zinc-900 font-semibold font-subhead pb-3 hover:font-bold"
        >
          Merchant Portal
        </a>
      </div>
    </div>
  );
};

export default DesktopDropdown;