import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isTokenValid, getAudience } from './jwtUtils';

interface ProtectedRouteProps {
  children: React.ReactNode;
  allowedRole: 'CUSTOMER' | 'MERCHANT';
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRole }) => {
  const location = useLocation();
  const token = localStorage.getItem('jwtToken'); // Assuming you store the JWT in localStorage

  if (!token || !isTokenValid(token)) {
    // console.log("token: ",!token);
    // console.log("!token: ",!token);
    // Redirect to login if there's no token or it's invalid
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const userRole = getAudience(token);

  if (userRole !== allowedRole) {
    if(allowedRole == "MERCHANT") {
      return <Navigate to="/merchant-hub" replace />;
    } else {
    // Redirect to home or show an error if the user doesn't have the correct role
    return <Navigate to="/" replace />;
    }
  }

  return <>{children}</>;
};

export default ProtectedRoute;