import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { QRCodeSVG } from 'qrcode.react';
import ConfirmationPopup from './ConfirmationPopup';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

type CardComponentProps = {
  id: string;
  image: string;
  title: string;
  price: string;
  description: string;
  pending: boolean;
  onDelete: (id: string) => void;
  onClick?: (id: string) => void;
};

const CardComponent: React.FC<CardComponentProps> = ({ id, image, title, price, description, pending, onDelete, onClick }) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showQR, setShowQR] = useState(false);

  const toggleQR = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowQR(!showQR);
  };

  const downloadQR = (e: React.MouseEvent) => {
    e.stopPropagation();
    const element = document.getElementById(`qr-code-${id}`);
    if (element instanceof SVGSVGElement) {
      const svgData = new XMLSerializer().serializeToString(element);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = `qr-code-${id}.png`;
        downloadLink.href = pngFile;
        downloadLink.click();
      };
      img.src = "data:image/svg+xml;base64," + btoa(svgData);
    } else {
      console.error('QR code element not found or is not an SVG element');
    }
  };

  const onDeleteClick = () => {
    setShowPopup(true);
  };

  const handleConfirmDelete = () => {
    onDelete(id);  // Call the onDelete function with the offer ID
    setShowPopup(false);
  };

  const handleCancelDelete = () => {
    setShowPopup(false);
  };

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) {
      onClick(id);
    } else {
      navigate(`/merchant-hub-post-1/${id}`);
    }
  };


  const truncateDescription = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return (
      <>
        {text.slice(0, maxLength)}...
        <span 
          className="text-blue-500 underline cursor-pointer ml-1 font-subhead"
          onClick={toggleDescription}
        >
          view more
        </span>
      </>
    );
  };

  return (
    <div className="relative flex flex-col items-start p-4 bg-gray-800 text-white rounded-lg border border-solid border-zinc-300">
      <div className='flex flex-row w-full'>
      {pending && (
        <img src="pending-ribbon.svg" alt="Pending" className="absolute mt-0.5 -ml-0.5 -top-5 left-0 w-28 h-28" crossOrigin="anonymous" />
      )}
      <img src={image} alt="Deal" className="w-32 h-32 object-cover rounded-md mb-0" crossOrigin="anonymous" />
      <div className="flex flex-col w-full px-5 mq480:px-5">
        <div onClick={handleClick} className="flex flex-col mq480:flex-row w-full mq480:gap-4 items-start justify-between cursor-pointer">
          <div className="text-xl text-black font-bold mb-2">{title}</div>
          <div className="text-blue-400 text-xl mb-2">${price}</div>
        </div>
        <div className="text-[14px] font-subhead mq480:text-sm font-thin text-black mb-4">
          {isExpanded ? description : truncateDescription(description, 120)}
        </div>
        <div className="justify-between mt-2 hidden mq500:flex">
            <button onClick={toggleQR} className="cursor-pointer text-zinc-700 mx-auto text-base text-center bg-transparent font-semibold">
              {showQR ? 'Hide QR Code' : 'Show QR Code'}
            </button>
            {showQR && <button onClick={downloadQR} className="cursor-pointer text-blue-700 mx-auto text-base text-center bg-transparent font-semibold">
              Download QR Code
            </button>}
            <button onClick={onDeleteClick} className="cursor-pointer text-zinc-700 mx-auto text-base text-left bg-transparent font-semibold">Delete</button>
            {showPopup && (
              <ConfirmationPopup
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
              />
            )}
        </div>
          {showQR && (
            <div className="hidden mq500:flex mt-2 px-5">
              <QRCodeSVG
                id={`qr-code-${id}`}
                value={`https://doyouweekend.com/offer/${id}`}
                size={128}
                level="H"
              />
            </div>
          )}
      </div>
    </div>
      {/* Show Action buttons below offer for small screens */}
      <div className='mq500:hidden'>
        <div className="flex justify-between mt-2">
          <button onClick={toggleQR} className="cursor-pointer text-zinc-700 mx-auto text-base text-center bg-transparent font-semibold">
            {showQR ? 'Hide QR Code' : 'Show QR Code'}
          </button>
          {showQR && <button onClick={downloadQR} className="cursor-pointer text-blue-700 mx-auto text-base text-center bg-transparent font-semibold">
            Download QR Code
          </button>}
          <button onClick={onDeleteClick} className="cursor-pointer text-zinc-700 mx-auto text-base text-left bg-transparent font-semibold">Delete</button>
          {showPopup && (
            <ConfirmationPopup
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}
        </div>
          {showQR && (
            <div className="mq500:hidden mt-2 px-5">
              <QRCodeSVG
                id={`qr-code-${id}`}
                value={`https://doyouweekend.com/offer/${id}`}
                size={128}
                level="H"
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default CardComponent;