import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

export type MobileRounderDynamicType = {
  coverImage: string;
  activityTitle: string;
  activityLink?: string;
  categories?: string;
  distance: string;
  numReviews?: string;
  priceOld?: string;
  priceNew: string;
  width?: string; // Add this line
  onActivityClick?: () => void;
};

export type Offer = {
  id: string;
  images: string[];
  name: string;
  longitude: number;
  latitude: number;
  categories: string[];
  price: number;
  description?: string,
  promotionType?: string;
  voucherCap?: number;
  percentageDiscount?: number;
  buy_x?: number;
  get_y?: number;
  width?: string;
}

const ImageSkeleton: React.FC<{ className?: string }> = ({ className }) => (
  <div className={`animate-pulse bg-zinc-300 rounded-lg ${className}`}></div>
);

const MobileRounderDynamic: FunctionComponent<Offer> = ({
  id,
  images = [],
  name = "",
  longitude = 0,
  latitude = 0,
  categories = [],
  price = 0,
  description = "",
  promotionType,
  percentageDiscount,
  buy_x,
  get_y,
  width = '100%',
}) => {
  const [isLiked, setIsLiked] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();

  const handleActivityClick = () => {
    navigate(`/offer/${id}`)
  };

  const parsedLatitude = localStorage.getItem('userLatitude');
  const parsedLongitude = localStorage.getItem('userLongitude');
  const latUser = parsedLatitude ? parseFloat(parsedLatitude) : undefined;
  const longUser = parsedLongitude ? parseFloat(parsedLongitude) : undefined;

  function calculateUserDistance(lat1: number, lon1: number): number {
    if (!latUser || !longUser)
      return 0;
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (latUser - lat1) * Math.PI / 180;
    const dLon = (longUser - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(latUser * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c; // Distance in kilometers
    const distanceMiles = distance * 0.621371;
    return Math.floor(distanceMiles * 10) / 10; //round down to 1 decimal place
  }

  //TODO: Implement API endpoint to persist liked Status
  const handleLikeClick = async () => {
    const jwtToken = localStorage.getItem('jwtToken');
  
    if (!jwtToken) {
      console.error('No JWT token found');
      return;
    }
  
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/users/customer/interactions`,
        {
          interactionType: isLiked ? 'OFFER_UNLIKED' : 'OFFER_LIKED',
          offerId: id
        },
        {
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.status === 201) {
        setIsLiked(!isLiked);
      } else {
        console.error('Failed to update like status');
      }
    } catch (error) {
      console.error('Error updating like status:', error);
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  function CategoriesDisplay(categories: string[] | undefined) {
    return (
      <div>
        {categories && categories.length > 0 && categories.join(" • ")}
      </div>
    );
  }
  
  useEffect(() => {
    const img = new Image();
    img.src = images[0] || "";
    img.onload = () => setImageLoaded(true);
    return () => {
      img.onload = null; // Clean up to avoid memory leaks
    };
  }, [images]);
  
  return (
    <div className={`snap-center py-2 self-stretch ${width} h-auto shrink-0 flex flex-col items-start justify-start box-border text-left text-base text-copy font-subhead relative`}>
      <div className="self-stretch flex-1 flex flex-col items-start justify-start z-[1] relative">
        <div className="relative w-full h-[40vw]">
          {!imageLoaded && <ImageSkeleton className="h-full w-full" />}
          <img
            className={`absolute top-0 left-0 w-full h-full object-cover rounded-lg transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
            src={images[0] || ""}
            alt={description}
            onLoad={() => setImageLoaded(true)}
            onClick={handleActivityClick}
          />
        </div>
        <img
          className="hidden absolute top-0 right-0 mt-1.5 mx-1 h-9 w-9 cursor-pointer"
          alt=""
          src={isLiked ? "/icon--heart1.svg" : "/icon--heart.svg"}
          onClick={handleLikeClick}
        />
        <div className="self-stretch flex flex-col items-start justify-start py-2 px-0 gap-[8px]">
          <div
            className="relative text-base tracking-[-0.01em] font-bold text-main-teal cursor-pointer mq450:text-base"
            onClick={handleActivityClick}
          >
            {truncateText(name, 50)}
          </div>
          <div className="hidden self-stretch text-xs relative font-medium whitespace-pre-wrap">
            {CategoriesDisplay(categories)}
          </div>

          {localStorage.getItem('userLatitude') && localStorage.getItem('userLongitude') &&
          <div className="self-stretch relative text-sm [text-decoration:underline] font-medium">
            {calculateUserDistance(latitude, longitude)} miles away
          </div>}

          {/* RegularOffer */}
          {price > 0 && !promotionType &&
            <div className="w-[187px] flex flex-row items-start justify-start gap-[8px] text-left text-xl text-main-teal">
            <div className="relative tracking-[-0.01em] inline-block whitespace-nowrap mq450:text-lgi font-semibold">
              ${price.toFixed(2)}
            </div>
          </div>
          }
          
          {/* Percentage Discount */}
          {price > 0 && promotionType == "PERCENTAGE_DISCOUNT" && percentageDiscount &&
            <div className="w-[187px] flex flex-row items-start justify-start gap-[8px] text-left text-xl text-main-teal">
              <div className="relative [text-decoration:line-through] tracking-[-0.01em] inline-block whitespace-nowrap mq450:text-lgi">
                ${price.toFixed(2)}
              </div>
              <b className="flex-1 relative tracking-[-0.01em] inline-block text-green min-w-[94px] whitespace-nowrap mq450:text-lgi">
                ${(price*(1-percentageDiscount*0.01)).toFixed(2)}
              </b>
            </div>
          }

          {/* Buy X get Y Discount */}
          {price > 0 && promotionType && promotionType !== "PERCENTAGE_DISCOUNT" && 
            <div className="w-[187px] flex flex-row items-start justify-start gap-[8px] text-left text-xl text-main-teal">
              <div className="relative text-green tracking-[-0.01em] inline-block whitespace-nowrap mq450:text-lgi font-bold">
                ${price.toFixed(2)}
              </div>
              <b className="flex-1 relative tracking-[-0.01em] inline-block min-w-[94px] whitespace-nowrap text-base mt-1 mq450:text-lgi">
                Buy {buy_x} get {get_y}
              </b>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default MobileRounderDynamic;