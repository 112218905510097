import React, { FunctionComponent, useState, useEffect } from "react";
import axios from 'axios';
import { DateTime } from 'luxon';
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import MerchantPage1 from "../../components/Merchant/MerchantSignup/MerchantPage1";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface Category {
  id: string;
  name: string;
}

interface Highlight {
  id: string;
  name: string;
}

const M01MerchantPage: FunctionComponent = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [highlights, setHighlights] = useState<Highlight[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, highlightsResponse] = await Promise.all([
          axios.get<Category[]>(`${API_BASE_URL}/v1/catalog/categories`),
          axios.get<Highlight[]>(`${API_BASE_URL}/v1/catalog/highlights`)
        ]);
        setCategories(categoriesResponse.data);
        setHighlights(highlightsResponse.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
      
    fetchData();
  }, []);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
        <MerchantHubHeader />
      <div className="flex items-center justify-center my-20">
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ||""}>
        <MerchantPage1 categories={categories} highlights={highlights} />
      </GoogleReCaptchaProvider>
      </div>
      <footer className="mt-auto">
        <Footer
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>  
    </div>
  );
};

export default M01MerchantPage;