import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import MerchantHubSideNav from "../../components/Merchant/MerchantHubSideNav";
import { isTokenValid, isMasterUser } from "../../routeProtection/jwtUtils";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

const ResetPasswordModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [resetSuccess, setResetSuccess] = useState(false);
  const [localResetEmail, setLocalResetEmail] = useState("");
  const [error, setError] = useState<string | null>(null);

  const sendPasswordResetRequest = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/users/auth/password-reset/request`,
        { email: localResetEmail }
      );

      if (response.status === 200 || response.status === 204) {
        setResetSuccess(true);
        // console.log('Password reset request sent successfully');
        setTimeout(() => onClose(), 5000);
      }
    } catch (error) {
      console.error('Failed to request password reset:', error);
      setError('Failed to request password reset. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl mb-4">Reset Password</h2>
        <div className="flex mb-4">
          <input
            type="email"
            value={localResetEmail}
            onChange={(e) => setLocalResetEmail(e.target.value)}
            placeholder="Enter your email"
            className="flex-grow p-2 border rounded-l"
          />
          <button
            onClick={sendPasswordResetRequest}
            disabled={!localResetEmail}
            className={`px-4 py-2 rounded-r ${
              localResetEmail
                ? 'bg-main-teal text-white cursor-pointer'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Send
          </button>
        </div>
        {resetSuccess && (
          <p className="text-green-500 text-sm mb-4">Password Reset Email sent!</p>
        )}
        {error && (
          <p className="text-red-500 text-sm mb-4">{error}</p>
        )}
        <button
          onClick={onClose}
          className="text-zinc-500 pl-0 ml-0 bg-white cursor-pointer"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const CloseAccountModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [confirmText, setConfirmText] = useState('');
  const [deletionRequestSent, setDeletionRequestSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate()
  const isValidConfirmation = confirmText === 'Confirm Deletion';

  const handleCloseAccount = async () => {
    if (isValidConfirmation) {
      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          setError('No authentication token found. Please log in.');
          return;
        }

        const response = await axios.post(
          `${API_BASE_URL}/v1/users/account-deletion/request`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        if (response.status === 200 || response.status === 204) {
          setDeletionRequestSent(true);
          localStorage.removeItem("jwtToken");
          setTimeout(() => onClose(), 1000);
          navigate("/");
        }
      } catch (error) {
        console.error('Failed to send account deletion request:', error);
        setError('Failed to send account deletion request. Please try again.');
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <h2 className="text-2xl font-semibold text-blue-900 mb-6">Close Account</h2>
        <p className="text-lg font-semibold mb-4">
          Please enter the text 'Confirm Deletion' to finish closing your account.
        </p>
        <p className="text-base text-gray-600 mb-6">
          Your account will be closed once you click 'Close Account'. Once your
          account is closed, you will not be able to log in or have access to your
          content.
        </p>
        <input
          type="text"
          placeholder="Confirm Deletion"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
          className="w-[95%] px-3  py-2 border border-gray-300 rounded-md mb-6"
        />
        <button
          onClick={handleCloseAccount}
          className={`w-full py-2 px-4 cursor-pointer rounded-md ${
            isValidConfirmation
              ? 'bg-blue-800 text-white hover:bg-[#478abf]'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
          disabled={!isValidConfirmation}
        >
          Close Account
        </button>
        {deletionRequestSent && (
          <p className="text-red-500 mt-2 text-center">Deletion Request Received</p>
        )}
        {error && (
          <p className="text-red-500 mt-2 text-center">{error}</p>
        )}
        <button
          onClick={onClose}
          className="mt-4 w-full py-2 px-4 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const MerchantAccount: FunctionComponent = () => {
  const [showResetModal, setShowResetModal] = useState(false);
  const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);
  const [exportError, setExportError] = useState<string | null>(null);
  const [isMasterUser2, setIsMasterUser] = useState(false);
  const navigate = useNavigate();

  const handleAccountClick = () => {
    navigate("/merchant-business-info")
  };

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      setIsMasterUser(isMasterUser(token));
    }
  }, []);

  const handleExportMerchants = async () => {
    try {
        const token = localStorage.getItem('jwtToken');
        if (!token || !isTokenValid(token)) {
            setExportError('No valid authentication token found. Please log in again.');
            setTimeout(() => setExportError(null), 5000);
            return;
        }

        if (!isMasterUser(token)) {
            setExportError('Unauthorized to export merchants data.');
            setTimeout(() => setExportError(null), 5000);
            return;
        }

        const response = await axios.get(
            `${API_BASE_URL}/v1/merchants/export`,
            {
                headers: { 
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob'
            }
        );

        const blob = new Blob([response.data], { type: 'text/csv' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'merchants_export.csv';
        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(downloadUrl);
        setExportError(null);
    } catch (error: any) {
        const status = error.response?.status;
        const isClientError = status >= 400 && status < 500;

        if (isClientError && error.response?.data instanceof Blob) {
            const blob = error.response.data;
            const reader = new FileReader();

            reader.onload = () => {
                try {
                    const resultText = reader.result as string;
                    const errorData = JSON.parse(resultText);
                    const message = errorData.message || 'An error occurred. Please try again.';
                    setExportError(message);
                } catch {
                    setExportError('Failed to parse error message. Please try again.');
                }
            };

            reader.readAsText(blob);
        } else {
            setExportError('Failed to export merchants data. Please try again later.');
        }

        setTimeout(() => setExportError(null), 5000);
    }
  };

  const handleStripeAccount = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/merchants/account-link`,
        { type: "ONBOARDING" },
        {
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.status === 200 && response.data) {
        const stripeUrl = response.data;
        
        // Redirect to the Stripe URL
        window.open(stripeUrl, '_blank');
      } else {
        console.error('Invalid response from server');
        // Optionally, show an error message to the user
      }
    } catch (error) {
      console.error('Error fetching Stripe account link:', error);
      // Optionally, show an error message to the user
    }
  };

  const handlePasswordReset = () => {
    setShowResetModal(true);
  };

  const handleCloseAccount = () => {
    setShowCloseAccountModal(true);
  };

  const renderContent = () => {
    return (
      <div className="bg-white p-8 rounded-lg font-subhead">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">Account Settings</h2>

        <div className="mb-8 flex flex-col">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Stripe Account </h3>
          <button 
            onClick={handleStripeAccount} 
            className="font-subhead px-4 py-2 bg-green w-[150px] text-white rounded hover:bg-blue-600"
          >
            View Account
          </button>
        </div>

        <div className="mb-8">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Reset Password</h3>
          <button 
            onClick={handlePasswordReset} 
            className="font-subhead px-4 py-2 bg-blue-500 w-[150px] text-white rounded hover:bg-blue-600"
          >
            Reset Password
          </button>
        </div>

        <div>
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Close Account</h3>
          <button 
            onClick={handleCloseAccount}
            className="font-subhead px-4 py-2 bg-red-500 w-[150px] text-white rounded hover:bg-red-600"
          >
            Close Account
          </button>
        </div>

        {isMasterUser2 && (
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Export Merchants</h3>
            <div className="flex flex-col gap-2">
              <button 
                onClick={handleExportMerchants} 
                className="font-subhead px-4 py-2 bg-blue-500 w-[150px] text-white rounded hover:bg-blue-600"
              >
                Export CSV
              </button>
              {exportError && (
                <p className="text-red-500 text-sm">{exportError}</p>
              )}
            </div>
          </div>
        )}

        {showResetModal && <ResetPasswordModal onClose={() => setShowResetModal(false)} />}
        {showCloseAccountModal && <CloseAccountModal onClose={() => setShowCloseAccountModal(false)} />}
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MerchantHubHeader />
      <div className="flex flex-1">
        <MerchantHubSideNav selected="Account"/>
        <div className="flex-1 p-8 pt-0 bg-gray-100 font-subhead">
          {renderContent()}
        </div>
      </div>
      <Footer
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </div>
  );
};

export default MerchantAccount;