import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const OAuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      const userType = searchParams.get('userType');

      if (token && userType) {
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('userType', userType);
        // Configure axios to use the token for future requests
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        try {
          // Fetch user profile
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/users/customer/profile`);
          const profile = response.data;

          if (profile.interestedCategories.length === 0) {
            // Redirect to profile page with a flag to show interests popup
            navigate('/account/profile', { state: { isOAuthSignup: true } });
          } else {
            // If interests are already set, redirect to home page
            navigate('/');
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
          navigate('/account/profile', { state: { isOAuthSignup: true } });
        }
      } else {
        console.error('No token or userType received');
        navigate('/login');
      }
    };

    handleCallback();
  }, [navigate, location]);

  return <div>Processing authentication...</div>;
};

export default OAuthCallback;