import React, { FunctionComponent, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";
import axios from 'axios';
import { createOrder, createCheckoutSession } from '../../api/stripe/stripeOrders';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

export type LikedItemMobileType = {
  id: string;
  title: string;
  description: string;
  image: string;
  price?: number;
  dealpage?: string;
};

interface Itinerary {
  id: string;
  name: string;
  offerIds: string[];
}

const LikedItemMobile: FunctionComponent<LikedItemMobileType> = ({
  id,
  title,
  description,
  image,
  price,
  dealpage="/",
}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
    const [isHoursDropdownOpen, setIsHoursDropdownOpen] = useState(false);
    const [isContactDropdownOpen, setContactDropdownOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [itineraries, setItineraries] = useState<Itinerary[]>([]);
    const [selectedItineraries, setSelectedItineraries] = useState<string[]>([]);
    const [initialSelectedItineraries, setInitialSelectedItineraries] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchItineraries = async () => {
            try {
                const jwtToken = localStorage.getItem("jwtToken");
                if (!jwtToken) {
                    throw new Error('No JWT token found');
                }

                const response = await fetch(`${API_BASE_URL}/v1/itineraries`, {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch itineraries');
                }
                const data: Itinerary[] = await response.json();
                setItineraries(data);

                // Find itineraries that already contain the offer
                const preSelectedItineraries = data
                    .filter(itinerary => itinerary.offerIds.includes(id))
                    .map(itinerary => itinerary.id);
                setSelectedItineraries(preSelectedItineraries);
                setInitialSelectedItineraries(preSelectedItineraries);
            } catch (error) {
                console.error('Error fetching itineraries:', error);
            }
        };
        fetchItineraries();
    }, [id]);

    const toggleHoursDropdown = () => {
        setIsHoursDropdownOpen(!isHoursDropdownOpen);
    };

    const toggleContactDropdown = () => {
        setContactDropdownOpen(!isContactDropdownOpen);
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
        setIsConfirmationPopupOpen(false);
    };

    const toggleConfirmationPopup = () => {
      setIsPopupOpen(false);
      setIsConfirmationPopupOpen(false);
    };

    const handleBookNow = async () => {
        try {
            const orderResponse = await createOrder(id, quantity);
            if (orderResponse && orderResponse.id) {
                const checkoutSessionResponse = await createCheckoutSession(orderResponse.id);
                
                if (checkoutSessionResponse) {
                    navigate(`/checkout/${orderResponse.id}`);
                }
            }
        } catch (error) {
            console.error('Error during booking process:', error);
        }
    };

    const handleItinerarySelection = (e: React.ChangeEvent<HTMLInputElement>, itineraryId: string) => {
        if (e.target.checked) {
            setSelectedItineraries(prev => [...prev, itineraryId]);
        } else {
            setSelectedItineraries(prev => prev.filter(id => id !== itineraryId));
        }
    };

    const handleAddToItineraries = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        
        try {
            const jwtToken = localStorage.getItem("jwtToken");
            if (!jwtToken) {
                throw new Error('No JWT token found');
            }
        
            const itinerariesToAdd = selectedItineraries.filter(itId => !initialSelectedItineraries.includes(itId));
            const itinerariesToRemove = initialSelectedItineraries.filter(itId => !selectedItineraries.includes(itId));
        
            for (const itineraryId of itinerariesToAdd) {
                await fetch(`${API_BASE_URL}/v1/itineraries/${itineraryId}/offer/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            }
        
            for (const itineraryId of itinerariesToRemove) {
                await fetch(`${API_BASE_URL}/v1/itineraries/${itineraryId}/offers/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            }
        
            setInitialSelectedItineraries(selectedItineraries);
            togglePopup();
            setIsConfirmationPopupOpen(true);
            // // console.log('Itineraries updated successfully');
        } catch (error) {
            console.error('Error updating itineraries:', error);
        }
    };

    const onNewItineraryClick = () => {
        setIsPopupOpen(false);
        setIsConfirmationPopupOpen(!isConfirmationPopupOpen);
    };

    const onViewItineraryClick = () => {
        setIsConfirmationPopupOpen(false);
        navigate("/itineraries");
    };

    const truncateString = (str: string, num: number): string => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    const truncatedDescription = useMemo(() => truncateString(description, 65), [description]);

    return (
        <section className="w-full max-w-full py-2 h-auto bg-zinc-50">
            <div className="flex flex-row gap-2">
                <img 
                    src={image}
                    className="w-[16vh] h-[12vh] items-center justify-center p-5 roundded-lg "
                    crossOrigin="anonymous"
                />
                <div className="flex flex-col text-start pr-5">
                    <h1 className="font-subhead text-6xl text-semibold text-black m-0 mb-1 mt-5">{title}</h1>
                    <p className="font-subhead text-xxs mq480:text-base text-zinc-900 mt-1 leading-[20px]">{truncatedDescription}
                        <a href={`/offer/${id}`} className="text-blue-900 mt-1 text-xxs mq480:text-base font-semibold"><br/>View Details</a>
                    </p>
                </div>
            </div>
            <div className="flex flex-row mt-2 gap-3 justify-between items-start px-5">
                <button 
                    className="w-[50%] rounded-lg cursor-pointer [border:none] py-[11px] px-2 bg-main-teal flex flex-row items-center justify-center relative whitespace-nowrap z-[1]"
                    onClick={togglePopup}
                >
                    <div className="h-full w-full absolute !m-[0] top-[0px] right-[1px] bottom-[0px] left-[0px] rounded-md bg-main-teal box-border border-[1px] border-solid border-main-teal hover:border-blue-400" />
                    <b className="py-1 w-[167px] relative text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-zinc-100 text-center z-[1]">
                      + Add to Itinerary
                    </b>
                </button>
                <button 
                    className="w-[45%] cursor-pointer [border:none] py-[11px] px-2 bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap z-[1]"
                    onClick={handleBookNow}
                >
                    <div className="h-full w-full absolute !m-[0] top-[0px] right-[1px] bottom-[0px] left-[0px] rounded-md bg-white box-border border-[1px] border-solid border-main-teal" />
                    <b className="w-[167px] relative py-1 text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-main-teal hover:text-blue-800 text-center z-[1]">
                        Book Now
                    </b>
                </button>
            </div>
            <div className="self-stretch h-1 box-border mt-7 shrink-0 border-t-[1px] border-solid border-zinc-200 pb-4" />
            {isPopupOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="relative bg-white p-8 rounded-lg w-full max-w-[80vw] max-h-[80vh] overflow-y-auto font-subhead px-5">
                  <X className="cursor-pointer absolute top-3 right-3 w-7 h-7 stroke-zinc-700 font-light" onClick={togglePopup} />
                  <h2 className="text-5xl font-light mb-6 text-center">Select an Itinerary</h2>
                  <ul className="text-base mb-6 text-start text-zinc-900">
                    {itineraries.map((itinerary, index) => (
                      <li key={itinerary.id} className="mb-2 flex items-start">
                        <input 
                          type="checkbox" 
                          id={`itinerary${index}`} 
                          name={`itinerary${index}`}
                          checked={selectedItineraries.includes(itinerary.id)}
                          onChange={(e) => handleItinerarySelection(e, itinerary.id)}
                          className="mt-1 flex-shrink-0"
                        />
                        <label htmlFor={`itinerary${index}`} className="ml-2 font-semibold flex-grow">{itinerary.name}</label>
                      </li>
                    ))}
                  </ul>
                  <div className="flex flex-col items-center">
                    <div className="bg-zinc-400 w-full h-[1px] mb-6" />
                    <button 
                      onClick={handleAddToItineraries}
                      className={`w-full max-w-[192px] cursor-pointer font-semibold mb-3 py-3 rounded ${
                        selectedItineraries.length !== initialSelectedItineraries.length 
                          ? 'hover:bg-blue-800 bg-main-teal text-white' 
                          : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      }`}
                      disabled={selectedItineraries.length === initialSelectedItineraries.length}
                    >
                      Add to Itinerary
                    </button>
                    <button onClick={onNewItineraryClick} className="w-full max-w-[192px] cursor-pointer text-main-teal font-bold bg-white border border-solid border-main-teal py-3 rounded">
                      Add to new Itinerary
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isConfirmationPopupOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="relative bg-white pt-8 pb-4 rounded-lg w-full max-w-[80vw] max-h-[80vh] overflow-y-auto font-subhead px-5">
                  <X className="cursor-pointer absolute top-3 right-3 w-7 h-7 stroke-zinc-700 font-light" onClick={toggleConfirmationPopup} />
                  <h2 className="text-5xl text-black font-semibold mb-6 text-center">Success</h2>
                  <p className="text-base text-zinc-800 font-base mb-6 text-center"><p className="text-5xl text-blue-800 font-semibold text-center m-0">{title}</p>was added to your Itinerary!</p>
                  <div className="flex flex-col items-center">
                    <button onClick={onViewItineraryClick} className="w-full max-w-[192px] my-3 cursor-pointer hover:bg-blue-800 font-semibold mb-6 bg-main-teal text-white text-xl py-4 px-2 rounded-lg">
                      View Itinerary
                    </button>
                    <div className="bg-zinc-400 w-full h-[1px] mb-6" />
                    <p className="text-[15px] text-blue-800 font-base mb-1 mt-0 text-center">Other popular Destinations Near</p>
                    <p className="text-base text-blue-800 font-bold mb-2 mt-0 text-center">{title}</p>
                  </div>
                  {/* @Recommendation Service */}
                  <div className="flex flex-row gap-2 mt-2 justify-between w-full bg-white-100">
                    <div className="flex flex-col gap-1 items-center justify-center text-center">
                      <img src="apple-picking@2x.png" className="w-[20vw] rounded-sm" crossOrigin="anonymous" />
                      <p className="text-xs text-zinc-700 font-subhead  mt-1">{title}</p>
                    </div>
                    <div className="flex flex-col gap-1 items-center justify-center text-center">
                      <img src="apple-picking@2x.png" className="w-[20vw] rounded-sm" crossOrigin="anonymous" />
                      <p className="text-xs text-zinc-700 font-subhead  mt-1">{title}</p>
                    </div>
                    <div className="flex flex-col gap-1 items-center justify-center text-center">
                      <img src="apple-picking@2x.png" className="w-[20vw] rounded-sm" crossOrigin="anonymous" />
                      <p className="text-xs text-zinc-700 font-subhead  mt-1">{title}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </section>
    );
};

export default LikedItemMobile;