import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/User/Header";
import UserFooter from "../../../components/User/UserFooter";
import { ArrowLeft } from "lucide-react";
import SideNavUserProfile from "../../../components/User/SideNavUserProfile";

const ContactSupport: FunctionComponent = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [lastSentTime, setLastSentTime] = useState(0);

  const handleBackClick = () => {
    navigate(-1);
  };

  const isValidEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  useEffect(() => {
    setIsFormValid(
      name.length > 1 &&
      isValidEmail(email) &&
      subject.length > 1 &&
      message.length > 1
    );
  }, [name, email, subject, message]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const currentTime = Date.now();
    if (isFormValid && currentTime - lastSentTime > 60000) { // 60000 ms = 1 minute
      const mailtoLink = `mailto:andrew@doyouweekend.com?subject=USER%20Support%20Request%20from%20${encodeURIComponent(name)}%20&body=${encodeURIComponent(message)}`;
      window.location.href = mailtoLink;
      setLastSentTime(currentTime);
      // Reset form
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
    } else if (currentTime - lastSentTime <= 60000) {
      alert("Please wait at least one minute before sending another message.");
    }
  };

  return (
    <>
      {/* Desktop (>=780px) */}
      <div className="hidden mq780:flex flex-col min-h-screen">
        <Header />
        <div className="flex flex-1 bg-gray-100">
          <SideNavUserProfile activeItem="Contact Support" />
          <div className="flex-1 p-8 font-subhead">
            <div className="bg-white rounded-lg p-6 max-w-2xl mx-auto">
              <h2 className="text-2xl font-semibold text-blue-900 mb-4">Contact Support</h2>
              <p className="text-gray-600 mb-6">Tell us how we can help and we'll get back to you within less than 24 hours.</p>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="font-subhead w-[95%] px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="font-subhead w-[95%] px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">Subject</label>
                  <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="font-subhead w-[95%] px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Message</label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={4}
                    className="font-subhead w-[95%] px-3 py-2 border border-gray-300 rounded-md"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className={`font-subhead w-full py-2 px-4 rounded-md ${
                    isFormValid && Date.now() - lastSentTime > 60000
                      ? 'bg-blue-500 text-white hover:bg-[#478abf] cursor-pointer'
                      : 'bg-gray-300 text-gray-200 cursor-not-allowed'
                  }`}
                  disabled={!isFormValid || Date.now() - lastSentTime <= 60000}
                >
                  Email Support Request
                </button>
              </form>
            </div>
          </div>
        </div>
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </div>

      {/* Mobile (<780px) */}
      <div className="mq780:hidden w-full relative bg-white overflow-hidden flex flex-col justify-between px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead min-h-screen">
        <div className="flex justify-between items-center px-4 mt-3 mb-1">
          <button onClick={handleBackClick} className="text-gray-500 pt-2 bg-white">
            <ArrowLeft className='w-7 h-7 stroke-gray-300' />
          </button>
          <h2 className="text-xl font-semibold">Contact Support</h2>
          <div className="w-7 h-7"></div>
        </div>
        <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
        <div className="flex-1 p-4">
          <p className="text-gray-600 mb-6">Tell us how we can help and we'll get back to you within less than 24 hours..</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-4 text-start">
              <label htmlFor="name-mobile" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
              <input
                style={{ width: 'calc(100vw - 64px)' }}
                type="text"
                id="name-mobile"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="font-subhead w-[90%] px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4 text-start">
              <label htmlFor="email-mobile" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
              <input
                style={{ width: 'calc(100vw - 64px)' }}
                type="email"
                id="email-mobile"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="font-subhead px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4 text-start">
              <label htmlFor="subject-mobile" className="block text-sm font-medium text-gray-700 mb-1">Subject</label>
              <input
                style={{ width: 'calc(100vw - 64px)' }}
                type="text"
                id="subject-mobile"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="font-subhead px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-6 text-start">
              <label htmlFor="message-mobile" className="block text-sm font-medium text-gray-700 mb-1">Message</label>
              <textarea
                style={{ width: 'calc(100vw - 64px)' }}
                id="message-mobile"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={4}
                className="font-subhead px-3 py-2 border border-gray-300 rounded-md"
              ></textarea>
            </div>
            <button
              type="submit"
              className={`font-subhead w-full cursor-pointer mb-20 mt-4 py-2 px-4 rounded-md ${
                isFormValid && Date.now() - lastSentTime > 60000
                  ? 'bg-blue-500 text-white hover:bg-[#478abf] cursor-pointer'
                  : 'bg-gray-300 text-gray-200 cursor-not-allowed'
              }`}
              disabled={!isFormValid || Date.now() - lastSentTime <= 60000}
            >
              Email Support Request
            </button>
          </form>
        </div>
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </div>
    </>
  );
};

export default ContactSupport;