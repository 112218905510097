import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RatingStars from "../../components/General/RatingStars";
import UserFooter from "../../components/User/UserFooter";
import Header from "../../components/User/Header";
import { ImageSlider } from "../../components/General/ImageSlider";
import Map from "../../components/General/Map";
import Rounder from "../../components/General/Rounder";
import Caroussel from "../../components/User/Caroussel";
import MobileRounder from "../../components/Mobile/MobileRounder";
import { ChevronDown, X } from "lucide-react";
import { ImageSliderMobile } from "../../components/Mobile/ImageSliderMobile";
import HighlightSection from "../../components/General/HighlightSection";
import { createOrder, createCheckoutSession } from '../../api/stripe/stripeOrders';
import { DateTime } from "luxon";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface AdditionalInfoSectionProps {
    title: string;
    description: string;
    price_old?: string;
    price_new?: string;
    rating?: string;
    before_you_go?: string;
    highlights?: string;
    location?: string;
    reviews?: [string];
    images?: [string];
}

interface Highlight {
  id: string;
  name: string;
}

interface Itinerary {
  id: string;
  name: string;
  offerIds: string[];
}

interface MerchantProfile {
  //customerEmail?: string;
  //customerPhone?: string;
  name: string;
  description: string;
  openingHours: {
    schedule: {
      [key: string]: {
        closed: boolean;
      };
    };
  };
}

interface Offer {
  id: string;
  merchantId: string;
  name: string;
  displayImageReferences: string[];
  price: number;
  locations: {
    latitude: number;
    longitude: number;
  }[];
  categoryIds: string[];
  promotionType?: string;
  percentageDiscount?: number;
  minimumPurchaseAmount?: number;
  discountValue?: number;
}

interface PromotionDataInterface {
  offerIds: string[];
  type: 'PERCENTAGE_DISCOUNT' | 'BUY_X_GET_Y_FREE';
  discountValue: number;
  minimumPurchaseAmount?: number;
  maxUsageCount: number;
  buy_x?: number;
  get_y?: number;
  recurrencePattern: {
    startTime?: DateTime;
    endTime?: DateTime;
    daysOfWeek?: string[];
    frequency?: string;
    startDate?: string;
    endDate?: string;
  };
  taxCode: string;
}

interface OfferDataInterface {
    id: string;
    merchantId: string;
    merchantName: string,
    name: string;
    description: string;
    additionalInfo?: string;
    locations: {
      name: string;
      address: string;
      city: string;
      country: string;
      postalCode: string;
      region: string;
      latitude: number;
      longitude: number;
    }[];
    recurrencePattern: {
      startTime?: DateTime;
      endTime?: DateTime;
      daysOfWeek?: string[];
      frequency?: string;
      startDate?: string;
      endDate?: string;
    };
    categoryIds?: string[];
    stock?: number;
    hasUnlimitedStock?: boolean;
    price: number;
    highlights?: Highlight[];
    tags?: string[];
    displayImageReferences: string[];
    master: boolean; 
}

const AdditionalInfoSection: React.FC<AdditionalInfoSectionProps> = ({
    title,
    description,
  }) => (
    <div className="flex flex-col mt-1 max-md:mt-10">
      <div className="text-xl font-semibold tracking-tight text-blue-900">
        {title}
      </div>
      <div className="whitespace-pre-wrap mt-5 text-base leading-7 text-zinc-800">{description}</div>
    </div>
  );

  const ImageSkeleton: React.FC<{ className?: string }> = ({ className }) => (
    <div className={`animate-pulse bg-gray-200 ${className}`}></div>
  );

  const DesktopImageSkeleton: React.FC = () => (
    <div className="animate-pulse bg-zinc-300 rounded-lg w-[45vw] h-[420px]"></div>
  );

const H51DetailsPage: FunctionComponent = () => {
  const [coverImageLoaded, setCoverImageLoaded] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [quantity, setQuantity] = useState<number>(1);
  const [isHoursDropdownOpen, setIsHoursDropdownOpen] = useState(false);
  const [isContactDropdownOpen, setContactDropdownOpen] = useState(false);
  const [isKnowBeforeYouGoOpen, setIsKnowBeforeYouGoOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const [itineraries, setItineraries] = useState<Itinerary[]>([]);
  const [selectedItineraries, setSelectedItineraries] = useState<string[]>([]);
  const [initialSelectedItineraries, setInitialSelectedItineraries] = useState<string[]>([]);
  const [merchantProfile, setMerchantProfile] = useState<MerchantProfile | null>(null);
  const [openingHours, setOpeningHours] = useState<any>(null);
  const [isPreparing, setIsPreparing] = useState(false);
  const [similarOffers, setSimilarOffers] = useState<Offer[]>([]);
  const [offerData, setOfferData] = useState<Partial<OfferDataInterface>>({
      id: '',
      name: '',
      description: '',
      locations: [{
        name: "",
        address: "",
        city: "",
        country: "",
        postalCode: "",
        region: "",
        latitude: 0,
        longitude: 0,
      }],
      categoryIds: [],
      stock: 0,
      hasUnlimitedStock: false,
      merchantName: "",
      tags: [],
      highlights: [],
      price: 0,
      displayImageReferences: [],
  });
  const [promotionData, setPromotionData] = useState<PromotionDataInterface | null>(null);
  const navigate = useNavigate();
  const offersRounder = [
    { 
      id: offerData.id || '',
      name: offerData.name || '',
      images: offerData.displayImageReferences || [],
      price: offerData.price || 0,
      latitude: offerData.locations && offerData.locations[0] ? offerData.locations[0].latitude : 0,
      longitude: offerData.locations && offerData.locations[0] ? offerData.locations[0].longitude : 0,
      categories: offerData.categoryIds || []
    },
  ];

  const { offerId } = useParams<{ offerId?: string }>();

  const isOfferActive = () => {
    const now = new Date();
    const startDate = offerData.recurrencePattern?.startDate ? new Date(offerData.recurrencePattern.startDate) : null;
    const endDate = offerData.recurrencePattern?.endDate ? new Date(offerData.recurrencePattern.endDate) : null;
    
    if (!startDate || !endDate) {
      return true; // or false, depending on what you want to do when dates are not available
    }
    
    return now >= startDate && now <= endDate;
  };

  const sendInteraction = async () => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (!jwtToken || !offerId || !offerData.merchantId) return;
  
    try {
      await axios.post(
        `${API_BASE_URL}/v1/users/customer/interactions`,
        {
          interactionType: "OFFER_VIEWED",
          // merchantId: offerData.merchantId,
          offerId: offerId
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`
          }
        }
      );

    } catch (error) {
      console.error('Error sending interaction:', error);
    }
  };

  useEffect(() => {
    if (offerData.id && offerData.merchantId) {
      sendInteraction();
    }
  }, [offerId, offerData.merchantId]);

  useEffect(() => {
    // Reset states
    setOfferData({});
    setPromotionData(null);
    setOpeningHours(null);
    setSimilarOffers([]);
  
    const fetchPromotionData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/v1/promotions/by-offer/${offerId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        if (response.data.length > 0) {
          setPromotionData(response.data[response.data.length - 1]); // Set the LATEST promotion
        } else {
          setPromotionData(null); // Set to null if no promotions
        }
      } catch (error) {
        console.error('Error fetching promotion data:', error);
        setPromotionData(null); // Set to null in case of error
      }
    };
  
    const fetchOfferData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/v1/catalog/offers/${offerId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        setOfferData(response.data);
        document.title = `${response.data.name} | DoYouWeekend`;
        return response.data;
      } catch (error) {
        console.error('Error fetching offer:', error);
        navigate("/")
      }
    };  
  
    const fetchOpeningHours = async (merchantId: string) => {
      try {
        const response = await axios.get(`${API_BASE_URL}/v1/merchants/${merchantId}/opening-hours`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        setOpeningHours(response.data.schedule);
      } catch (error) {
        console.error('Error fetching opening hours:', error);
      }
    };
    
    const fetchSimilarOffers = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/v1/recommendations/similar/${offerId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        
        const similarOffersData = response.data;
        
        // Fetch promotions for similar offers
        const offerIds = similarOffersData.map((offer: Offer) => offer.id);
        const promotions = await fetchPromotionsForSimilarOffers(offerIds);
        
        // Apply promotions to similar offers
        const offersWithPromotions = applyPromotionsToSimilarOffers(similarOffersData, promotions);
        
        setSimilarOffers(offersWithPromotions);
      } catch (error) {
        console.error('Error fetching similar offers:', error);
      }
    };
  
    const fetchAllData = async () => {
      const offerData = await fetchOfferData();
      if (offerData && offerData.merchantId) {
        await Promise.all([
          fetchPromotionData(),
          fetchOpeningHours(offerData.merchantId),
          fetchSimilarOffers()
        ]);
      }
    };
  
    fetchAllData();
  }, [offerId, navigate]);
  
  
  useEffect(() => {
    const fetchItineraries = async () => {
      try {
        const jwtToken = localStorage.getItem("jwtToken");
        if (!jwtToken) {
          throw new Error('No JWT token found');
        }
  
        const response = await fetch(`${API_BASE_URL}/v1/itineraries`, {
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch itineraries');
        }
        const data: Itinerary[] = await response.json();
        setItineraries(data);
  
        // Find itineraries that already contain the offer
        if(offerId) { //necessary becasue TypeScript
          const preSelectedItineraries = data
            .filter(itinerary => itinerary.offerIds.includes(offerId))
            .map(itinerary => itinerary.id);
        setSelectedItineraries(preSelectedItineraries);
        setInitialSelectedItineraries(preSelectedItineraries);
        }

      } catch (error) {
        console.error('Error fetching itineraries:', error);
      }
    };
    fetchItineraries();
  }, [offerId]);

  async function fetchPromotionsForSimilarOffers(offerIds: string[]): Promise<any> {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(`${API_BASE_URL}/v1/promotions/by-offers`, {
        params: { offerIds: offerIds.join(',') },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching promotions for similar offers:', error);
      return [];
    }
  }

  function applyPromotionsToSimilarOffers(offers: Offer[], promotions: any[]): Offer[] {
    return offers.map(offer => {
      const promotion = promotions.find(promo => promo.offerIds.includes(offer.id));
      if (promotion) {
        return {
          ...offer,
          promotionType: promotion.type,
          percentageDiscount: promotion.type === 'PERCENTAGE_DISCOUNT' ? promotion.discountValue : undefined,
          minimumPurchaseAmount: promotion.minimumPurchaseAmount,
          discountValue: promotion.discountValue,
        };
      }
      return offer;
    });
  }

  function displayPrice(price: number | undefined) {
    if(!price)
      return
    else if (Math.round(price) == price)
      return price.toString()+".00"
    else if (Math.round(price*10)/10 == price)
      return price.toString()+"0"
    else {
      return price.toFixed(2)
    }
  }

  const handleItinerarySelection = (e: React.ChangeEvent<HTMLInputElement>, itineraryId: string) => {
    if (e.target.checked) {
      setSelectedItineraries(prev => [...prev, itineraryId]);
    } else {
      setSelectedItineraries(prev => prev.filter(id => id !== itineraryId));
    }
  };

  const handleAddToItineraries = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    try {
      const jwtToken = localStorage.getItem("jwtToken");
      if (!jwtToken) {
        throw new Error('No JWT token found');
      }
  
      const itinerariesToAdd = selectedItineraries.filter(id => !initialSelectedItineraries.includes(id));
      const itinerariesToRemove = initialSelectedItineraries.filter(id => !selectedItineraries.includes(id));
  
      for (const itineraryId of itinerariesToAdd) {
        await fetch(`${API_BASE_URL}/v1/itineraries/${itineraryId}/offers/${offerId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        });
      }
  
      for (const itineraryId of itinerariesToRemove) {
        await fetch(`${API_BASE_URL}/v1/itineraries/${itineraryId}/offers/${offerId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        });
      }
  
      // Update the initial selected itineraries
      setInitialSelectedItineraries(selectedItineraries);
  
      // Close the popup and show a success message
      togglePopup();
    } catch (error) {
      console.error('Error updating itineraries:', error);
    }
  };

  const handleBookNow = async () => {
    if (isPreparing) return; // Prevent multiple clicks

    setIsPreparing(true);
    
    setTimeout(() => {
      setIsPreparing(false);
    }, 5000);

    try {
      // Create the order
      if (offerId) { //Ghetto
        const orderResponse = await createOrder(offerId, quantity);
      if (orderResponse && orderResponse.id) {
        // Create the checkout session
        const checkoutSessionResponse = await createCheckoutSession(orderResponse.id);
        
        if (checkoutSessionResponse) {
          // Redirect to the checkout page
          navigate(`/checkout/${orderResponse.id}`);
        }
      }
    }
    } catch (error) {
      console.error('Error during booking process:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const formatOpeningHours = (schedule: any) => {
    const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    
    return daysOfWeek.map(day => {
      const daySchedule = schedule[day];
      const formattedDay = day.charAt(0) + day.slice(1).toLowerCase();
      
      if (daySchedule.closed) {
        return `${formattedDay}: Closed`;
      } else {
        // Remove timezone indicators and format time
        const openTime = daySchedule.openTime.slice(0, 5);
        const closeTime = daySchedule.closeTime.slice(0, 5);
        return `${formattedDay}: ${openTime} - ${closeTime}`;
      }
    });
  };

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return "N/A";
    return DateTime.fromISO(dateString).toFormat("yyyy-MM-dd");
  };

  const handleAddressClick = (e: React.MouseEvent, address: string | undefined) => {
    if (!address) return;
  
    e.preventDefault();
    const encodedAddress = encodeURIComponent(address);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    const appleMapsUrl = `http://maps.apple.com/?q=${encodedAddress}`;
  
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      window.location.href = appleMapsUrl;
    } else if (/Android/i.test(navigator.userAgent)) {
      window.location.href = googleMapsUrl;
    } else {
      // For desktop or other devices, offer a choice
      if (window.confirm('Open in Google Maps?')) {
        window.open(googleMapsUrl, '_blank');
      } else {
        window.open(appleMapsUrl, '_blank');
      }
    }
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setIsConfirmationPopupOpen(false);
  };

  const toggleConfirmationPopup = () => {
    setIsPopupOpen(false);
    setIsConfirmationPopupOpen(false);
    };

  const toggleDescriptionOpen = () => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };

  const toggleHoursDropdown = () => {
      setIsHoursDropdownOpen(!isHoursDropdownOpen);
  };

  const toggleContactDropdown = () => {
      setContactDropdownOpen(!isContactDropdownOpen);
  };

  const toggleKnowBeforeYouGoOpen = () => {
    setIsKnowBeforeYouGoOpen(!isKnowBeforeYouGoOpen);
  };

  const onAddToItineraryClick = () => {
    navigate("/itineraries");
  }

  const onNewItineraryClick = () => {
    navigate("/itineraries");
  }

  const onViewItineraryClick = () => { //itinerary_id?: string
    setIsConfirmationPopupOpen(false);
    navigate("/itineraries"); //add logic to navigate to itinerary passed
  }

  return (
  <>
  <form className="hidden mq780:flex m-0 bg-white max-w-full overflow-hidden flex flex-col items-start justify-start px-0 pb-0 box-border gap-[5px] leading-[normal] tracking-[normal]">
      <Header />
      <section className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-8 box-border gap-[9px] max-w-full">
        <div className="self-stretch bg-white flex flex-col items-center justify-start pt-[10px] px-5 pb-4 box-border mq1050:pt-5 mq1050:pb-5 mq1050:box-border">
          <div className="w-[1441px] h-[1040px] relative bg-white hidden max-w-full" />
          <h1 className="m-0 w-[1444px] pb-3 relative text-[40px] leading-[80px] font-semibold font-subhead text-dark-navy text-left inline-block shrink-0 max-w-full z-[1] mq450:text-17xl mq450:leading-[48px] mq1050:text-29xl mq1050:leading-[64px]">
            {offerData.name || "Loading Offer..."}
          </h1>
          <div className="w-[1444px] flex flex-row items-start justify-start pt-0 px-0 pb-16 box-border gap-[32px] max-w-full mq750:gap-[16px]">
          {!coverImageLoaded && 
            <div className="hidden mq780:block relative w-[45vw] h-[420px]">
              <DesktopImageSkeleton />
            </div>
          } 
          <div className="relative">
            <ImageSkeleton className={`w-full h-[400px] ${coverImageLoaded ? 'hidden' : ''}`} />
            {offerData.displayImageReferences &&
            <ImageSlider 
              images={offerData.displayImageReferences.map(url => ({ url }))} 
            />}
          </div>
            <div className="w-[396px] font-subhead flex flex-col items-end justify-start gap-[20px] min-w-[396px] max-w-full mq450:gap-[10px] mq750:min-w-full mq1050:flex-1">
              <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                
                {/* No Promotion */}
                {(!promotionData?.offerIds || promotionData.offerIds.length === 0) && offerData.price !== 0 && (
                <div className="self-stretch flex flex-row items-center justify-start py-0 pr-5 pl-0">
                  <div className="flex flex-row items-center justify-start gap-[10px] pb-2">
                    <p className="m-0 font-subhead text-29xl pr-2 text-main-teal font-base tracking-[-0.01em] inline-block">
                      ${displayPrice(offerData.price)}
                    </p>
                  </div>
                </div>
                )}

                {/* Percentage Discount */}
                {offerData.price != null && 
                typeof offerData.price === 'number' && 
                offerData.price !== 0 && 
                promotionData && 
                promotionData?.offerIds?.length !== 0 && 
                promotionData?.discountValue && 
                promotionData?.type === "PERCENTAGE_DISCOUNT" && (
                <div className="self-stretch flex flex-row items-center justify-start py-0 pr-5 pl-0">
                  <div className="flex flex-row items-center justify-start gap-[10px] pb-2">
                    <p className="m-0 font-subhead text-29xl pr-2 [text-decoration:line-through] tracking-[-0.01em] inline-block">
                      ${displayPrice(offerData.price)}
                    </p>
                    <h1 className="m-0 font-subhead text-29xl pr-2 text-green tracking-[-0.01em] inline-block">
                      ${displayPrice(offerData.price * (1 - (promotionData?.discountValue ?? 0) * 0.01))}
                    </h1>
                  </div>
                </div>
                )}

                {/* Buy X get Y Discount */}
                {promotionData && promotionData?.offerIds?.length !== 0 && promotionData?.type === "BUY_X_GET_Y_FREE" && promotionData?.minimumPurchaseAmount && promotionData?.discountValue && (
                <>
                <div className="self-stretch flex flex-row items-center justify-start py-0 pr-5 pl-0">
                  <div className="flex flex-row items-center justify-start gap-[10px] pb-2">
                    <p className="m-0 font-subhead text-29xl font-bold pr-2 text-green tracking-[-0.01em] inline-block">
                      ${displayPrice(offerData.price)}
                    </p>
                  </div>
                  <div className="flex flex-row gap-2 text-black font-subhead mt-1 pl-2">
                    <p className="text-3xl m-0 -mt-1 mr-1 text-black">Buy</p>
                    <p className="text-11xl m-0 -mt-2 mr-1 text-main-teal font-semibold">{promotionData.minimumPurchaseAmount}</p>
                    <p className="text-3xl m-0 -mt-1 mr-1 text-black">get</p>
                    <p className="text-11xl m-0 -mt-2 text-main-teal font-semibold">{promotionData.discountValue}</p>
                  </div>
                </div>
                </>
              )}
              {offerData.merchantName && <a href={`/business/${offerData.merchantId}`} className="text-lg text-zinc-800 mb-4 -mt-3 cursor-pointer font-subhead mt-1 [text-decoration:underline]">View Business</a>}
              {offerData.price !== 0 &&
                <div className="flex items-center  w-[130px] mb-4">
                  <label htmlFor="quantity" className="mr-2 mb-1">Quantity:</label>
                  <input 
                    id="quantity"
                    name="quantity"
                    type="number"
                    value={quantity}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantity(parseInt(e.target.value))}
                    className="w-full rounded-lg py-1 pl-1 text-center pr-2"
                  />
                </div>
              }
            <div className="pr-4 py-5 self-stretch flex flex-col items-start justify-start gap-[10px]">
            <div onClick={toggleHoursDropdown} className="cursor-pointer w-full justify-between flex flex-row items-start justify-start pt-0 px-0 pb-0 box-border">
              <div className="whitespace-nowrap flex-1 relative text-lg tracking-[-0.01em] font-medium font-subhead text-dark-navy text-left shrink-0 mt-1 z-[1]">
                {offerData.master ? "Directions" : "Hours & Directions"}
              </div>
              <div className={`transform transition-transform duration-200 ${isHoursDropdownOpen ? 'rotate-180' : ''}`}>
                <ChevronDown className="stroke-zinc-600 w-8 h-8" />
              </div>
            </div>
            <div 
              className={`overflow-hidden transition-all duration-200 ${isHoursDropdownOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <div className="font-subhead w-full pb-4 text-zinc-900">
                {openingHours ? (
                  <>
                    {!offerData.master && formatOpeningHours(openingHours).map((line, index) => (
                      <p key={index} className="m-0">{line}</p>
                    ))}
                    {offerData.locations?.[0]?.address && (
                      <p className="mt-4">
                        <a 
                          href="#" 
                          onClick={(e) => handleAddressClick(e, offerData.locations ? offerData.locations[0].address : "No address available")}
                          className="text-blue-600 hover:underline"
                        >
                          {offerData.locations[0].address}
                        </a>
                      </p>
                    )}
                  </>
                ) : (
                  'Loading opening hours...'
                )}
                {!offerData.master && <p className="m-0 font-bold">Offer valid until: {formatDate(offerData.recurrencePattern?.endDate)}</p>}
              </div>
            </div>

            <div className="self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-gray-200" />
            <div onClick={toggleContactDropdown} className="hidden cursor-pointer w-full justify-between flex flex-row items-start justify-start pt-0 px-0 pb-0 box-border">
              <div className="whitespace-nowrap flex-1 relative text-lg tracking-[-0.01em] font-medium font-subhead text-dark-navy text-left shrink-0 mt-1 z-[1]">
                Contact info
              </div>
              <div className={`transform transition-transform duration-200 ${isContactDropdownOpen ? 'rotate-180' : ''}`}>
                <ChevronDown className="stroke-zinc-600 w-8 h-8" />
              </div>
            </div>
            <div 
              className={`overflow-hidden transition-all duration-200 ${isContactDropdownOpen ? 'max-h-[100px] opacity-100' : 'max-h-0 opacity-0'}`}
            >
                {"Stripe Profile Suppot Email"}<br/>
                {"Stripe Profile Suppot Phone"}
            </div>
            <div 
              className={`overflow-hidden transition-all duration-200 ${isHoursDropdownOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}
            >
            </div>
            <div className="hidden self-stretch -mt-[10px] h-px relative box-border z-[1] border-t-[0.1px] border-solid border-gray-200" />
            </div>
              </div>
              {!isOfferActive() && offerData.price && offerData.price> 0 && <div className="w-full border border-1 border-solid border-red-500 text-center py-3 -mb-4 rounded-lg text-red-800">{`Available starting ${formatDate(offerData.recurrencePattern?.endDate)}`}</div>}
              <div className="flex flex-row items-center w-full justify-center gap-[29px] pt-5 mq450:flex-wrap">
                <button type="button" onClick={togglePopup} className="flex justify-center flex-1 relative cursor-pointer [border:none] py-0 pr-[23px] pl-6 bg-[transparent] flex flex-row items-center justify-start relative whitespace-nowrap z-[1]">
                    <div className="w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-main-teal" />
                    <p className="relative text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-white text-center z-[1">
                      + Add to Itinerary
                    </p>
                </button>
                {isPopupOpen && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative bg-white p-8 rounded-lg max-w-md w-full font-subhead items-center justify-center">
                        <X className="cursor-pointer absolute top-3 right-3 w-7 h-7 stroke-zinc-700 font-light" onClick={togglePopup} />
                        <h2 className="text-19xl font-light mb-10 text-center justify-center">Select an Itinerary</h2>
                        <ul>
                        {itineraries.map((itinerary, index) => (
                          <li key={itinerary.id} className="mb-2 list-none cursor-pointer">
                            <input 
                              className="cursor-pointer" 
                              type="checkbox" 
                              id={`itinerary${index}`} 
                              name={`itinerary${index}`}
                              checked={selectedItineraries.includes(itinerary.id)}
                              onChange={(e) => handleItinerarySelection(e, itinerary.id)}
                            />
                            <label htmlFor={`itinerary${index}`} className="ml-2 font-semibold">{itinerary.name}</label>
                          </li>
                        ))}
                      </ul>
                      <div className="flex items-center justify-center flex-col">
                        <div className="bg-zinc-400 w-80 h-[1px] my-8" />
                        <button 
                          onClick={handleAddToItineraries} 
                          className={`cursor-pointer mt-0 px-auto w-64 font-subhead font-semibold my-3 py-2 rounded ${
                            selectedItineraries.length !== initialSelectedItineraries.length 
                              ? 'bg-main-teal text-white hover:bg-blue-800' 
                              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          }`}
                          disabled={selectedItineraries.length === initialSelectedItineraries.length}
                        >
                          Add to Itinerary
                        </button>
                        <button onClick={onNewItineraryClick} className="cursor-pointer text-main-teal mt-0 px-auto w-64 font-subhead font-semibold bg-white border border-solid border-main-teal py-2 rounded">
                          Go to itineraries
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {isConfirmationPopupOpen && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative bg-white pt-8 pb-8 rounded-lg w-full max-w-[min(500px,65vw)] max-h-[80vh] overflow-y-auto font-subhead px-5">
                      <X className="cursor-pointer absolute top-3 right-3 w-7 h-7 stroke-zinc-700 font-light" onClick={toggleConfirmationPopup} />
                      <h2 className="text-11xl text-black font-semibold mb-6 text-center">Success</h2>
                      <p className="text-xl text-zinc-800 font-base mb-6 text-center"><p className="text-19xl text-blue-800 font-semibold text-center m-0">{offerData.name}</p>was added to your Itinerary!</p>
                      <div className="flex flex-col items-center">
                        <button onClick={onViewItineraryClick} className="w-full my-3 cursor-pointer hover:bg-blue-800 font-semibold mb-6 bg-main-teal text-white text-xl py-4 px-6 max-w-[80%] cursor-pointer rounded-lg">
                          View Your Itinerary
                        </button>
                        <div className="bg-zinc-400 w-full h-[1px] mb-6" />
                        <p className="text-[15px] text-blue-800 font-base mb-1 mt-0 text-center">Other popular Destinations Near</p>
                        <p className="text-base text-blue-800 font-bold mb-2 mt-0 text-center">{offerData.name}</p>
                      </div>
                      {/* @Recommendation Service */}
                      <div className="flex flex-row gap-0 mt-2 gap-4 items-center justify-center w-full bg-white-100">
                        <div onClick={() => navigate("/offer/")} className="flex flex-col gap-1 items-center justify-center text-center">
                          <img src="apple-picking@2x.png" className="w-[min(20vw,140px)] rounded-sm" crossOrigin="anonymous" />
                          <p className="text-xs text-zinc-700 font-subhead  mt-1">{"title"}</p>
                        </div>
                        <div onClick={() => navigate("/offer/")} className="flex flex-col gap-1 items-center justify-center text-center">
                          <img src="apple-picking@2x.png" className="w-[min(20vw,140px)] rounded-sm" crossOrigin="anonymous" />
                          <p className="text-xs text-zinc-700 font-subhead  mt-1">{"title"}</p>
                        </div>
                        <div onClick={() => navigate("/offer/")} className="flex flex-col gap-1 items-center justify-center text-center">
                          <img src="apple-picking@2x.png" className="w-[min(20vw,140px)] rounded-sm" crossOrigin="anonymous" />
                          <p className="text-xs text-zinc-700 font-subhead  mt-1">{"title"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(offerData.price ? offerData.price : 0) > 0 
                  && isOfferActive()
                  && (
                  <button 
                    onClick={handleBookNow} 
                    disabled={isPreparing}
                    type="button" 
                    className={`flex-1 relative cursor-pointer [border:none] py-3.5 pr-[7px] pl-2 bg-[transparent] flex flex-row items-center justify-center whitespace-nowrap z-[1] ${isPreparing ? 'opacity-50' : ''}`}
                  >
                    <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-white box-border border-[1px] border-solid border-main-teal" />
                    <b className="relative text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-main-teal text-center z-[1]">
                      {isPreparing ? "Preparing checkout..." : "Book Now"}
                    </b>
                  </button>
                )}
              </div>
              <button className="hidden cursor-pointer [border:none] py-[11px] pr-[23px] pl-6 bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap z-[1] w-full">
                <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-black" />
                  <b className="relative text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-white text-center z-[1]">
                    Instant Payment
                  </b>
              </button>
            </div>
          </div>
          <div className="w-[1444px] flex flex-col items-start justify-start gap-[4px] max-w-full ">
            <div className="self-stretch relative text-xl tracking-[-0.01em] font-semibold font-subhead text-main-teal text-left z-[1] mq450:text-base">
              <p className="m-0">Main Description:</p>
            </div>
            <div className="self-stretch h-auto relative text-base leading-[28px] font-subhead text-copy text-left inline-block shrink-0 z-[1]">
              {offerData.description}
            </div>
          </div>
          <div className="w-[1444px] flex flex-row flex-wrap items-start justify-start gap-[30.5px] max-w-full mq750:gap-[15px] whitespace-pre-wrap">
          </div>
          <div className="mt-10 w-full max-w-[1444px] max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 font-subhead">
              <section className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <AdditionalInfoSection
                  title="Know Before You Go:"
                  description={offerData.additionalInfo || ""}
                />
              </section>
              <section className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <HighlightSection highlights={offerData.highlights || []} />
              </section>
              <section className="flex flex-col ml-5 h-[210px] w-[33%] max-md:ml-0 max-md:w-full">
              <Map 
                centerLat={offersRounder[0].latitude}
                centerLng={offersRounder[0].longitude}
                setHeight="250px" 
                setZoom={10}
                offers={offersRounder} 
              />
              </section>
            </div>
          </div>
        </div>
      </section>
      <section className="self-stretch bg-white flex flex-row items-start justify-start relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <div className="flex-1 flex flex-col items-end justify-start pt-0 px-0 pb-[67px] box-border gap-[26px] max-w-full text-left text-mini-2 mq450:pb-[29px] mq450:box-border mq1050:pb-11 mq1050:box-border">
          <div className="self-stretch flex flex-col items-end justify-start pt-[92px] pb-[44.9px] pr-[45px] pl-[41px] box-border relative gap-[81px] max-w-full z-[1] text-center text-29xl mq750:gap-[40px_81px] mq750:pt-[60px] mq750:pr-[22px] mq750:pb-[29px] mq750:box-border mq450:gap-[20px_81px]">
            <div className="w-full h-full !m-[0] absolute top-[0px] left-[0px] flex flex-row items-start justify-center max-w-full">
              <h1 className="!m-[0] ml-[50px] absolute top-[70px] text-inherit tracking-[-0.01em] leading-[72px] font-semibold font-inherit inline-block z-[3] _mq1050:text-[30px] _mq1050:leading-[40px] _mq750:text-[25px] _mq750:leading-[35px] _mq450:text-[24px] _mq450:leading-[30px]">
                Similar Activities
              </h1>
            </div>

            <div className="pt-0 self-stretch flex flex-col items-center justify-start gap-[19px] max-w-full text-left text-base text-copy">
              <Caroussel
                activities={similarOffers.map((offer) => (
                  <Rounder
                    key={offer.id}
                    id={offer.id}
                    images={offer.displayImageReferences}
                    name={offer.name}
                    categories={offer.categoryIds}
                    price={offer.price}
                    longitude={offer.locations[0]?.longitude || 0}
                    latitude={offer.locations[0]?.latitude || 0}
                    promotionType={offer.promotionType}
                    percentageDiscount={offer.percentageDiscount}
                    buy_x={offer.minimumPurchaseAmount}
                    get_y={offer.discountValue}
                  />
                ))}
              />
            </div>
          </div>
        </div>
      </section>
      <UserFooter
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </form>













    { /* Mobile Page (<780px) */}
    <form className="mq780:hidden m-0 bg-white w-full overflow-hidden flex flex-col items-center justify-center px-0 pb-0 box-border gap-[5px] leading-[normal] tracking-[normal] pt-[73px]">
    <Header />
    <section className="box-border pb-8 w-full flex flex-col items-start justify-start pt-0 gap-[9px]">
      <div className="relative">
        <ImageSkeleton className={`w-full h-[70vw] ${coverImageLoaded ? 'hidden' : ''}`} />
        {offerData.displayImageReferences && (
          <ImageSliderMobile 
            images={offerData.displayImageReferences.map(url => ({ url }))} 
            onImageLoad={() => setCoverImageLoaded(true)}
            className={`transition-opacity duration-300 ${coverImageLoaded ? 'opacity-100' : 'opacity-0'}`}
          />
        )}
      </div>
    <div className="font-subhead w-full px-4 box-border">
      <h1 className="m-0 mt-4 text-black text-6xl">
        {offerData.name}
      </h1>

      {/* No Promotion */}
      {(!promotionData?.offerIds || promotionData.offerIds.length === 0) && offerData.price !== 0 && (
        <div className="flex flex-row items-center justify-start gap-[10px] pb-1">
          <p className="m-0 font-subhead text-6xl pr-1 text-main-teal tracking-[-0.01em] inline-block">
            ${displayPrice(offerData.price)}
          </p>
        </div>
      )}

      {/* Percentage Discount */}
      {offerData.price != null && 
      typeof offerData.price === 'number' && 
      offerData.price !== 0 && 
      promotionData && 
      promotionData?.offerIds?.length !== 0 && 
      promotionData?.discountValue && 
      promotionData?.type === "PERCENTAGE_DISCOUNT" && (
        <div className="flex flex-row items-center justify-start gap-[10px] pb-1">
          <p className="m-0 font-subhead text-6xl pr-1 text-navy-900 [text-decoration:line-through] tracking-[-0.01em] inline-block">
            ${displayPrice(offerData.price)}
          </p>
          <h1 className="m-0 font-subhead text-6xl pr-2 text-green tracking-[-0.01em] inline-block">
            ${displayPrice(offerData.price * (1 - (promotionData?.discountValue ?? 0) * 0.01))}
          </h1>
        </div>
      )}

      {/* Buy X get Y Discount */}
      {promotionData && promotionData?.offerIds?.length !== 0 && promotionData?.type === "BUY_X_GET_Y_FREE" && promotionData?.minimumPurchaseAmount && promotionData?.discountValue && (
        <>
          <div className="flex flex-row items-center justify-start gap-[10px] pb-1">
            <p className="m-0 font-subhead font-bold text-6xl pr-1 text-green tracking-[-0.01em] inline-block">
              ${displayPrice(offerData.price)}
            </p>
          <div className="flex flex-row gap-2 mt-1 text-black font-subhead">
            <p className="text-2xl m-0 mr-[2px] text-black">Buy</p>
            <p className="text-4xl m-0 mr-[2px] text-main-teal font-semibold">{promotionData.minimumPurchaseAmount}</p>
            <p className="text-2xl m-0 mr-[2px] text-black">get</p>
            <p className="text-4xl m-0 text-main-teal font-semibold">{promotionData.discountValue}</p>
          </div>
          </div>
        </>
      )}
      {offerData.merchantName && <a href={`/business/${offerData.merchantId}`} className="text-lg mb-4 text-zinc-800 cursor-pointer font-subhead [text-decoration:underline]">View Business</a>}
      {offerData.price !== 0 &&
      <div className="flex items-center w-[120px] my-4">
        <label htmlFor="quantity" className="mr-2 mb-1">Quantity:</label>
        <input 
          id="quantity"
          name="quantity"
          type="number"
          value={quantity}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantity(parseInt(e.target.value))}
          className="w-full rounded-lg py-1 pl-1 text-center pr-2"
        />
      </div>
      }
      {!isOfferActive() && offerData.price && offerData.price> 0 && <div className="border border-1 border-solid border-red-500 mb-2 text-center p-2 rounded-lg text-red-800">{`Available starting ${formatDate(offerData.recurrencePattern?.endDate)}`}</div>}
      <div className={`box-border flex flex-row items-center justify-center gap-[10px] pb-2 w-full ${offerData.price == 0 ? "mt-3" : ""}`}>
        <button type="button" onClick={togglePopup} className="w-full relative cursor-pointer [border:none] py-[11px] px-4 bg-[transparent] flex items-center justify-center whitespace-nowrap z-[1]">
          <div className="h-full w-full absolute top-0 right-0 bottom-0 left-0 rounded-md bg-white box-border border-[1px] border-solid border-main-teal" />
          <b className="relative text-mini tracking-[-0.01em] leading-[19px] font-subhead text-main-teal text-center z-[1]">
            + Add to Itinerary
          </b>
        </button>
        {isPopupOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative bg-white p-8 rounded-lg w-full max-w-[80vw] max-h-[80vh] overflow-y-auto font-subhead px-5">
              <X className="cursor-pointer absolute top-3 right-3 w-7 h-7 stroke-zinc-700 font-light" onClick={togglePopup} />
              <h2 className="text-5xl font-light mb-6 text-center">Select an Itinerary</h2>
              <ul className="text-base mb-6 text-start text-zinc-900">
                {itineraries.map((itinerary, index) => (
                  <li key={itinerary.id} className="mb-2 flex items-start">
                    <input 
                      type="checkbox" 
                      id={`itinerary${index}`} 
                      name={`itinerary${index}`} 
                      className="mt-1 flex-shrink-0"
                      checked={selectedItineraries.includes(itinerary.id)}
                      onChange={(e) => handleItinerarySelection(e, itinerary.id)}
                    />
                    <label htmlFor={`itinerary${index}`} className="ml-2 font-semibold flex-grow">{itinerary.name}</label>
                  </li>
                ))}
              </ul>
              <div className="flex flex-col items-center">
                <div className="bg-zinc-400 w-full h-[1px] mb-6" />
                <button 
                  onClick={handleAddToItineraries}
                  className={`w-full max-w-[192px] cursor-pointer font-semibold mb-3 py-3 rounded ${
                    selectedItineraries.length !== initialSelectedItineraries.length 
                      ? 'bg-main-teal text-white hover:bg-blue-800' 
                      : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  }`}
                  disabled={selectedItineraries.length === initialSelectedItineraries.length}
                >
                  Add to Itinerary
                </button>
                <button onClick={onNewItineraryClick} className="w-full max-w-[192px] cursor-pointer text-main-teal font-bold bg-white border border-solid border-main-teal py-3 rounded">
                  Go to itineraries
                </button>
              </div>
            </div>
          </div>
        )}
        {(offerData.price ? offerData.price : 0) > 0
          && isOfferActive()
          && (
            <button 
              onClick={handleBookNow} 
              disabled={isPreparing}
              type="button" 
              className={`w-full relative cursor-pointer [border:none] py-[11px] px-4 bg-[transparent] flex items-center justify-center whitespace-nowrap z-[1] ${isPreparing ? 'opacity-50' : ''}`}
            >
              <div className="h-full w-full absolute top-0 right-0 bottom-0 left-0 rounded-md bg-main-teal" />
              <b className="relative text-mini tracking-[-0.01em] leading-[19px] font-subhead text-white text-center z-[1]">
                {isPreparing ? "Preparing checkout..." : "Book Now"}
              </b>
            </button>
          )}
      </div>
      <button className="hidden cursor-pointer [border:none] py-[11px] pr-[23px] pl-6 bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap z-[1] w-full">
        <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-black" />
        <b className="relative text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-white text-center z-[1]">
          Instant Payment
        </b>
      </button>
        </div>
        <div className="px-4 py-5 self-stretch flex flex-col items-start justify-start gap-[10px]">
            <div onClick={toggleDescriptionOpen} className="w-full justify-between flex flex-row items-start justify-start pt-0 px-0 pb-0 box-border">
              <div className="whitespace-nowrap flex-1 relative text-lg tracking-[-0.01em] font-medium font-subhead text-dark-navy text-left shrink-0 mt-1 z-[1]">
                Description
              </div>
              <div className={`transform transition-transform duration-200 ${isDescriptionOpen ? 'rotate-180' : ''}`}>
                <ChevronDown className="stroke-zinc-600 w-8 h-8" />
              </div>
            </div>
            <div 
              className={`overflow-hidden transition-all duration-200 ${isDescriptionOpen ? 'h-auto opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <div className="font-subhead w-full pb-4 text-zinc-900 whitespace-pre-wrap">
                {offerData.description}
              </div>
            </div>

            <div className="self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-gray-200" />
            <div onClick={toggleHoursDropdown} className="cursor-pointer w-full justify-between flex flex-row items-start justify-start pt-0 px-0 pb-0 box-border">
              <div className="whitespace-nowrap flex-1 relative text-lg tracking-[-0.01em] font-medium font-subhead text-dark-navy text-left shrink-0 mt-1 z-[1]">
                {offerData.master ? "Directions" : "Hours & Directions"}
              </div>
              <div className={`transform transition-transform duration-200 ${isHoursDropdownOpen ? 'rotate-180' : ''}`}>
                <ChevronDown className="stroke-zinc-600 w-8 h-8" />
              </div>
            </div>
            <div 
              className={`overflow-hidden transition-all duration-200 ${isHoursDropdownOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <div className="font-subhead w-full pb-4 text-zinc-900">
                {openingHours ? (
                  <>
                    {!offerData.master && formatOpeningHours(openingHours).map((line, index) => (
                      <p key={index} className="m-0">{line}</p>
                    ))}
                    {offerData.locations?.[0]?.address && (
                      <p className="mt-4">
                        <a 
                          href="#" 
                          onClick={(e) => handleAddressClick(e, offerData.locations ? offerData.locations[0].address : "No address available")}
                          className="text-blue-600 hover:underline"
                        >
                          {offerData.locations[0].address}
                        </a>
                      </p>
                    )}
                  </>
                ) : (
                  'Loading opening hours...'
                )}
                {!offerData.master && <p className="m-0 font-bold">Offer valid until: {formatDate(offerData.recurrencePattern?.endDate)}</p>}
              </div>
            </div>
            <div className="self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-gray-200" />
            <div onClick={toggleContactDropdown} className="hidden cursor-pointer w-full justify-between flex flex-row items-start justify-start pt-0 px-0 pb-0 box-border">
              <div className="whitespace-nowrap flex-1 relative text-lg tracking-[-0.01em] font-medium font-subhead text-dark-navy text-left shrink-0 mt-1 z-[1]">
                Contact info
              </div>
              <div className={`transform transition-transform duration-200 ${isContactDropdownOpen ? 'rotate-180' : ''}`}>
                <ChevronDown className="stroke-zinc-600 w-8 h-8" />
              </div>
            </div>
            <div 
              className={`overflow-hidden transition-all duration-200 ${isContactDropdownOpen ? 'max-h-[100px] opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <div className="font-subhead w-full pb-4 text-zinc-900">
                {"Stripe Profile Suppot Email"}<br/>
                {"Stripe Profile Suppot Phone"}
              </div>
            </div>

            <div className="hidden self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-gray-200" />
            <div onClick={toggleKnowBeforeYouGoOpen} className="w-full justify-between flex flex-row items-start justify-start pt-0 px-0 pb-0 box-border">
              <div className="whitespace-nowrap flex-1 relative text-lg tracking-[-0.01em] font-medium font-subhead text-dark-navy text-left shrink-0 mt-1 z-[1]">
                Know before you go
              </div>
              <div className={`transform transition-transform duration-200 ${isKnowBeforeYouGoOpen ? 'rotate-180' : ''}`}>
                <ChevronDown className="stroke-zinc-600 w-8 h-8" />
              </div>
            </div>
            <div 
              className={`overflow-hidden transition-all duration-200 ${isKnowBeforeYouGoOpen ? 'h-auto opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <div className="font-subhead h-auto w-full pb-4 text-zinc-900 whitespace-pre-wrap">
                {offerData.additionalInfo}
              </div>
            </div>
            <div className="font-subhead mt-4">
              <HighlightSection highlights={offerData.highlights || []} />
            </div>
        </div>
      </section>
      <section className="pb-10 self-stretch bg-white flex flex-col items-center justify-center relative max-w-full text-center text-29xl text-main-teal font-subhead">
        <p className="font-bold font-subhead m-0 py-4 text-10xl">Similar Activities</p>
        <div className="w-full pl-6 pb-32 flex overflow-x-auto overflow-y-hidden snap-x snap-mandatory scrollbar-hide">
          <div className="flex flex-nowrap gap-4 items-start justify-start">
            {similarOffers.map((offer) => (
              <MobileRounder
                key={offer.id}
                id={offer.id}
                images={offer.displayImageReferences}
                name={offer.name}
                categories={offer.categoryIds}
                price={offer.price}
                longitude={offer.locations[0]?.longitude || 0}
                latitude={offer.locations[0]?.latitude || 0}
                promotionType={offer.promotionType}
                percentageDiscount={offer.percentageDiscount}
                buy_x={offer.minimumPurchaseAmount}
                get_y={offer.discountValue}
              />
            ))}
          </div>
        </div>
      </section>
      <UserFooter
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </form>

  </>
  );
};

export default H51DetailsPage;