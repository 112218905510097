import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { verifyEmailCode } from '../../../api/EmailVerification';
import axios from 'axios';

function MerchantPage3() {
  const navigate = useNavigate();
  const [code, setCode] = useState<string[]>(new Array(6).fill(''));
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('jwtToken');
    // // console.log("JWT in localStorage on MerchantPage3 mount:", storedToken ? "Present" : "Missing");
    if (storedToken) {
      // // console.log("Stored token:", storedToken);
    }
  }, []);

  useEffect(() => {
    if (code.every(digit => digit !== '')) {
      handleVerification();
    }
  }, [code]);

  const onBackClick = () => {
    navigate('/merchant-signup-2');
  }

  const onNextClick = () => {
    if (code.some(digit => digit === '')) {
      setError("Please enter a 6-digit code");
    } else {
      handleVerification();
    }
  }

  const onResendCodeClick = () => {
    // // console.log('resend code');
  }

  const handleVerification = async () => {
    // // console.log("Starting verification process");
    const verificationCode = code.join('');
    
    try {
      const jwtToken = localStorage.getItem('jwtToken');
      if (!jwtToken) {
        console.error("JWT token not found in localStorage");
        setError("Authentication token not found. Please sign up again.");
        return;
      }
  
      // // console.log("Calling verifyEmailCode");
      const result = await verifyEmailCode(verificationCode, jwtToken);
      // // console.log("Verification result:", result);
      navigate("/merchant-hub-home");
    } catch (error) {
      console.error("Verification error:", error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data || error.message;
        setError(`Verification failed: ${errorMessage}`);
      } else {
        setError("An unknown error occurred during verification");
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) return; // Only allow numbers

    setError(null); // Clear any existing error message

    setCode(prevCode => {
      const newCode = [...prevCode];
      newCode[index] = value.slice(-1);
      return newCode;
    });

    // Move to the next input field if a number is entered
    if (value && index < 5) {
      const nextInput = document.getElementById(`code-input-${index + 1}`) as HTMLInputElement;
      if (nextInput) nextInput.focus();
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError(null); // Clear any existing error message

    const pastedData = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 6);
    
    setCode(prevCode => {
      const newCode = [...prevCode];
      for (let i = 0; i < pastedData.length; i++) {
        newCode[i] = pastedData[i];
      }
      return newCode;
    });

    // Focus on the next empty input or the last input if all are filled
    const nextEmptyIndex = pastedData.length < 6 ? pastedData.length : 5;
    const nextInput = document.getElementById(`code-input-${nextEmptyIndex}`) as HTMLInputElement;
    if (nextInput) nextInput.focus();
  }

  return (
    <div className="flex flex-col items-center bg-white">
      <main className="justify-center text-center items-center">
        <h2 className="mt-10 text-11xl px-3 text-center leading-[50.1px] text-zinc-800 mq480:text-19xl">
          Verify your account
        </h2>
        <p className="px-5 leading-6 max-w-[600px]">Please enter the verification code sent to your Email.</p>
        <div className="flex gap-8 justify-center text-center items-center px-4 mt-14 max-md:mt-10 580px:gap-4 580px:px-2">
          {code.map((digit, index) => (
            <input
              key={index}
              id={`code-input-${index}`}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleInputChange(e, index)}
              onPaste={handlePaste}
              className="text-19xl text-center shrink-0 p-2 w-8 rounded-lg border border-solid border-zinc-700 h-[60px] 580px:w-6"
              aria-label={`Verification code digit ${index + 1}`}
            />
          ))}
        </div>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        <div className="px-6 flex gap-5 justify-between mt-20 max-w-full text-base tracking-tight text-center whitespace-nowrap max-md:flex-wrap max-md:mt-10">
          <button onClick={onBackClick} type="button" className="cursor-pointer justify-center bg-transparent px-7 py-2 font-bold text-blue-900 rounded-lg border border-blue-900 border-solid border-2 font-bold max-md:px-5">
            Back
          </button>
          <button onClick={onNextClick} type="button" className="cursor-pointer font-medium w-[90px] justify-center p-2 text-white bg-blue-900 rounded-lg max-md:px-5">
            Next
          </button>
        </div>
        <div className="flex justify-center items-center gap-2 mt-11 text-base leading-7 text-center max-md:mt-10">
          <p className="text-zinc-800">Didn't get code?</p>
          <button type="button" onClick={onResendCodeClick} className="cursor-pointer font-semibold bg-transparent text-blue-900 underline">
            Click here to resend code
          </button>
        </div>
      </main>
    </div>
  );
}

export default MerchantPage3;