import * as React from "react";
import { ImageSlider } from "../General/ImageSlider";

interface ReviewPostProps {
    title?: string;
    price?: number;
    address?: string;
    description?: string;
    knowbefore?: string;
    images?: string[];
    promotion?: {
        type: 'PERCENTAGE_DISCOUNT' | 'BUY_X_GET_Y_FREE';
        discountValue: number;
        minimumPurchaseAmount?: number;
    };
}

const ReviewPost: React.FC<ReviewPostProps> = ({
    title, 
    price,
    address,
    description,
    knowbefore,
    images = [], // Provide a default empty array
    promotion
}) => { 
    // Map the images to the format expected by ImageSlider
    const formattedImages = images.map(img => ({ url: img }));

    const displayPrice = (price: number | undefined) => {
        if (!price) return;
        else if (Math.round(price) === price) return price.toFixed(2);
        else if (Math.round(price * 10) / 10 === price) return price.toFixed(2);
        else return price.toFixed(2);
    };

    const renderPrice = () => {
        if (!price || price === 0) {
            return <p className="font-subhead font-semibold text-blue-400 text-5xl">FREE</p>;
        }
        else if (!promotion?.discountValue) {
            return <p className="font-subhead font-semibold text-blue-400 text-5xl">${displayPrice(price)}</p>;
        }

        if (promotion.type === 'PERCENTAGE_DISCOUNT') {
            const discountedPrice = price * (1 - promotion.discountValue * 0.01);
            return (
                <div className="flex flex-row items-center gap-4">
                    <p className="font-subhead font-semibold text-gray-400 text-3xl line-through">${displayPrice(price)}</p>
                    <p className="font-subhead font-semibold text-green-600 text-green text-5xl">${displayPrice(discountedPrice)}</p>
                </div>
            );
        }

        if (promotion.type === 'BUY_X_GET_Y_FREE') {
            return (
                <div className="flex gap-3 flex-row">
                    <p className="font-subhead font-semibold text-green text-5xl">${displayPrice(price)}</p>
                    <p className="font-subhead font-semibold text-blue-600 text-3xl mt-[26px]">
                        Buy {promotion.minimumPurchaseAmount}, get {promotion.discountValue} free
                    </p>
                </div>
            );
        }
    };

    return (
        <div className="w-inherit flex flex-col">
            <ImageSlider images={formattedImages} />
            <p className="font-subhead font-bold pb-0 mb-1 text-19xl">{title}</p>
            <p className="font-subhead m-0 -pb-2 text-xl">{address}</p>
            {renderPrice()}
            <p className="font-subhead text-blue-400 m-0 mt-8 text-xl">Description:</p>
            <p className="font-subhead text-xl whitespace-prewrap">{description}</p>
            <p className="font-subhead text-blue-400 m-0 mt-8 text-xl">Before you go:</p>
            <p className="font-subhead text-xl whitespace-prewrap">{knowbefore}</p>
        </div>
    );
};

export default ReviewPost;