import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface Category {
  id: string;
  name: string;
}

interface MerchantProfile {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  status: string;
  userType: string;
  preferences: {
    wantsPromotionalEmails: boolean;
  };
  createdAt: string;
  businessName: string;
  highlightIds: string[];
  categoryIds: string[];
  description: string;
  displayImageReferences: string[];
  profilePictureReference: string;
  address: {
    line1: string,
    line2: string,
    city: string,
    statg: string,
    postalCode: string,
    country: string
  };
  openingHours: {
    schedule: {
      [key: string]: {
        openTime: {
          hour: number;
          minute: number;
        };
        closeTime: {
          hour: number;
          minute: number;
        };
        closed: boolean;
      };
    };
  };
  offerIds: string[];
  promotionIds: string[];
}

const CompanyCard: React.FC = () => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [merchantProfile, setMerchantProfile] = useState<MerchantProfile | null>(null);
  const navigate = useNavigate();

  const handleStripeAccount = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/merchants/account-link`,
        { type: "ONBOARDING" },
        {
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.status === 200 && response.data) {
        const stripeUrl = response.data;
        
        // Redirect to the Stripe URL
        window.open(stripeUrl, '_blank');
      } else {
        console.error('Invalid response from server');
        // Optionally, show an error message to the user
      }
    } catch (error) {
      console.error('Error fetching Stripe account link:', error);
      // Optionally, show an error message to the user
    }
  };

  const fetchMerchantProfile = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/v1/users/merchant/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      setMerchantProfile(response.data);
      
      // Handle image references
      let images = [];
      if (response.data.displayImageReferences && response.data.displayImageReferences.length > 0) {
        images = response.data.displayImageReferences;
      } else if (response.data.profilePictureReference) {
        images = [response.data.profilePictureReference];
      }
    } catch (error) {
      console.error('Error fetching merchant profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

    useEffect(() => {
      fetchMerchantProfile();
    }, []);

    return (
    <div className="h-auto hidden mq780:flex flex-col px-6 py-8 mx-5 min-w-[20] w-[20vw] bg-white rounded-xl border border-solid border-zinc-300 mt-5 max-w-[450px]">
        <div className="flex gap-5 justify-between font-semibold">
            <div className="flex flex-col my-auto">
            <div className="text-2xl tracking-tight leading-9 text-zinc-800">
                {merchantProfile?.businessName}
            </div>
            <div onClick={handleStripeAccount} className="cursor-pointer mt-6 text-base leading-4 text-blue-400 underline">
              View Stripe Profile
            </div>
            </div>
            <img
            loading="lazy"
            src={merchantProfile?.profilePictureReference}
            className="rounded shrink-0 w-20 h-20  aspect-square"
            />
        </div>
        <div className="mt-14 text-base leading-7 text-zinc-800 flex flex-rows">
          {merchantProfile?.description}
        </div>
        <div onClick={() => navigate("/merchant-business-info")} className="cursor-pointer mt-3 text-base leading-7 text-blue-400">
            Add/Edit Business Info
        </div>
    </div>
  );
};

export default CompanyCard;
