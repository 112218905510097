import { FunctionComponent, useCallback } from "react";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import MerchantPage1 from "../../components/Merchant/MerchantSignup/MerchantPage1";

const TermsOfService: FunctionComponent = () => {
  const onItemClick = useCallback(() => {
    // Please sync "H4.1 Details Page - K1 Speed" to the project
  }, []);

  const onQuickAddContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='background']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onArrowFrontClick = () => {
    //Add functionality
  };

  const onArrowBackClick = () => {
    //Add functionality
  };

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col justify-center  px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
      <MerchantHubHeader />
      <h1 className="text-11xl font-subhead font-semibold pt-20">Terms of Service</h1>
      <div className="items-center justify-center flex pb-20">
      <main className="relative mx-10 px-6 font-subhead mt-20 max-w-[650px] text-start items-start justify-start">
      <strong>Effective Date: Jul 5th 2024</strong><br />
      <br />
      <strong className="text-start items-start flex justify-start m-0 mt-2 ">Introduction</strong><br />
Welcome to DoYouWeekend! These Terms of Service govern your use of our website and services. By accessing or using our site, you agree to comply with and be bound by these terms. If you do not agree to these terms, please do not use our site.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">User Responsibilities</strong><br />
As a user of our site, you agree to provide accurate and complete information about yourself and to update this information as necessary. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Prohibited Activities</strong><br />
You agree not to use the site for any unlawful or prohibited purpose. You agree not to:

<ul>
<li>Engage in any activity that interferes with or disrupts the site or the servers and networks that host the site.</li>
<li>Use any automated means to access the site for any purpose without our express written permission.</li>
<li>Attempt to gain unauthorized access to any portion or feature of the site, or any other systems or networks connected to the site.</li>
<li>Engage in any fraudulent activity or impersonate any person or entity.</li>
</ul>

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Intellectual Property</strong><br />
All content on this site, including but not limited to text, graphics, logos, and images, is the property of DoYouWeekend or its content suppliers and is protected by copyright and other intellectual property laws. You agree not to reproduce, distribute, or create derivative works based on the content without our express written permission.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Termination</strong><br />
We may terminate or suspend your access to the site without notice if you breach these terms or for any other reason. Upon termination, you must cease all use of the site and any content obtained from the site.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Disclaimers and Limitation of Liability</strong><br />
The site is provided "as is" and "as available" without warranties of any kind, either express or implied. DoYouWeekend does not warrant that the site will be uninterrupted or error-free. To the fullest extent permitted by law, DoYouWeekend disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Governing Law</strong><br />
These terms shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in Florida for the resolution of any disputes arising out of or relating to these terms or the site.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Changes to These Terms</strong><br />
We may update these Terms of Service from time to time. If we make changes, we will notify you by revising the date at the top of this document and, in some cases, we may provide additional notice. We encourage you to review these terms periodically to stay informed about our practices.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Contact Us</strong><br />
If you have questions or comments about these Terms of Service, please contact us at:<br />
<br />
DoYouWeekend<br />
7901 4TH ST N, STE 300<br />
ST. PETERSBURG, FL 33702 <br />
rich@doyouweekend.com<br />
+1 (518) 416-2744<br />

    </main>
    </div>
      <footer className="mt-auto"> {/* This will push the footer to the bottom */}
        <Footer
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>  
    </div>
  );
};

export default TermsOfService;
