import { FunctionComponent, useCallback } from "react";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import MerchantHub from "../../components/Merchant/MerchantSignup/MerchantPage0";

const M01MerchantHub: FunctionComponent = () => {
  const onItemClick = useCallback(() => {
    // Please sync "H4.1 Details Page - K1 Speed" to the project
  }, []);

  const onQuickAddContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='background']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onArrowFrontClick = () => {
    //Add functionality
  };

  const onArrowBackClick = () => {
    //Add functionality
  };

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border tracking-[normal] text-center text-mini text-dark-navy font-subhead">
      <MerchantHubHeader />
      <MerchantHub />
      <footer className="mt-auto"> {/* This will push the footer to the bottom */}
          <Footer
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>  
    </div>
  );
};

export default M01MerchantHub;
