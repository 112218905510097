import { FunctionComponent, useCallback } from "react";
import Footer from "../../components/Merchant/MerchantFooter";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import MerchantLogInForm from "../../components/Merchant/MerchantLogInForm";

const H41LogInForm: FunctionComponent = () => {
    const handleGoogleSignUp = () => {
        // console.log('Google sign up initiated');
        // Implement your Google sign-up logic here
      };
    
      // Function to handle Facebook sign-up
      const handleFacebookSignUp = () => {
        // console.log('Facebook sign up initiated');
        // Implement your Facebook sign-up logic here
      };
    
      // Function to handle regular sign-up
      const handleSignUp = () => {
        // console.log('Regular sign up initiated');
        // Implement your regular sign-up logic here
      };

    const onQuickAddContainerClick = useCallback(() => {
        const anchor = document.querySelector("[data-scroll-to='background']");
        if (anchor) {
        anchor.scrollIntoView({ block: "start", behavior: "smooth" });
        }


  }, []);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
      <MerchantHubHeader />
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ||""}>
        <MerchantLogInForm
          onGoogleSignUp={handleGoogleSignUp}
          onFacebookSignUp={handleFacebookSignUp}
        />
      </GoogleReCaptchaProvider>
      <Footer
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </div>
  );
};

export default H41LogInForm;
