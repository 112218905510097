import React from 'react';
import { ChevronLeft, ChevronRight } from "lucide-react";

type DayProps = {
  day: number;
  isCurrentMonth: boolean;
  isSelected: boolean | null;
  isInRange: boolean;
  onClick: () => void;
  isDisabled: boolean;
};

const Day: React.FC<DayProps> = ({ day, isCurrentMonth, isSelected, isInRange, onClick, isDisabled }) => (
  <div
    onClick={isDisabled || !isCurrentMonth ? undefined : onClick}
    className={`flex items-center justify-center w-8 h-8 ${
      isCurrentMonth ? 'cursor-pointer' : 'cursor-default opacity-30'
    } ${
      isSelected
        ? 'font-bold text-white bg-blue-500'
        : isInRange
        ? 'bg-blue-100'
        : isCurrentMonth
        ? 'bg-white'
        : 'bg-gray-100 text-gray-400'
    } ${isDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
  >
    {day}
  </div>
);

const generateWeeks = (year: number, month: number) => {
  const weeks = [];
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  let day = 1 - firstDayOfMonth;

  for (let i = 0; i < 6; i++) {
    const week = [];
    for (let j = 0; j < 7; j++) {
      week.push(new Date(year, month, day));
      day++;
    }
    weeks.push(week);
    if (day > daysInMonth) break;
  }

  return weeks;
};

type CalendarDateRangeProps = {
  startDate: Date | null;
  endDate: Date | null;
  onDateSelect: (date: Date) => void;
  year: number;
  month: number;
  onMonthChange: (offset: number) => void;
};

const CalendarDateRange: React.FC<CalendarDateRangeProps> = ({
  startDate,
  endDate,
  onDateSelect,
  year,
  month,
  onMonthChange,
}) => {
  const weeks = generateWeeks(year, month);

  const isDateInRange = (date: Date) => {
    if (!startDate || !endDate) return false;
    return date >= startDate && date <= endDate;
  };

  const isDateSelected = (date: Date) => {
    return (startDate && date.getTime() === startDate.getTime()) || (endDate && date.getTime() === endDate.getTime());
  };

  const isDateDisabled = (date: Date) => {
    if (!startDate) return false;
    return date < startDate;
  };

  const handleDateSelect = (date: Date) => {
    if (date.getMonth() === month) {
      onDateSelect(date);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-4">
      <div className="flex justify-between items-center mb-4">
        <ChevronLeft onClick={() => onMonthChange(-1)} className="cursor-pointer text-blue-500" />
        <h2 className="text-lg font-bold text-blue-500">{new Date(year, month).toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
        <ChevronRight onClick={() => onMonthChange(1)} className="cursor-pointer text-blue-500" />
      </div>
      <div className="grid grid-cols-7 gap-1 text-center text-sm font-medium text-gray-500 mb-2">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="text-zinc-500 text-xxs text-center font-semibold">{day}</div>
        ))}
      </div>
      {weeks.map((week, weekIndex) => (
        <div key={weekIndex} className="grid grid-cols-7 gap-1 mt-1">
          {week.map((date, dayIndex) => (
            <Day
              key={dayIndex}
              day={date.getDate()}
              isCurrentMonth={date.getMonth() === month}
              isSelected={isDateSelected(date)}
              isInRange={isDateInRange(date)}
              onClick={() => handleDateSelect(date)}
              isDisabled={isDateDisabled(date)}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default CalendarDateRange;