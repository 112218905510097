import * as React from "react"; 
import MyDeals from "./MyDeals";
import { useNavigate
  
 } from "react-router-dom";
import CompanyCard from "./CompanyCard";
import DynamicCompanyCard from "./DynamicCompanyCard";
type MerchantHubPostsProps = {}; 

const MerchantHubPosts: React.FC<MerchantHubPostsProps> = () => { 

  const navigate = useNavigate();

  return ( 
    <div className="flex items-start flex-col bg-white w-full max-w-full">
      <div className="w-full flex flex-row items-center justify-center">
        <div className="flex flex-col w-full">
          <div className="flex flex-col grow mt-12 w-full">
            <div className="self-center w-full">
              <div className="flex flex-row w-full justify-center items-center mq1050:items-start mq1050:justify-start">
                <div className="flex flex-col w-[90%] mq1050:w-full">
                  <MyDeals selected="View all"/>
                </div>
                <div className="hidden mq1050:flex flex-col items-center justify-center mb-[55vh] mx-5 ml-16 w-full">
                  <CompanyCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ); 
};

export default MerchantHubPosts;