import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

export async function fetchPromotions(offerIds: string[]): Promise<any> {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(`${API_BASE_URL}/v1/promotions/by-offers`, {
      params: { offerIds: offerIds.join(',') },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching promotions:', error);
    return [];
  }
}

export function applyPromotionsToOffers(offers: any[], promotions: any[]): any[] {
  return offers.map(offer => {
    const promotion = promotions.find(promo => promo.offerIds.includes(offer.id));
    if (promotion) {
      return {
        ...offer,
        promotionType: promotion.type,
        percentageDiscount: promotion.type === 'PERCENTAGE_DISCOUNT' ? promotion.discountValue : undefined,
        minimumPurchaseAmount: promotion.minimumPurchaseAmount,
        discountValue: promotion.discountValue,
      };
    }
    return offer;
  });
}