import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/User/Header";
import UserFooter from "../../../components/User/UserFooter";
import { X, ArrowLeft } from "lucide-react";
import { ChevronRight } from "lucide-react";


const Preferences: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {/* Desktop (>=780px) */}
      <div className="hidden mq780:flex flex-col">
        <Header />
        <footer>
          <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>
      </div>

      {/* Mobile (<780px) */}
      <div className="mq780:hidden w-full relative bg-white overflow-hidden flex flex-col justify-between px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead min-h-screen">
        <div className="flex justify-between items-center px-4 mt-3 mb-1">
            <button onClick={handleBackClick} className="text-gray-500 pt-2 bg-white">
                <ArrowLeft className='w-7 h-7 stroke-gray-300' />
            </button>
            <h2 className="text-xl font-semibold">Preferences</h2>
            <ArrowLeft className='w-7 h-7 stroke-white' />
        </div>
        <div className="min-h-[90vh]">
            <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
            <div onClick={() => navigate("/account/account-info")} className="text-xl bg-white py-4">
            <button
                className="flex justify-between items-center bg-white w-full text-left"
            >
                <span className="text-main-teal text-5xl font-semibold pl-4">Account Info</span>
                <ChevronRight className={`cursor-pointer text-main-teal transition-transform pr-1`} size={45} />
            </button>
            </div>

            <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
            <div onClick={() => navigate("/account/payments")} className="text-xl bg-white py-4">
            <button
                className="flex justify-between items-center bg-white w-full text-left"
            >
                <span className="text-main-teal text-5xl font-semibold pl-4">Payments</span>
                <ChevronRight className={`cursor-pointer text-main-teal transition-transform pr-1`} size={45} />
            </button>
            </div>
            
            <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
            <div onClick={() => navigate("/account/notification-settings")} className="text-xl bg-white py-4">
            <button
                className="flex justify-between items-center bg-white w-full text-left"
            >
                <span className="text-main-teal text-5xl font-semibold pl-4">Notification Settings</span>
                <ChevronRight className={`cursor-pointer text-main-teal transition-transform pr-1`} size={45} />
            </button>
            </div>

            <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
            <div onClick={() => navigate("/account/close-account")} className="text-xl bg-white py-4">
            <button
                className="flex justify-between items-center bg-white w-full text-left"
            >
                <span className="text-main-teal text-5xl font-semibold pl-4">Close Account</span>
                <ChevronRight className={`cursor-pointer text-main-teal transition-transform pr-1`} size={45} />
            </button>
            </div>
            <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
        </div>
        <footer>
            <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
            />
        </footer>
      </div>
    </>
  );
};

export default Preferences;