import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/User/Header";
import UserFooter from "../../../components/User/UserFooter";
import { Settings, ArrowLeft } from "lucide-react";
import SideNavUserProfile from "../../../components/User/SideNavUserProfile";
import BookingsItemMobile from "../../../components/Mobile/BookingsItemMobile";
import EditButton from "../../../components/General/EditButton";

interface NotificationProps {
    text: string;
    read: boolean;
    link: string;
  }
  
  const Notification: React.FC<NotificationProps> = ({
    text,
    read,
    link,
  }) => {
    return (
    <>
      <a href={link} className="flex-grow no-underline flex flex-row gap-3 items-center mr-2 px-2 mt-1 bg-white px-4 w-[90%] mq780:w-full bg-white max-h-[10vh]">
        <img src={`${read ? "../notification-icon-read.png" : "../notification-icon-unread.png"}`} className="w-[30px] h-[30px] p-4" crossOrigin="anonymous" />
        <p className={`font-semibold text-lg ${read ? "text-zinc-500" : "text-zinc-900"}`}>{text}</p>
      </a>
      <div className="h-[1px] bg-zinc-100 px-4 mx-4 w-full"/>
    </>
    );
  };

const Notifications: FunctionComponent = () => {
  const navigate = useNavigate();
  const [hasBookings, setHasBookings] = useState(false);  

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {/* Desktop (>=780px) */}
      <div className="hidden mq780:flex flex-col min-h-screen">
        <Header />
        <div className="flex flex-1 bg-gray-100">
          <SideNavUserProfile activeItem="Notifications"/>
          <div className="flex-1 p-8 font-subhead">
            <div className="bg-white rounded-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl text-main-teal font-semibold">Notifications</h2>
                <Settings 
                  onClick={() => navigate("/account/notification-settings")} 
                  className="w-6 h-6 text-gray-500 cursor-pointer" 
                />
              </div>
              <div className="space-y-2">
                <Notification
                  read={true}
                  text="Time to leave a review for your visit at Carlson Carnival."
                  link="/review"
                />
                <Notification
                  read={false}
                  text="Upload a picture to complete your profile."
                  link="/account/profile"
                />
              </div>
            </div>
          </div>
        </div>
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </div>

      {/* Mobile (<780px) */}
      <div className="mq780:hidden w-full relative bg-white overflow-hidden flex flex-col min-h-screen justify-between px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
        <div className="flex justify-between items-center px-4 mt-3 mb-1">
            <button onClick={handleBackClick} className="text-gray-500 pt-2 bg-white">
                <ArrowLeft className='w-7 h-7 stroke-gray-300' />
            </button>
            <h2 className="text-xl font-semibold">Notifications</h2>
            <Settings onClick={() => navigate("/account/notification-settings")} className='w-7 h-7 stroke-gray-300 mq480:pr-2' />
        </div>
        <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
        <div className="flex-1 flex flex-col items-start justify-start text-start mb-10 mb-0 min-h-[90vh]">
            <Notification
                read={false}
                text="Update your Profile picture"
                link="/account/profile"
            />
            <Notification
                read={true}
                text="Confirm your emali"
                link="/account/profile"
            />
        </div>
        <footer>
            <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
            />
        </footer>
      </div>
    </>
  );
};

export default Notifications;