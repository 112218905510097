import { FunctionComponent, useCallback } from "react";
import Header from "../../components/User/Header";
import Footer from "../../components/User/UserFooter";
import { useNavigate } from "react-router-dom";
import QuizComponent3 from "../../components/Quiz/QuizComponent3";

const H73QuizPage: FunctionComponent = () => {

  return (
    <>
    <Header />
    <div className="font-subhead py-16 flex items-center justify-center pt-[100px] mq780:pt-10">
     <QuizComponent3 />
    </div>
    <Footer
    dYWLogoW1="/dyw-logow-1@2x.png"
    propAlignSelf="stretch"
    propPosition="unset"
    propTop="unset"
    propLeft="unset"
    propWidth="unset"
    />
    </>
  );
};

export default H73QuizPage;