import axios from 'axios';
import { jwtDecode } from "jwt-decode";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface DecodedToken {
  exp: number;
  // Add other properties from your JWT payload if needed
}

interface RefreshTokenResponse {
  token: string;
  userType: string;
}

export const checkAndRefreshToken = async () => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    const decodedToken = jwtDecode<DecodedToken>(token); // You'll need to implement this
    const currentTime = Date.now() / 1000;
    
    if (decodedToken.exp < currentTime + 300) { // Refresh if token expires in less than 5 minutes
      try {
        const response = await axios.post('/api/refresh-token', {
          refreshToken: localStorage.getItem('refreshToken')
        });
        
        localStorage.setItem('jwtToken', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
      } catch (error) {
        console.error('Failed to refresh token:', error);
        // Handle failed refresh (e.g., logout user)
      }
    }
  }
};