import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function usePreviousPath() {
  const { pathname } = useLocation();
  const previousPathRef = useRef<string | null>(localStorage.getItem('previousPath'));

  useEffect(() => {
    if (pathname !== previousPathRef.current) {
      localStorage.setItem('previousPath', previousPathRef.current || '');
      previousPathRef.current = pathname;
    }
  }, [pathname]);

  return previousPathRef.current;
}