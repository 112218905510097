import React, { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../../components/User/Header";
import UserFooter from "../../../components/User/UserFooter";
import { ArrowLeft } from "lucide-react";
import AppleToggle from "../../../components/General/AppleToggle";
import SideNavUserProfile from "../../../components/User/SideNavUserProfile";
import EditButton from "../../../components/General/EditButton";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

type UserPreferences = {
  wantsPromotionalEmails: boolean;
  wantsLeaveAReviewReminder: boolean;
  wantsLocationBasedDeals: boolean;
  wantsPriceDropsForLikedOffers: boolean;
  wantsBookingRemindersOrUpdates: boolean;
};

interface PreferenceSettingProps {
  title: string;
  text: string;
  isChecked: boolean;
  onToggle: (newState: boolean) => void;
  isEditMode: boolean;
}

const PreferenceSetting: React.FC<PreferenceSettingProps> = ({
  title,
  text,
  isChecked,
  onToggle,
  isEditMode,
}) => {
  const handleToggle = (newState: boolean) => {
    if (isEditMode) {
      onToggle(newState);
    }
  };

  return (
    <>
      <div className="flex flex-row px-5 mt-10 justify-between w-[95%] mq780:w-full">
        <div className="flex flex-col gap-1">
          <h2 className="mt-1 mb-0 text-lg mq480:text-2xl">{title}</h2>
          <p className="max-w-[70vw]">{text}</p>
        </div>
        <div className={`w-auto pr-4 pl-6 ${!isEditMode ? 'opacity-50 pointer-events-none' : ''}`}>
          <AppleToggle initialState={isChecked} onToggle={handleToggle} />
        </div>
      </div>
      <div className="h-[1px] my-4 bg-zinc-200 w-full" />
    </>
  );
};

const NotificationSettings: FunctionComponent = () => {
  const navigate = useNavigate();
  const [preferences, setPreferences] = useState<UserPreferences>({
    wantsPromotionalEmails: false,
    wantsLeaveAReviewReminder: false,
    wantsLocationBasedDeals: false,
    wantsPriceDropsForLikedOffers: false,
    wantsBookingRemindersOrUpdates: false,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    fetchPreferences();
  }, []);

  const fetchPreferences = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get<UserPreferences>(`${API_BASE_URL}/v1/users/customer/preferences`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPreferences(response.data);
    } catch (error) {
      console.error('Error fetching preferences:', error);
    }
  };

  const updatePreference = (key: keyof UserPreferences, value: boolean) => {
    setPreferences(prev => ({ ...prev, [key]: value }));
    setHasChanges(true);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handlePreferenceEditClick = () => {
    setIsEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      await axios.put(`${API_BASE_URL}/v1/users/customer/preferences`, preferences, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setIsEditMode(false);
      setHasChanges(false);
    } catch (error) {
      console.error('Error updating preferences:', error);
    }
  };

  const renderPreferenceSettings = () => (
    <>
      <PreferenceSetting
        title="Booking Reminders or Updates"
        text="We'll remind you of upcoming bookings or updates to current bookings."
        isChecked={preferences.wantsBookingRemindersOrUpdates}
        onToggle={(newState) => updatePreference('wantsBookingRemindersOrUpdates', newState)}
        isEditMode={isEditMode}
      />
      <PreferenceSetting
        title="Leave a Review Reminder"
        text="We'll occasionally invite you to leave a review for any bookings you've made."
        isChecked={preferences.wantsLeaveAReviewReminder}
        onToggle={(newState) => updatePreference('wantsLeaveAReviewReminder', newState)}
        isEditMode={isEditMode}
      />
      <PreferenceSetting
        title="Location-based Deals"
        text="Get notified when there are deals nearby."
        isChecked={preferences.wantsLocationBasedDeals}
        onToggle={(newState) => updatePreference('wantsLocationBasedDeals', newState)}
        isEditMode={isEditMode}
      />
      <PreferenceSetting
        title="Price Drops for liked Offers"
        text="Get notified when a booking you've saved has a price drop."
        isChecked={preferences.wantsPriceDropsForLikedOffers}
        onToggle={(newState) => updatePreference('wantsPriceDropsForLikedOffers', newState)}
        isEditMode={isEditMode}
      />
      <PreferenceSetting
        title="Promotional Emails"
        text="We'll occasionally send you emails with personalized deals for you."
        isChecked={preferences.wantsPromotionalEmails}
        onToggle={(newState) => updatePreference('wantsPromotionalEmails', newState)}
        isEditMode={isEditMode}
      />
    </>
  );

  return (
    <>
      {/* Desktop (>=780px) */}
      <div className="hidden mq780:flex flex-col min-h-screen">
        <Header />
        <div className="flex flex-1 bg-gray-100 font-subhead">
          <SideNavUserProfile activeItem="Notifications" />
          <div className="flex-1 p-8">
            <div className="bg-white rounded-lg p-6 max-w-3xl">
              <div className="flex flex-row justify-between">
                <h2 className="text-2xl text-main-teal font-semibold mb-4">Preferences</h2>
                {!isEditMode && (
                  <div onClick={handlePreferenceEditClick} className="w-auto h-[30px] mt-6 cursor-pointer">
                    <EditButton />
                  </div>
                )}
              </div>
              <p className="text-gray-600 mb-10">
                What types of emails would you like to receive?
              </p>
              <div className="space-y-6 mt-10">
                {renderPreferenceSettings()}
              </div>
              <div className="flex flex-row justify-center items-center">
                {isEditMode && (
                  <button
                    onClick={handleSaveClick}
                    className={`vursor-pointer mt-6 px-20 py-3 rounded-md ${
                      hasChanges ? 'bg-main-teal text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    }`}
                    disabled={!hasChanges}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </div>

      {/* Mobile (<780px) */}
      <div className="mq780:hidden w-full relative bg-white overflow-hidden flex flex-col min-h-screen justify-between px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
        <div className="flex justify-between items-center px-4 mt-3 mb-1">
          <button onClick={handleBackClick} className="text-gray-500 pt-2 bg-white">
            <ArrowLeft className='w-7 h-7 stroke-gray-300' />
          </button>
          <h2 className="text-xl font-semibold">Notification Settings</h2>
          {!isEditMode && (
            <div onClick={handlePreferenceEditClick} className="cursor-pointer">
              <EditButton />
            </div>
          )}
        </div>
        <div className="h-[1px] bg-zinc-300 px-4 mx-4" />
        <div className="flex-1 flex flex-col items-start justify-start text-start mb-10 mb-0 min-h-[90vh]">
          {renderPreferenceSettings()}
        </div>
        {isEditMode && (
          <div className="px-4 py-4">
            <button
              onClick={handleSaveClick}
              className={`w-full py-2 rounded-md ${
                hasChanges ? 'bg-main-teal text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
              disabled={!hasChanges}
            >
              Save
            </button>
          </div>
        )}
        <footer>
          <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>
      </div>
    </>
  );
};

export default NotificationSettings;