import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  
  // Add a request interceptor to include the JWT token
  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
  
  export const createOrder = async (offerId: string, quantity: number) => {
    const jwtToken = localStorage.getItem('jwtToken');
    const endpoint = '/v1/orders';
  
    const payload = {
      items: [
        {
          offerId,
          quantity
        }
      ]
    };
  
    try {
      const response = await axiosInstance.post(endpoint, payload);
      return response.data;
    } catch (error) {
      console.error('Error creating order:', error);
      throw error;
    }
  };
  
  export const createCheckoutSession = async (orderId: string) => {
    try {
      const response = await axiosInstance.post(`/v1/orders/${orderId}/checkout`);
      return response.data;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  };