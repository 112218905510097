import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

const ConfirmEmail: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const apiCallMade = useRef(false);

  useEffect(() => {
    const confirmEmail = async () => {
      if (!token) {
        setError('No confirmation token found');
        return;
      }

      if (apiCallMade.current) return; // Skip if API call was already made

      apiCallMade.current = true;

      try {
        const response = await axios.post(
          `${API_BASE_URL}/v1/users/auth/email-confirmation/confirm`,
          { token }
        );

        if (response.status === 200 || response.status === 204 || response.status === 201) {
          try {
            let profileResponse;
            if (localStorage.getItem('userType') === "MERCHANT") {
                profileResponse = await axios.get(`${API_BASE_URL}/v1/users/merchant/profile`, {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem("jwtToken")}`
                }
              });
            } else {
                profileResponse = await axios.get(`${API_BASE_URL}/v1/users/customer/profile`, {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem("jwtToken")}`
                }
              });
            }
            if (profileResponse.data.userType !== "CUSTOMER") {
              setTimeout(() => navigate('/merchant-hub-home'), 3000);
            } else {
              setTimeout(() => navigate('/account/profile'), 3000);
            }
          } catch (profileError) {
            console.error('Error fetching profile:', profileError);
            setTimeout(() => navigate("/"), 5000);
          }
        }
      } catch (error) {
        console.error('Failed to confirm email:', error);
        setError(`Something went wrong ${error}`);
        setTimeout(() => navigate('/merchant-hub-home'), 2000);
      }
    };

    confirmEmail();
  }, [token, navigate]);

  if (error) {
    return (
      <div className="font-subhead flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h1 className="text-2xl font-bold mb-4 text-red-600">Error</h1>
          <p>{error}</p>
          <button 
            onClick={() => navigate('/account/profile')}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-[#478abf]"
          >
            Go to Profile
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="font-subhead flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className=" font-subheadtext-2xl font-bold mb-4">Confirming your email...</h1>
        <p className="font-subhead">Please wait while we confirm your email address.</p>
      </div>
    </div>
  );
};

export default ConfirmEmail;