import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from "axios";
import { Eye, EyeOff } from "lucide-react";
import Header from "../../../components/User/Header";
import UserFooter from "../../../components/User/UserFooter";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface ResetPasswordFormData {
  newPassword: string;
  confirmPassword: string;
}

const ResetPassword: React.FC = () => {
  const [formData, setFormData] = useState<ResetPasswordFormData>({
    newPassword: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { token } = useParams<{ token: string }>();
  // console.log("token: ")
  // console.log(token)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
  
    if (formData.newPassword !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }
  
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])(?=.*[^a-zA-Z0-9]).{8,}$/;
    if (!passwordRegex.test(formData.newPassword)) {
      setError("Password must include at least one lowercase letter, one uppercase letter, one number, and one special character (!@#?%)");
      return;
    }
  
    if (!token) {
      setError("No token specified");
      return;
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/v1/users/auth/password-reset/confirm`, {
        token: token,
        newPassword: formData.newPassword
      });
  
      if (response.status === 204) {
        setSuccess(true);
        // Optionally redirect to login page after a delay
        setTimeout(() => navigate('/login'), 3000);
      }
    } catch (error) {
      setError("Failed to reset password. Please try again.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-1 items-center justify-center">
        <div className="hidden mq780:block w-full md:w-[300px] lg:w-[30vw] max-w-[330px] bg-whitesmoke flex flex-col pl-10 pt-[60px] pb-[40vh] min-h-screen px-4 box-border gap-[30px] text-left text-xl font-semibold text-[#478abf] font-subhead">
            <button 
                className="w-[80%] cursor-pointer font-subhead text-left text-base font-semibold py-2 px-4 rounded-md bg-[#478abf] text-white"
            >
            Reset Password
            </button>
        </div>
        <div className="flex flex-row min-h-[40vh] justify-center items-center flex-1 p-8 bg-gray-100 font-subhead">
          <div className="bg-white p-8 rounded-lg w-[50vw] py-[150px] mq780:py-0">
            <div className="flex items-center mb-8">
              <img 
                src="../Avatar_Default@4x.png"
                alt="Profile"
                className="hidden mq480:flex flex w-24 h-24 rounded-full mr-6"
                crossOrigin="anonymous"
              />
              <div>
                <h2 className="text-2xl font-semibold text-gray-800">Reset Password</h2>
                <p className="text-gray-600 mb-1">Enter your new password below</p>
              </div>
            </div>
            {success ? (
              <p className="text-green-500">Password reset successful. Redirecting to login...</p>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleInputChange}
                    placeholder="New Password"
                    className="w-[calc(100%-20px)] p-2 border border-gray-300 rounded-md"
                    required
                  />
                  <span
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeOff className="w-5 h-5 mr-0 text-gray-500" /> : <Eye className="w-5 h-5 pr-0 text-gray-500" />}
                  </span>
                </div>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    placeholder="Confirm New Password"
                    className="w-[calc(100%-20px)] p-2 border border-gray-300 rounded-md"
                    required
                  />
                  <span
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <EyeOff className="w-5 h-5 text-gray-500" /> : <Eye className="w-5 h-5 text-gray-500" />}
                  </span>
                </div>
                {error && <p className="text-red-500">{error}</p>}
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-700"
                >
                  Reset Password
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
      <UserFooter
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </div>
  );
};

export default ResetPassword;