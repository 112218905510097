import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/User/Header";
import UserFooter from "../../../components/User/UserFooter";
import { ArrowLeft } from "lucide-react";

const SuggestFeature: FunctionComponent = () => {
    const navigate = useNavigate();
    const [importance, setImportance] = useState<string | null>(null);

    const handleBackClick = () => {
      navigate(-1);
    };
  
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      // Add your submission logic here
    };

  return (
    <>
      {/* Desktop (>=780px) */}
      <div className="hidden mq780:flex flex-col">
        <Header />
        <main className="flex-grow flex justify-center items-center">
          {/* Add your desktop version of the form here if needed */}
        </main>
        <footer className="mq780:hidden">
          <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>
      </div>

      {/* Mobile (<780px) */}
      <div className="mq780:flex w-full relative bg-white overflow-hidden flex flex-col justify-between px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead min-h-screen">
        <div className="mq780:hidden flex justify-between items-center px-4 mt-3 mb-1">
          <button onClick={handleBackClick} className="text-gray-500 pt-2 bg-white">
            <ArrowLeft className='w-7 h-7 stroke-gray-300' />
          </button>
          <h2 className="text-xl font-semibold ml-4">Suggest Feature</h2>
          <div className="w-7 h-7"></div> {/* Placeholder for symmetry */}
        </div>
        <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>

        <form onSubmit={handleSubmit} className="flex flex-col px-4 pr-8 py-6 space-y-4 min-h-[90vh]">
          <div className="flex flex-col items-start mb-8">
            <label htmlFor="idea" className="text-left text-xs mb-1 font-bold">Describe your idea</label>
            <textarea
              id="idea"
              className="w-full h-32 p-2 border border-silver-100 rounded-8xs text-xs"
              required
            />
          </div>

          <div className="flex flex-col items-start mb-8">
            <label className="text-left text-xs mb-1 font-bold">How important is this to you?</label>
            <div className="flex space-x-2">
            <button
                type="button"
                onClick={() => setImportance('NICE TO HAVE')}
                className={`cursor-pointer px-3 py-4 font-subhead font-semibold rounded-8xs text-xxs ${
                    importance === 'NICE TO HAVE' ? 'bg-green text-zinc-100' : 'bg-green/20 text-green'
                }`}
                >
                NICE TO HAVE
            </button>
            <button
                type="button"
                onClick={() => setImportance('IMPORTANT')}
                className={`cursor-pointer px-3 py-4 font-subhead font-semibold rounded-8xs text-xxs ${
                    importance === 'IMPORTANT' ? 'bg-coral text-zinc-100' : 'bg-coral/20 text-coral'
                }`}
                >
                IMPORTANT
            </button>
            <button
                type="button"
                onClick={() => setImportance('CRITICAL')}
                className={`cursor-pointer px-3 py-4 font-subhead font-semibold rounded-8xs text-xxs ${
                    importance === 'CRITICAL' ? 'bg-chocolate text-zinc-100' : 'bg-chocolate/20 text-chocolate'
                }`}
                >
                CRITICAL
            </button>
            </div>
          </div>
          <div className="flex flex-col items-start mt-8">
            <label htmlFor="email" className="text-left text-xs mb-1 font-bold">Email</label>
            <input
              type="email"
              id="email"
              className="w-full max-w-[680px] mq780:max-w-[300px] p-2 border border-silver-100 rounded-8xs text-xs"
              required
            />
          </div>
          <button
            type="submit"
            className="cursor-pointer mt-4 w-[104%] mq780:max-w-[320px] max-w-[700px] bg-main-teal text-white py-3 rounded-8xs text-base font-semibold"
          >
            Submit
          </button>
        </form>

        <footer>
          <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>
      </div>
    </>
  );
};

export default SuggestFeature;