import React from "react";
import { Store, Compass, Tag, Calendar, MapPin, Users } from "lucide-react";

export type CarouselVariant =
    | "trending"
    | "deals"
    | "businesses"
    | "events"
    | "nearby"
    | "personalized"
    | "restaurants";

interface VariantConfig {
    icon: JSX.Element;
    title: string;
    message: string;
    cta: string;
}

interface EmptyCarouselStateProps {
    type?: CarouselVariant;
    className?: string;
}

const variants: Record<CarouselVariant, VariantConfig> = {
    trending: {
        icon: <Compass className="w-10 h-10 text-main-teal" />,
        title: "No Trending Deals Yet",
        message: "Be the first to explore exciting local experiences",
        cta: "Check back soon for trending deals",
    },
    deals: {
        icon: <Tag className="w-10 h-10 text-main-teal" />,
        title: "No Active Deals",
        message: "We're gathering the best local offers just for you",
        cta: "New deals coming soon",
    },
    businesses: {
        icon: <Store className="w-10 h-10 text-main-teal" />,
        title: "No Businesses Listed",
        message: "Be among the first local businesses to get discovered",
        cta: "List your business with us",
    },
    events: {
        icon: <Calendar className="w-10 h-10 text-main-teal" />,
        title: "No Upcoming Events",
        message: "Stay tuned for exciting activities in your area",
        cta: "Events coming soon",
    },
    nearby: {
        icon: <MapPin className="w-10 h-10 text-main-teal" />,
        title: "No Nearby Offers",
        message: "We're finding great local deals in your area",
        cta: "Check back soon for nearby deals",
    },
    personalized: {
        icon: <Users className="w-10 h-10 text-main-teal" />,
        title: "No Personalized Deals Yet",
        message: "We're curating the perfect deals for you",
        cta: "Explore other categories meanwhile",
    },
    restaurants: {
        icon: <Store className="w-10 h-10 text-main-teal" />,
        title: "No Restaurant Deals",
        message: "We're gathering the best dining experiences",
        cta: "Check back soon for restaurant deals",
    },
};

const EmptyCarouselState: React.FC<EmptyCarouselStateProps> = ({
    type = "trending",
    className = "",
}) => {
    const selected = variants[type];

    return (
        <div className="w-full flex items-center justify-center py-8">
            <div className={`
                max-w-[300px] w-full mx-auto py-8 px-6
                flex flex-col items-center justify-center
                bg-gradient-to-b from-gray-50 to-white
                rounded-lg border border-gray-100
                ${className}
            `}>
                <div className="mb-6 p-4 rounded-full bg-main-teal bg-opacity-10">
                    {selected.icon}
                </div>

                <h3 className="text-xl font-bold mb-2 text-gray-900">
                    {selected.title}
                </h3>

                <p className="text-gray-600 mb-4 text-sm text-center max-w-[250px]">
                    {selected.message}
                </p>

                <span className="text-main-teal text-sm font-medium">
                    {selected.cta}
                </span>
            </div>
        </div>
    );
};

export default EmptyCarouselState;
