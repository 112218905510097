import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the JWT token
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export const createAccountSession = async () => {
  try {
    const response = await axiosInstance.post('/v1/merchants/account-session');
    return response.data;
  } catch (error) {
    console.error('Error creating account session:', error);
    throw error;
  }
};

export const createAccountLink = async () => {
  try {
    const response = await axiosInstance.post('/v1/merchants/account-link');
    return response.data;
  } catch (error) {
    console.error('Error creating account link:', error);
    throw error;
  }
};

export const getOnboardingStatus = async () => {
  try {
    const response = await axiosInstance.get('/v1/merchants/onboarding/status');
    return response.data;
  } catch (error) {
    console.error('Error getting onboarding status:', error);
    throw error;
  }
};

export const fetchMerchantAnalytics = async () => {
  // Mock data for testing
  // const mockData = {
  //   default_currency: "USD",
  //   revenue: [
  //     { date: 'Jan', amount: 1000 },
  //     { date: 'Feb', amount: 1500 },
  //     { date: 'Mar', amount: 1200 },
  //     { date: 'Apr', amount: 2000 },
  //     { date: 'May', amount: 1800 },
  //     { date: 'Jun', amount: 3020 },
  //     { date: 'Jul', amount: 4000 },
  //     { date: 'Aug', amount: 1800 },
  //   ],
  //   recentTransactions: [
  //     { date: '2023-05-15', amount: 150, description: 'Product A Purchase', offerId: 'f1121b80-87d4-4f77-bb08-d49b6e8be8e0'},
  //     { date: '2023-05-14', amount: 200, description: 'Service B Pruchase', offerId: 'f1121b80-87d4-4f77-bb08-d49b6e8be8e0' },
  //     { date: '2023-05-13', amount: 75, description: 'Product C Purchase', offerId: 'f1121b80-87d4-4f77-bb08-d49b6e8be8e0' },
  //     { date: '2023-05-15', amount: 150, description: 'Product A Purchase', offerId: 'f1121b80-87d4-4f77-bb08-d49b6e8be8e0' },
  //     { date: '2023-05-14', amount: 200, description: 'Service B Pruchase', offerId: 'f1121b80-87d4-4f77-bb08-d49b6e8be8e0' },
  //     { date: '2023-05-13', amount: 75, description: 'Product C Purchase', offerId: 'f1121b80-87d4-4f77-bb08-d49b6e8be8e0' },
  //     { date: '2023-05-15', amount: 150, description: 'Product A Purchase', offerId: 'f1121b80-87d4-4f77-bb08-d49b6e8be8e0' },
  //     { date: '2023-05-14', amount: 200, description: 'Service B Pruchase', offerId: 'f1121b80-87d4-4f77-bb08-d49b6e8be8e0' },
  //     { date: '2023-05-13', amount: 75, description: 'Product C Purchase', offerId: 'f1121b80-87d4-4f77-bb08-d49b6e8be8e0' },
  //   ],
  //   monthlyCustomers: [
  //     { month: 'Jan', customers: 100 },
  //     { month: 'Feb', customers: 120 },
  //     { month: 'Mar', customers: 140 },
  //     { month: 'Apr', customers: 160 },
  //     { month: 'May', customers: 180 },
  //     { month: 'Jun', customers: 270 },
  //     { month: 'Jul', customers: 340 },
  //     { month: 'Aug', customers: 200 },
  //   ]
  // };

  // return mockData;

  // Uncomment and use this when you have the actual API endpoint
  try {
    const response = await axiosInstance.get('/v1/merchants/dashboard-data');
    return response.data;
  } catch (error) {
    console.error('Error fetching merchant analytics:', error);
    throw error;
  }
};