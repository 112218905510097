import { useState } from "react";
import { ChevronLeft, ChevronRight, Circle, CircleDot } from "lucide-react";
import { FullScreenImagePreview } from "./FullScreenImagePreview";

type ImageSliderProps = {
  images: {
    url: string;
    alt?: string;
  }[];
};

export function ImageSlider({ images }: ImageSliderProps) {
  const [imageIndex, setImageIndex] = useState(0);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  function showNextImage() {
    setImageIndex((index) => {
      if (index === images.length - 1) return 0;
      return index + 1;
    });
  }

  function showPrevImage() {
    setImageIndex((index) => {
      if (index === 0) return images.length - 1;
      return index - 1;
    });
  }

  return (
    <>
      <div
        aria-label="Image Slider"
        className="relative max-w-[800px] h-[450px]"
      >
        <div className="flex w-full h-full overflow-hidden">
          {images.map(({ url, alt }, index) => (
            <img
              key={url}
              src={url}
              alt={alt}
              aria-hidden={imageIndex !== index}
              className="img-slider-img object-cover cursor-pointer w-full h-full block flex-shrink-0 flex-grow-0 transition-transform duration-300 ease-in-out rounded-lg cursor-pointer"
              style={{ transform: `translateX(${-100 * imageIndex}%)` }}
              onClick={() => setPreviewImage(url)}
            />
          ))}
        </div>
        <button
          type="button"
          onClick={showPrevImage}
          className="absolute top-0 bottom-0 left-0 p-4 cursor-pointer transition-bg duration-100 ease-in-out bg-transparent hover:bg-black/20 focus-visible:bg-black/20"
          aria-label="View Previous Image"
        >
          <ChevronLeft className="stroke-white w-12 h-12" aria-hidden />
        </button>
        <button
          type="button"
          onClick={showNextImage}
          className="absolute top-0 bottom-0 right-0 p-4 cursor-pointer transition-bg duration-100 ease-in-out bg-transparent hover:bg-black/20 focus-visible:bg-black/20"
          aria-label="View Next Image"
        >
          <ChevronRight className="stroke-white w-12 h-12" aria-hidden />
        </button>
        <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-1">
          {images.map((_, index) => (
            <button
              type="button"
              key={index}
              className="img-slider-dot-btn cursor-pointer w-4 h-4 transition-transform duration-100 ease-in-out hover:scale-110 focus-visible:scale-110 rounded-full"
              aria-label={`View Image ${index + 1}`}
              onClick={() => setImageIndex(index)}
            >
              {index === imageIndex ? (
                <CircleDot type="button" className="hidden stroke-white fill-black w-full h-full" aria-hidden />
              ) : (
                <Circle type="button" className="stroke-white w-full h-full" aria-hidden />
              )}
            </button>
          ))}
        </div>
        <div id="after-image-slider-controls" />
      </div>
      {previewImage && (
        <FullScreenImagePreview
          imageUrl={previewImage}
          alt={images[imageIndex].alt}
          onClose={() => setPreviewImage(null)}
        />
      )}
    </>
  );
}