import React, { FunctionComponent, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import QrScanner from 'react-qr-scanner'; //Typechecking Jucka 
import axios from 'axios';

const ScanQRCode: FunctionComponent = () => {
  const [scanResult, setScanResult] = useState<string | null>(null);
  const [scanStatus, setScanStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [isCameraActive, setIsCameraActive] = useState<boolean>(false);
  
  const location = useLocation();

  useEffect(() => {
    let cameraStream: MediaStream | null = null;

    const activateCamera = async () => {
      try {
        cameraStream = await navigator.mediaDevices.getUserMedia({ video: true });
        setHasPermission(true);
        setIsCameraActive(true);
      } catch (err) {
        setHasPermission(false);
        setErrorMessage('Camera permission denied. Please enable camera access and refresh the page.');
      }
    };

    const deactivateCamera = () => {
      if (cameraStream) {
        cameraStream.getTracks().forEach(track => track.stop());
        setIsCameraActive(false);
      }
    };

    if (location.pathname === '/merchant-scan-qr-code') {
      activateCamera();
    }

    return () => {
      deactivateCamera();
    };
  }, [location]);

  const handleScan = async (result: string | null) => {
    if (result) {
      setScanResult(result);
      try {
        // Convert the QR code data to a Blob
        const blob = new Blob([result], { type: 'text/plain' });
        
        // Create FormData and append the blob
        const formData = new FormData();
        formData.append('file', blob, 'qrcode.txt');

        // Get the JWT token from localStorage
        const jwtToken = localStorage.getItem('jwtToken');

        if (!jwtToken) {
          throw new Error('No JWT token found');
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/v1/orders/scan-qr`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${jwtToken}`
            }
          }
        );

        if (response.status === 204) {  // No Content
          setScanStatus('success');
        } else {
          setScanStatus('error');
          setErrorMessage('An unexpected error occurred');
        }
      } catch (error) {
        setScanStatus('error');
        if (axios.isAxiosError(error) && error.response) {
          setErrorMessage(error.response.data.message || 'An error occurred while processing the QR code');
        } else {
          setErrorMessage('An unexpected error occurred');
        }
      }
    }
  };

  const handleError = (error: Error) => {
    console.error(error);
    setErrorMessage('An error occurred while scanning');
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MerchantHubHeader />
      <main className="flex-1 flex font-subhead flex-col items-center justify-center p-4">
        <h1 className="text-2xl font-bold mb-4">Scan QR Code</h1>
        {hasPermission === null ? (
          <p>Requesting camera permission...</p>
        ) : hasPermission && isCameraActive ? (
          <div className="w-full max-w-md">
            <QrScanner
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%' }}
            />
            {scanStatus === 'idle' && (
              <p className="text-center mt-4">Please scan your QR Code now</p>
            )}
          </div>
        ) : (
          <p className="text-coral font-bold mt-4">{errorMessage}</p>
        )}
        {scanStatus === 'success' && (
          <p className="text-green font-bold mt-4">QR Code Scanned Successfully</p>
        )}
        {scanStatus === 'error' && errorMessage && (
          <p className="text-coral font-bold mt-4">{errorMessage}</p>
        )}
      </main>
      <footer className="mt-auto">
        <Footer
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>  
    </div>
  );
};

export default ScanQRCode;