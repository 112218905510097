import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import CardComponent from "./CardComponent";
import { getOnboardingStatus } from "../../api/stripe/stripeApi";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

type MyDealsProps = {
  selected: "View all" | "Offers" | "Events";
};

interface Deal {
  id: string;
  name: string;
  price: number;
  description: string;
  enabled: boolean;
  displayImageReferences: string[];
  recurrencePattern: {
    frequency: string;
  };
}

interface PaginatedResponse {
  content: Deal[];
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  last: boolean;
}

const MyDeals: React.FC<MyDealsProps> = ({ selected }) => {
  const [activeTab, setActiveTab] = useState<"View all" | "Offers" | "Events">(selected);
  const [onboardingStatus, setOnboardingStatus] = useState<string | null>(null);
  const [allDeals, setAllDeals] = useState<Deal[]>([]);
  const [filteredDeals, setFilteredDeals] = useState<Deal[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [showVerificationWarning, setShowVerificationWarning] = useState(false);
  const [promotionResponses, setPromotionResponses] = useState<Record<string, any>>({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
    fetchDeals();
    fetchOnboardingStatus();
  }, []);

  useEffect(() => {
    filterDeals();
  }, [activeTab, allDeals]);

  const fetchOnboardingStatus = async () => {
    try {
      const status = await getOnboardingStatus();
      setOnboardingStatus(status);
    } catch (err) {
      console.error('Error fetching onboarding status:', err);
      setError('Failed to load onboarding status. Please try again later.');
    }
  };

  const fetchPromotionData = async (offerIds: string[]) => {
    try {
      const promotionData: Record<string, any> = {};
      for (const offerId of offerIds) {
        const response = await axios.get(`${API_BASE_URL}/v1/promotions/by-offer/${offerId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        promotionData[offerId] = response.data;
      }
      setPromotionResponses(prevResponses => ({...prevResponses, ...promotionData}));
    } catch (err) {
      // console.error('Error fetching promotion data:', err);
    }
  };

  const fetchDeals = async () => {
    try {
      setLoading(true);
      const response = await axios.get<PaginatedResponse>(`${API_BASE_URL}/v1/catalog/offers/merchant?page=${currentPage}&size=10`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      const paginatedData = response.data;
      const newDeals = [...allDeals, ...paginatedData.content];
      setAllDeals(newDeals);
      setHasMore(!paginatedData.last);
      setCurrentPage(prevPage => prevPage + 1);
      setLoading(false);

      // Fetch promotion data for new deals
      await fetchPromotionData(paginatedData.content.map(deal => deal.id));
    } catch (err) {
      console.error('Error fetching deals:', err);
      setError('Failed to load deals. Please try again later.');
      setLoading(false);
    }
  };

  const filterDeals = () => {
    switch (activeTab) {
      case "Events":
        setFilteredDeals(allDeals.filter(deal => deal.recurrencePattern.frequency === "ONCE"));
        break;
      case "Offers":
        setFilteredDeals(allDeals.filter(deal => deal.recurrencePattern.frequency !== "ONCE"));
        break;
      default:
        setFilteredDeals(allDeals);
    }
  };

  const handleTabClick = (tab: "View all" | "Offers" | "Events") => {
    setActiveTab(tab);
  };

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/users/merchant/profile`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      // // console.log("userProfile", response.data);
      setUserProfile(response.data);
    } catch (err) {
      console.error('Error fetching user profile:', err);
      setError('Failed to load user profile. Please try again later.');
    }
  };

  const handleCreateNewPost = () => {
    // // console.log("onboardingStatus: ",onboardingStatus)
    if (onboardingStatus == "You did the box right?") { //TODO Change to !== "VERIFIED" for deployment
      // // console.log("Change onboarding status check to !== 'VERIFIED' for deployment")
      setShowVerificationWarning(true);
    } else {
      navigate('/merchant-hub-post-1');
    }
  };

  const handleLoadMore = () => {
    if (hasMore) {
      fetchDeals();
    }
  };

  const getButtonClassNames = (tab: "View all" | "Offers" | "Events") => (
    `cursor-pointer ${
      activeTab === tab ? "text-blue-500" : "text-zinc-800"
    } bg-transparent cursor-pointer`
  );

  const getBarClassNames = () => {
    switch (activeTab) {
      case "View all":
        return "left-0 w-1/3";
      case "Offers":
        return "left-1/3 w-1/3";
      case "Events":
        return "left-2/3 w-1/3";
      default:
        return "";
    }
  };

  const deleteOffer = async (offerId: string) => {
    try {
      const promotionData = promotionResponses[offerId];

      // If a promotion exists, delete it first
      if (promotionData && promotionData.id) {
        try {
          await axios.delete(`${API_BASE_URL}/v1/promotions/${promotionData.id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
          });
          // // console.log(`Associated promotion ${promotionData.id} deleted.`);
        } catch (promotionError) {
          console.error('Error deleting associated promotion:', promotionError);
          // Continue with offer deletion even if promotion deletion fails
        }
      }

      // Now delete the offer
      await axios.delete(`${API_BASE_URL}/v1/catalog/offers/${offerId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });

      // Update the state to remove the deleted offer
      setAllDeals(prevDeals => prevDeals.filter(deal => deal.id !== offerId));
      
      // Remove the promotion data for the deleted offer
      setPromotionResponses(prevResponses => {
        const newResponses = {...prevResponses};
        delete newResponses[offerId];
        return newResponses;
      });

      // console.log(`Offer ${offerId} deleted successfully.`);
    } catch (err) {
      console.error('Error deleting offer:', err);
      if (axios.isAxiosError(err) && err.response) {
        console.error('Error response:', err.response.data);
      }
      setError('Failed to delete offer. Please try again later.');
    }
  };

  const handleDealClick = (dealId: string) => {
    navigate(`/merchant-hub-post-1/${dealId}`);
  };

  return (
    <div className="flex flex-col grow pt-4 w-full max-w-full font-semibold mq1050:pl-8">
      <div className="text-29xl leading-[50.04px] tracking-tight text-zinc-800">
        My Deals
      </div>
      <div className="mt-4 font-medium text-3xl tracking-tight leading-9 text-zinc-600">
        Share and manage all of your offers and events with your customers.
      </div>
      <div className="flex flex-col px-0 pb-2 w-full">
        <div className="flex gap-5 justify-between self-center mt-14 w-full text-base font-medium leading-7 text-zinc-800">
          <button
            onClick={() => handleTabClick("View all")}
            className={getButtonClassNames("View all")}
          >
            VIEW ALL
          </button>
          <button
            onClick={() => handleTabClick("Offers")}
            className={getButtonClassNames("Offers")}
          >
            OFFERS
          </button>
          <button
            onClick={() => handleTabClick("Events")}
            className={getButtonClassNames("Events")}
          >
            EVENTS
          </button>
        </div>
        <div className="relative flex flex-col justify-center items-start mt-2.5 rounded-xl bg-zinc-300 h-1">
          <div className={`absolute h-1 bg-blue-500 rounded-xl transition-all duration-300 ${getBarClassNames()}`} />
        </div>
        {loading && allDeals.length === 0 ? (
          <div className="mt-14 text-base leading-7 text-center text-zinc-800">Loading deals...</div>
        ) : error ? (
          <div className="mt-14 text-base leading-7 text-center text-red-500">{error}</div>
        ) : allDeals.length === 0 ? (
          <div className="mt-14 text-base leading-7 text-center text-zinc-800">
            You don't have any posts for your customers to see yet.
          </div>
        ) : (
          <div className="flex flex-col gap-3 pt-3 max-h-[600px] overflow-y-auto">
            {filteredDeals.map((deal, index) => (
              <CardComponent
                key={deal.id}
                id={deal.id}
                image={deal.displayImageReferences[0] || `img-${index + 1}@2x.png`}
                title={deal.name}
                price={deal.price.toFixed(2)}
                description={deal.description}
                pending={!deal.enabled}
                onDelete={deleteOffer}
                onClick={handleDealClick}
              />
            ))}
          {loading && <div className="text-center">Loading more deals...</div>}
          {hasMore && !loading && (
            <button
              onClick={handleLoadMore}
              className="cursor-pointer justify-center self-center px-8 py-3 mt-4 text-base font-bold tracking-tight text-center text-white bg-blue-900 rounded-lg"
            >
              Load More
            </button>
          )}
        </div>
        )}
        {showVerificationWarning && (
        <div className="text-red-500 text-center mt-4">
          Set up your Stripe Profile in the "Home" Tab.
        </div>
        )}
        <button
          onClick={handleCreateNewPost}
          className="cursor-pointer justify-center self-center px-8 py-3 mt-8 mb-16 text-base font-bold tracking-tight text-center text-white bg-blue-900 rounded-lg"
        >
          Create New Post
        </button>
      </div>
    </div>
  );
};

export default MyDeals;