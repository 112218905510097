import React from 'react';

interface Highlight {
  id: string;
  name: string;
}

interface HighlightSectionProps {
  highlights: Highlight[];
}

const HighlightSection: React.FC<HighlightSectionProps> = ({ highlights }) => {
  return (
    <div className="flex flex-col mt-1 max-md:mt-10">
      <div className="text-xl font-semibold tracking-tight text-blue-900">
        Highlights
      </div>
      <div className="mt-5 flex flex-wrap gap-4">
        {highlights.map(highlight => (
          <span
            key={highlight.id}
            className="px-4 py-2 rounded-full bg-blue-100 text-blue-800 border border-blue-300"
          >
            {highlight.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default HighlightSection;