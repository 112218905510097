import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { AtSign } from "lucide-react";
import Header from "../../components/User/Header";
import UserFooter from "../../components/User/UserFooter";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

const EmailChange: React.FC = () => {
  const [newEmail, setNewEmail] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    const fetchNewEmail = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/v1/users/profile`, { token }, {
            headers: {Authorization: `Bearer ${localStorage.getItem('jwtToken')}`}
        });
        setNewEmail(response.data.newEmail);
      } catch (error) {
        setError("Failed to fetch new email. Please try again.");
      }
    };

    if (token) {
      fetchNewEmail();
    }
  }, [token]);

  const handleConfirm = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/v1/users/email-change/confirm`, { token });
      if (response.status === 200 || response.status === 204) {
        setSuccess(true);
        setTimeout(() => navigate('/account/profile'), 3000);
      }
    } catch (error) {
      setError("Failed to confirm email change. Please try again.");
    }
  };

  const handleCancel = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/v1/users/email-change-reversal/confirm`, { token });
      if (response.status === 200 || response.status === 204) {
        setSuccess(true);
        setTimeout(() => navigate('/account/profile'), 3000);
      }
    } catch (error) {
      setError("Failed to confirm email change. Please try again.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-1 items-center justify-center">
        <div className="hidden mq780:block w-full md:w-[300px] lg:w-[30vw] max-w-[330px] bg-whitesmoke flex flex-col pl-10 pt-[60px] pb-[40vh] min-h-screen px-4 box-border gap-[30px] text-left text-xl font-semibold text-[#478abf] font-subhead">
          <button 
            className="w-[80%] cursor-pointer font-subhead text-left text-base font-semibold py-2 px-4 rounded-md bg-[#478abf] text-white"
          >
            Email Change
          </button>
        </div>
        <div className="flex flex-row min-h-[40vh] justify-center items-center flex-1 p-8 bg-gray-100 font-subhead">
          <div className="bg-white p-8 rounded-lg w-[50vw] py-[150px] mq780:py-0">
            <div className="flex items-center mb-8">
              <AtSign className="w-16 h-16 stroke-[#478abf] mr-4" />
              <div>
                <h2 className="text-2xl font-semibold text-gray-800">Email Change</h2>
                <p className="text-gray-600 mb-1">Confirm your new email address</p>
              </div>
            </div>
            {success ? (
              <p className="text-green-500">Email change successful. Redirecting to account page...</p>
            ) : (
              <div className="space-y-6">
                {newEmail && (
                  <p className="text-61xl font-semibold text-center">{newEmail}</p>
                )}
                {/* {error && <p className="text-red-500">{error}</p>} */}
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={handleConfirm}
                    className="bg-blue-500 text-white p-3 rounded-md hover:bg-blue-700"
                  >
                    Confirm Change
                  </button>
                  <button
                    onClick={handleCancel}
                    className="bg-gray-300 text-gray-700 p-3 rounded-md hover:bg-gray-400"
                  >
                    Cancel Change
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <UserFooter
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </div>
  );
};

export default EmailChange;