import { FunctionComponent, useCallback } from "react";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import MerchantPage1 from "../../components/Merchant/MerchantSignup/MerchantPage1";

const TermsOfService: FunctionComponent = () => {
  const onItemClick = useCallback(() => {
    // Please sync "H4.1 Details Page - K1 Speed" to the project
  }, []);

  const onQuickAddContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='background']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onArrowFrontClick = () => {
    //Add functionality
  };

  const onArrowBackClick = () => {
    //Add functionality
  };

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col justify-center  px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
      <MerchantHubHeader />
      <h1 className="text-11xl font-subhead font-semibold pt-20">Privacy Policy</h1>
      <div className="items-center justify-center flex pb-20">
      <main className="relative mx-10 px-6 font-subhead mt-20 max-w-[650px] text-start items-start justify-start">
      <strong>Effective Date: Jul 5th 2024</strong><br />
      <br />
      <strong className="text-start items-start flex justify-start m-0 mt-2 ">Introduction</strong><br />
Welcome to DoYouWeekend! We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.doyouweekend.com or use our services. Please read this Privacy Policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Information We Collect</strong><br />
We may collect information about you in a variety of ways. The information we may collect on the Site includes:

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Personal Data</strong><br />
Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Derivative Data</strong><br />
Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Financial Data</strong><br />
Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, STRIPE, and you are encouraged to review their privacy policy and contact them directly for responses to your questions.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Data From Social Networks</strong><br />
User information from social networking sites, such as Facebook, Google+, Instagram, including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Mobile Device Data</strong><br />
Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Use of Your Information</strong><br />
Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
<ul>
<li>Create and manage your account.</li>
<li>Process your transactions and send you related information, including purchase confirmations and invoices.</li>
<li> Manage your orders and provide customer support.</li>
<li>Personalize and improve our services.</li>
<li>Develop new products, services, and features.</li>
<li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Site, and for marketing and promotional purposes.</li>
<li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
<li>Analyze trends and monitor usage and activities in connection with the Site</li>
<li>Comply with legal obligations and resolve any disputes we may have with any of our users and enforce our agreements.</li>
</ul>

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Disclosure of Your Information</strong><br />
We may share information we have collected about you in certain situations. Your information may be disclosed as follows:

<br />
<p className="text-start items-start flex justify-start m-0 mt-6 ">By Law or to Protect Rights</p><br />
If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.

<br />
<p className="text-start items-start flex justify-start m-0 mt-6 ">Third-Party Service Providers</p><br />
We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.

<br />
<p className="text-start items-start flex justify-start m-0 mt-6 ">Business Transfers</p><br />
We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.

<br />
<p className="text-start items-start flex justify-start m-0 mt-6 ">Affiliates</p><br />
We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.

<br />
<p className="text-start items-start flex justify-start m-0 mt-6 ">Other Third Parties</p><br />
We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Security of Your Information</strong><br />
We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Policy for Children</strong><br />
We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us at the support email provided below.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Changes to This Privacy Policy</strong><br />
We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.

<br />
<strong className="text-start items-start flex justify-start m-0 mt-6 ">Contact Us</strong><br />
If you have questions or comments about this Privacy Policy, please contact us at:<br />
<br />
DoYouWeekend<br />
7901 4TH ST N, STE 300<br />
ST. PETERSBURG, FL 33702 <br />
rich@doyouweekend.com<br />
+1 (518) 416-2744<br />

    </main>
    </div>
      <footer className="mt-auto"> {/* This will push the footer to the bottom */}
        <Footer
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>  
    </div>
  );
};

export default TermsOfService;
