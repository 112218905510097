import { FunctionComponent, useCallback, useState, useEffect, useRef } from "react";
import QuizBlock from "../../components/User/QuizBlock";
import Caroussel from "../../components/User/Caroussel";
import CarousselCloseBy from "../../components/User/CarousselCloseBy";
import Rounder from "../../components/General/Rounder";
import UserFooter from "../../components/User/UserFooter";
import Header from "../../components/User/Header";
import { useNavigate } from "react-router-dom";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import Map from "../../components/General/Map";
import MobileRounder from "../../components/Mobile/MobileRounder";
import BusinessRounder from "../../components/General/BusinessRounder";
import BusinessRounderMobile from "../../components/Mobile/BusinessRounderMobile";
import axios from 'axios';
import { useAxios } from "../../contexts/AxiosContext";
import Modal from "../../components/Merchant/Modal";
import CarouselContent from "../../components/General/CarouselContent";
import EmptyCarouselState from "../../components/General/EmptyCarouselState";
import MobileCarouselPagination from "../../components/Mobile/MobileCarouselPagination";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

//Ghetto Solution
const EXCLUDED_BUSINESS_NAMES = [
  "Test",
  "John's Pizza",
  "John‘s Pizza",
  "John's Pizzaa",
  "Leonid‘s biz",
  "Leonid's biz",
  "There the error is",
  "Unexpected Runtime Exception",
  "DoYouWeekend"
];

interface Offer {
  id: string;
  name: string;
  price: number;
  latitude: number;
  longitude: number;
  categories: string[];
  images: string[];
  promotionType?: string;
  voucherCap?: number;
  percentageDiscount?: number;
  minimumPurchaseAmount?: number;
  discountValue?: number;
}

interface ApiOffer {
  id: string;
  name: string;
  price: number;
  locations: {
    latitude: number;
    longitude: number;
  }[];
  categories: {
    name: string;
  }[];
  displayImageReferences: string[];
}

interface Business {
  id: string;
  businessName: string;
  categories: { id: string; name: string }[];
  description: string;
  profilePictureReference: string;
}


async function fetchPromotions(offerIds: string[]): Promise<any> {
  try {
    const token = localStorage.getItem('jwtToken');
    // if (!token) {
    //   throw new Error('No JWT token found');
    // }

    const response = await axios.get(`${API_BASE_URL}/v1/promotions/by-offers`, {
      params: { offerIds: offerIds.join(',') },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching promotions:', error);
    return [];
  }
}

const HomeScreen: FunctionComponent = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 1050;
  const [trendingOffers, setTrendingOffers] = useState<Offer[]>([]);
  const [dealsForYouOffers, setDealsForYouOffers] = useState<Offer[]>([]);
  const [closestToYouOffers, setClosestToYouOffers] = useState<Offer[]>([]);
  const [isLoadingTrending, setIsLoadingTrending] = useState<boolean>(true);
  const [isLoadingDealsForYou, setIsLoadingDealsForYou] = useState<boolean>(true);
  const [isLoadingClosestToYou, setIsLoadingClosestToYou] = useState<boolean>(true);
  const [trendingPage, setTrendingPage] = useState(0);
  const [dealsForYouPage, setDealsForYouPage] = useState(0);
  const [closestToYouPage, setClosestToYouPage] = useState(0);
  const [hasMoreTrending, setHasMoreTrending] = useState(true);
  const [hasMoreDealsForYou, setHasMoreDealsForYou] = useState(true);
  const [hasMoreClosestToYou, setHasMoreClosestToYou] = useState(true);
  const [currentDealsForYouPage, setCurrentDealsForYouPage] = useState(0);
  const [currentTrendingPage, setCurrentTrendingPage] = useState(0);
  const ITEMS_PER_PAGE = 10;
  const [errorTrending, setErrorTrending] = useState<string | null>(null);
  const [errorDealsForYou, setErrorDealsForYou] = useState<string | null>(null);
  const [errorClosestToYou, setErrorClosestToYou] = useState<string | null>(null);
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [isLoadingBusinesses, setIsLoadingBusinesses] = useState<boolean>(true);
  const [errorBusinesses, setErrorBusinesses] = useState<string | null>(null);
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [showSignupModal, setShowSignupModal] = useState(false);
  const modalTimerRef = useRef<NodeJS.Timeout>();
  const scrollDebounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  
  const handleCloseSignupModal = () => {
    setShowSignupModal(false);
    localStorage.setItem('signupModalDismissed', 'true');
  };

  // Add these helper functions
  const getPageItems = (items: any[], page: number) => {
    const start = page * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return items.slice(start, end);
  };

  const getScrollContainerStyle = (isLastPage: boolean) => {
    return {
      paddingRight: isLastPage ? '24px' : '100px' // Reduced padding when on last page
    };
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>, loadMore: () => Promise<void>) => {
    const container = event.currentTarget;
    const isNearEnd = container.scrollLeft + container.clientWidth >= container.scrollWidth - 100; // 100px threshold
  
    if (isNearEnd) {
      // Clear any existing timeout
      if (scrollDebounceTimeoutRef.current) {
        clearTimeout(scrollDebounceTimeoutRef.current);
      }
  
      // Set new timeout
      scrollDebounceTimeoutRef.current = setTimeout(() => {
        loadMore();
        scrollDebounceTimeoutRef.current = null;
      }, 500); // 500ms delay
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
  
    fetchData();
    fetchBusinesses();
  
    // Cleanup function
    return () => {
      if (scrollDebounceTimeoutRef.current) {
        clearTimeout(scrollDebounceTimeoutRef.current);
      }
    };
  }, []);

  const getTotalPages = (items: any[]) => Math.ceil(items.length / ITEMS_PER_PAGE);

  const loadMoreTrending = async () => {
    try {
      const nextPage = trendingPage + 1;
      const newData = await fetchCategory('Trending', nextPage);
      if (newData.length === 0) {
        setHasMoreTrending(false);
        return;
      }
      setTrendingOffers([...trendingOffers, ...mapApiOffersToOffers(newData)]);
      setTrendingPage(nextPage);
    } catch (error) {
      console.error('Error loading more trending offers:', error);
    }
  };

  const loadMoreDealsForYou = async () => {
    try {
      const nextPage = dealsForYouPage + 1;
      const newData = await fetchCategory('DealsForYou', nextPage);
      if (newData.length === 0) {
        setHasMoreDealsForYou(false);
        return;
      }
      setDealsForYouOffers([...dealsForYouOffers, ...mapApiOffersToOffers(newData)]);
      setDealsForYouPage(nextPage);
    } catch (error) {
      console.error('Error loading more deals for you:', error);
    }
  };

  const loadMoreClosestToYou = async () => {
    try {
      const nextPage = closestToYouPage + 1;
      const newData = await fetchCategory('ClosestToYou', nextPage);
      if (newData.length === 0) {
        setHasMoreClosestToYou(false);
        return;
      }
      setClosestToYouOffers([...closestToYouOffers, ...mapApiOffersToOffers(newData)]);
      setClosestToYouPage(nextPage);
    } catch (error) {
      console.error('Error loading more closest to you offers:', error);
    }
  };

  const handleSignup = () => {
    handleCloseSignupModal();
    navigate('/signup');
  };

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('signupModalDismissed');
    const isLoggedIn = localStorage.getItem('jwtToken');
    
    if (!hasSeenModal && !isLoggedIn) {
      modalTimerRef.current = setTimeout(() => {
        setShowSignupModal(true);
      }, 7500);
    }

    return () => {
      if (modalTimerRef.current) {
        clearTimeout(modalTimerRef.current);
      }
    };
  }, []);

  async function fetchCategory(category: string, page: number = 0, limit: number = 10): Promise<any> {
    const defaultLocation = {
      //Fort Myers location
      latitude: 26.6406,
      longitude: -81.8723
    };
    
    if (!axiosInstance) {
      throw new Error('Axios instance not available');
    }
  
    const userLatitude = localStorage.getItem('userLatitude') || defaultLocation.latitude;
    const userLongitude = localStorage.getItem('userLongitude') || defaultLocation.longitude;
  
    try {
      switch (category) {
        case 'DealsForYou':
          if (localStorage.getItem('userType') == 'MERCHANT')
            return;
          const token = localStorage.getItem('jwtToken');
          if (!token) {
            throw new Error('No JWT token found');
          }
          const response = await axiosInstance.get(`${API_BASE_URL}/v1/recommendations/personalized?page=${page}&limit=${limit}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          return response.data.content;
  
        case 'Trending':
          const trendingResponse = await axios.post(
            `${API_BASE_URL}/v1/recommendations/trending?page=${page}&limit=${limit}`,
            {
              location: {
                latitude: userLatitude,
                longitude: userLongitude
              }
            }
          );
          console.log(trendingResponse.data.content);
          return trendingResponse.data.content;
          
        case 'ClosestToYou':
          const nearbyResponse = await axios.post(
            `${API_BASE_URL}/v1/recommendations/nearby?page=${page}&limit=${limit}`,
            {
              latitude: userLatitude,
              longitude: userLongitude
            }
          );
          return nearbyResponse.data.content;
  
        default:
          throw new Error('Invalid category');
      }
    } catch (error) {
      console.error('Error fetching category data:', error);
      throw error;
    }
  }

  async function fetchData() {
    try {
      setIsLoadingTrending(true);
      setIsLoadingClosestToYou(true);
      setErrorTrending(null);
      setErrorClosestToYou(null);
  
      const [trending, closestToYou] = await Promise.all([
        fetchCategory('Trending'),
        fetchCategory('ClosestToYou')
      ]);
  
      let mappedTrending = mapApiOffersToOffers(trending);
      let mappedClosestToYou = mapApiOffersToOffers(closestToYou);
  
      const allOfferIds = [...mappedTrending, ...mappedClosestToYou].map(offer => offer.id);
      const promotions = await fetchPromotions(allOfferIds);
  
      mappedTrending = applyPromotionsToOffers(mappedTrending, promotions);
      mappedClosestToYou = applyPromotionsToOffers(mappedClosestToYou, promotions);
  
      setTrendingOffers(mappedTrending);
      setClosestToYouOffers(mappedClosestToYou);
  
      const jwtToken = localStorage.getItem("jwtToken");
      if (jwtToken) {
        setIsLoadingDealsForYou(true);
        setErrorDealsForYou(null);
        try {
          const dealsForYou = await fetchCategory('DealsForYou');
          let mappedDealsForYou = mapApiOffersToOffers(dealsForYou);
          mappedDealsForYou = applyPromotionsToOffers(mappedDealsForYou, promotions);
          setDealsForYouOffers(mappedDealsForYou);
        } catch (error) {
          console.error('Error fetching deals for you:', error);
          if (error instanceof Error) {
            setErrorDealsForYou(error.message);
          }
        } finally {
          setIsLoadingDealsForYou(false);
        }
      }
  
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error instanceof Error) {
        if (error.message.includes('Trending')) {
          setErrorTrending(error.message);
        } else if (error.message.includes('ClosestToYou')) {
          setErrorClosestToYou(error.message);
        }
      }
    } finally {
      setIsLoadingTrending(false);
      setIsLoadingClosestToYou(false);
    }
  }

  async function fetchBusinesses() {
    try {
      setIsLoadingBusinesses(true);
      setErrorBusinesses(null);
      const response = await axios.get(`${API_BASE_URL}/v1/merchants/recent`);
      const mappedBusinesses = response.data.content
        .filter((business: Business) => !EXCLUDED_BUSINESS_NAMES.includes(business.businessName))
        .map((business: any) => ({
          id: business.id,
          businessName: business.businessName,
          categories: business.categories,
          description: business.description,
          profilePictureReference: business.profilePictureReference,
        }));
      setBusinesses(mappedBusinesses);
    } catch (error) {
      console.error('Error fetching businesses:', error);
      if (error instanceof Error) {
        setErrorBusinesses(error.message);
      }
    } finally {
      setIsLoadingBusinesses(false);
    }
  }

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
  
    fetchData();
    fetchBusinesses();
  }, []);

  const mapApiOffersToOffers = (apiOffers: ApiOffer[]): Offer[] => {
    return apiOffers.map(apiOffer => ({
      id: apiOffer.id,
      name: apiOffer.name,
      images: apiOffer.displayImageReferences,
      price: apiOffer.price,
      latitude: apiOffer.locations?.[0]?.latitude ?? 0,
      longitude: apiOffer.locations?.[0]?.longitude ?? 0,
      categories: apiOffer.categories?.map(cat => cat.name) ?? [],
      // merchantId: apiOffer.merchantId,
      // description: apiOffer.description,
      // additionalInfo: apiOffer.additionalInfo,
      // currency: apiOffer.currency,
      // stock: apiOffer.stock,
      // hasUnlimitedStock: apiOffer.hasUnlimitedStock,
      // enabled: apiOffer.enabled,
      // master: apiOffer.master,
    }));
  };

  function applyPromotionsToOffers(offers: Offer[], promotions: any[]): Offer[] {
    return offers.map(offer => {
      const promotion = promotions.find(promo => promo.offerIds.includes(offer.id));
      if (promotion) {
        return {
          ...offer,
          promotionType: promotion.type,
          percentageDiscount: promotion.type === 'PERCENTAGE_DISCOUNT' ? promotion.discountValue : undefined,
          minimumPurchaseAmount: promotion.type === 'BUY_X_GET_Y_FREE' ? promotion.minimumPurchaseAmount : undefined,
          discountValue: promotion.type === 'BUY_X_GET_Y_FREE' ? promotion.discountValue : undefined,
        };
      }
      return offer;
    });
  }

  const renderOfferRounders = (offers: Offer[]) => (
    offers.map((offer) => (
      <Rounder
        key={`offer-${offer.id}`}
        id={offer.id}
        images={offer.images}
        name={offer.name}
        categories={offer.categories}
        price={offer.price}
        longitude={offer.longitude}
        latitude={offer.latitude}
        promotionType={offer.promotionType}
        percentageDiscount={offer.percentageDiscount}
        buy_x={offer.minimumPurchaseAmount}
        get_y={offer.discountValue}
      />
    ))
  );

  const renderBusinessRounders = (businesses: Business[]) => (
    businesses
      .filter(business => !EXCLUDED_BUSINESS_NAMES.includes(business.businessName))
      .map((business) => (
        <BusinessRounder
          key={`business-${business.id}`}
          id={business.id}
          businessName={business.businessName}
          categories={business.categories}
          description={business.description}
          profilePictureReference={business.profilePictureReference}
        />
      ))
  );

  const onFilterClick = () => {
    navigate("/map");
  };

  const userLatitude = parseFloat(localStorage.getItem('userLatitude') || "26.6406");
  const userLongitude = parseFloat(localStorage.getItem('userLongitude') || "-81.8723");

  return (
    <>
      <Modal
        isOpen={showSignupModal}
        onClose={handleCloseSignupModal}
        onAccept={handleSignup}
        title="Get Amazing Local Deals!"
        primaryButtonText="Sign Up - It's Free!"
        secondaryButtonText="Maybe Later"
      >
        <div className="px-1 py-2 sm:px-2 font-subhead">
          <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">Don't miss out on the best local deals in your area!</h3>
        
          <div className="mb-6">
            <h4 className="font-bold text-lg mb-3">Sign up to unlock:</h4>
            <ul className="space-y-2.5">
              {[
                "Hand-picked local deals you'll love",
                "Exclusive offers not found anywhere else",
                "Fun weekend activities at great prices",
                "Personalized deal alerts just for you"
              ].map((benefit, index) => (
                <li key={index} className="flex items-center">
                  <span className="text-main-teal text-xl mr-2">•</span>
                  <span className="text-gray-800">{benefit}</span>
                </li>
              ))}
            </ul>
          </div>

          <p className="text-main-teal font-bold text-lg text-center">Join now and start saving on local experiences!</p>
        </div>
      </Modal>

      {!isMobile ? (
        // Desktop View
        <div className="hidden mq1050:flex w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
          <Header />
          <div className=""><QuizBlock /></div>
          
          {localStorage.getItem("jwtToken") && localStorage.getItem("userType") == "CUSTOMER" && (
            <section className="self-stretch bg-white-100 flex flex-row items-center justify-center relative max-w-full">
              <CarouselContent
                isLoading={isLoadingDealsForYou}
                error={errorDealsForYou}
                data={dealsForYouOffers}
                emptyStateType="personalized"
                renderItems={(offers) => (
                  <Caroussel
                    title="Deals For You"
                    filterButton={true}
                    activities={renderOfferRounders(dealsForYouOffers)}
                    onLoadMore={loadMoreDealsForYou}
                    hasMore={hasMoreDealsForYou}
                  />
                )}
              />
            </section>
          )}

          <section className="self-stretch bg-zinc-100 flex flex-row items-center justify-center relative max-w-full">
            <CarouselContent
              isLoading={isLoadingTrending}
              error={errorTrending}
              data={trendingOffers}
              emptyStateType="trending"
              renderItems={(offers) => (
                <Caroussel
                  title="Trending Deals"
                  activities={renderOfferRounders(trendingOffers)}
                  onLoadMore={loadMoreTrending}
                  hasMore={hasMoreTrending}
                />
              )}
            />
          </section>

          <section className="hidden self-stretch bg-white-100 flex flex-row items-center justify-center relative max-w-full">
            <CarouselContent
              isLoading={isLoadingTrending}
              error={errorTrending}
              data={trendingOffers.filter(offer => offer.categories.some(category => 
                category.toLowerCase().includes('restaurant')
              ))}
              emptyStateType="restaurants"
              renderItems={(offers) => (
                <Caroussel
                  title="Eat"
                  activities={renderOfferRounders(offers)}
                />
              )}
            />
          </section>

          <section className="self-stretch bg-zinc-100 flex flex-row items-center justify-center relative max-w-full">
            <CarouselContent
              isLoading={isLoadingBusinesses}
              error={errorBusinesses}
              data={businesses}
              emptyStateType="businesses"
              renderItems={(businesses) => (
                <Caroussel
                  title="Businesses"
                  activities={renderBusinessRounders(businesses)}
                />
              )}
            />
          </section>

          <section className="self-stretch pb-20 bg-white-100 flex flex-row items-center justify-center relative max-w-full">
            <div className="text-29xl text-main-teal font-subhead flex flex-col items-center justify-center gap-[0px] mq750:gap-[22px_45px]">
              <h1 className="leading-[72px] mb-3 text-29xl text-main-teal font-subhead inline-block mq750:text-19xl mq750:leading-[58px] mq450:text-10xl mq450:leading-[43px]">
                Closest to You
              </h1>
              <div className="w-[95%] min-w-[75vw] -mb-16 rounded-lg md:w-3/4 xl:w-1/2 h-auto mb-8 rounded-lg">
                <Map 
                  setHeight="30vw" 
                  setZoom={11} 
                  offers={closestToYouOffers} 
                  centerLat={userLatitude}
                  centerLng={userLongitude}
                />
              </div>
              <CarouselContent
                isLoading={isLoadingClosestToYou}
                error={errorClosestToYou}
                data={closestToYouOffers}
                emptyStateType="nearby"
                renderItems={(offers) => (
                  <Caroussel
                    activities={renderOfferRounders(closestToYouOffers)}
                    onLoadMore={loadMoreClosestToYou}
                    hasMore={hasMoreClosestToYou}
                  />
                )}
              />
            </div>
          </section>

          <section className="hidden self-stretch pb-20 bg-white-100 flex flex-row items-center justify-center relative max-w-full">
            <CarouselContent
              isLoading={isLoadingTrending}
              error={errorTrending}
              data={trendingOffers.filter(offer => offer.categories.includes("Nightlife"))}
              emptyStateType="events"
              renderItems={(offers) => (
                <Caroussel
                  title="Events"
                  activities={renderOfferRounders(offers)}
                />
              )}
            />
          </section>

          <div className="flex flex-col">
            <footer className="mt-auto">
              <UserFooter
                dYWLogoW1="/dyw-logow-1@2x.png"
                propAlignSelf="stretch"
                propPosition="unset"
                propTop="unset"
                propLeft="unset"
                propWidth="unset"
              />
            </footer>
          </div>
        </div>
      ) : (
        // Mobile View
        <div className="mq1050:hidden w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead pt-[73px] mq780:pt-0">
          <Header />
          <div className="pt-0 bg-white"><QuizBlock /></div>
          
          {localStorage.getItem("jwtToken") && localStorage.getItem("userType") == "CUSTOMER" && (
            <section className="pb-30 self-stretch bg-white-100 flex flex-col items-start justify-start relative max-w-full">
              <div className="flex flex-row justify-between w-full">
                <p className="pl-6 font-bold font-subhead m-0 py-4 text-10xl">Deals for You</p>
                <button onClick={onFilterClick} className="cursor-pointer [border:none] p-0 bg-[transparent] flex items-center justify-center pr-4">
                  <div className="rounded-lg bg-white flex items-center justify-center py-[7px] px-[10px] border-[1px] border-solid border-steelblue">
                    <div className="flex-1 relative text-base tracking-[-0.01em] leading-[15px] font-medium text-main-teal text-center">
                      View All
                    </div>
                  </div>
                </button>
              </div>
              {isLoadingDealsForYou ? (
                <div className="w-full h-[200px] flex items-center justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
                </div>
              ) : errorDealsForYou ? (
                <div className="w-full h-[200px] flex items-center justify-center text-gray-600">
                  {errorDealsForYou}
                </div>
              ) : dealsForYouOffers.length === 0 ? (
                <EmptyCarouselState type="personalized" />
              ) : (
                <>
                  <div 
                    className="w-full min-h-[300px] px-6 overflow-x-scroll overflow-y-hidden snap-x snap-mandatory scrollbar-hide"
                    onScroll={(e) => hasMoreDealsForYou && handleScroll(e, loadMoreDealsForYou)}
                  >
                    <div 
                      className="flex flex-nowrap gap-4 min-w-max"
                      style={getScrollContainerStyle(!hasMoreDealsForYou)}
                    >
                      {dealsForYouOffers.map((offer) => (
                        <MobileRounder
                          key={`forYou-${offer.id}`}
                          {...offer}
                        />
                      ))}
                      {isLoadingDealsForYou && (
                        <div className="w-[285px] h-full flex items-center justify-center">
                          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </section>
          )}

          <section className="pb-30 self-stretch bg-zinc-100 flex flex-col items-start justify-start relative max-w-full">
            <p className="pl-6 font-bold font-subhead m-0 py-4 text-10xl">Trending</p>
            {isLoadingTrending ? (
              <div className="w-full h-[200px] flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
              </div>
            ) : errorTrending ? (
              <div className="w-full h-[200px] flex items-center justify-center text-gray-600">
                {errorTrending}
              </div>
            ) : trendingOffers.length === 0 ? (
              <EmptyCarouselState type="trending" />
            ) : (
              <>
                <div 
                  className="w-full min-h-[300px] px-6 overflow-x-scroll overflow-y-hidden snap-x snap-mandatory scrollbar-hide"
                  onScroll={(e) => hasMoreTrending && handleScroll(e, loadMoreTrending)}
                >
                  <div 
                    className="flex flex-nowrap gap-4 min-w-max"
                    style={getScrollContainerStyle(!hasMoreTrending)}
                  >
                    {trendingOffers.map((offer) => (
                      <MobileRounder
                        key={`trending-${offer.id}`}
                        {...offer}
                      />
                    ))}
                    {isLoadingTrending && (
                      <div className="w-[285px] h-full flex items-center justify-center">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </section>

          <section className="hidden pb-30 self-stretch bg-white flex flex-col items-start justify-start relative max-w-full">
            <p className="pl-6 font-bold font-subhead m-0 py-4 text-10xl">Eat</p>
            {isLoadingTrending ? (
              <div className="w-full h-[200px] flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
              </div>
            ) : errorTrending ? (
              <div className="w-full h-[200px] flex items-center justify-center text-gray-600">
                {errorTrending}
              </div>
            ) : trendingOffers.filter(offer => offer.categories.includes("Restaurant")).length === 0 ? (
              <EmptyCarouselState type="events" />
            ) : (
              <div 
                className="w-full min-h-[300px] px-6 overflow-x-scroll overflow-y-hidden snap-x snap-mandatory scrollbar-hide"
                onScroll={(e) => hasMoreTrending && handleScroll(e, loadMoreTrending)}
              >
                <div 
                  className="flex flex-nowrap gap-4 min-w-max"
                  style={getScrollContainerStyle(!hasMoreTrending)}
                >
                  {trendingOffers
                    .filter(offer => offer.categories.includes("Restaurant"))
                    .map((offer) => (
                      <MobileRounder
                        key={`restaurant-${offer.id}`}
                        {...offer}
                      />
                  ))}
                  {isLoadingTrending && (
                    <div className="w-[285px] h-full flex items-center justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </section>

          <section className="pb-30 self-stretch bg-zinc-100 flex flex-col items-start justify-start relative max-w-full">
            <p className="pl-6 font-bold font-subhead m-0 py-4 text-10xl">Businesses</p>
            {isLoadingBusinesses ? (
              <div className="w-full h-[200px] flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
              </div>
            ) : errorBusinesses ? (
              <div className="w-full h-[200px] flex items-center justify-center text-gray-600">
                {errorBusinesses}
              </div>
            ) : businesses.length === 0 ? (
              <EmptyCarouselState type="businesses" />
            ) : (
              <div className="w-full px-6 pb-10 overflow-x-scroll snap-x snap-mandatory scrollbar-hide">
                <div className="flex flex-nowrap gap-4 pr-12 min-w-max">
                  {businesses
                    .filter(business => !EXCLUDED_BUSINESS_NAMES.includes(business.businessName))
                    .map((business) => (
                      <BusinessRounderMobile
                        key={`business-mobile-${business.id}`}
                        {...business}
                      />
                    ))}
                </div>
              </div>
            )}
          </section>

          <section className="pb-0 mb-4 self-stretch bg-white flex flex-col items-start justify-start relative max-w-full">
            <p className="pl-6 font-bold font-subhead m-0 pt-4 text-10xl">Close to You</p>
          </section>

          <div className="mx-5 rounded-full w-auto bg-white -mt-3 mb-2">
            <Map offers={closestToYouOffers} setZoom={10} setHeight="250px"/>
          </div>

          <section>
            {isLoadingClosestToYou ? (
              <div className="w-full h-[200px] flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
              </div>
            ) : errorClosestToYou ? (
              <div className="w-full h-[200px] flex items-center justify-center text-gray-600">
                {errorClosestToYou}
              </div>
            ) : closestToYouOffers.length === 0 ? (
              <EmptyCarouselState type="nearby" />
            ) : (
              <div 
                className="w-full min-h-[300px] px-6 overflow-x-scroll overflow-y-hidden snap-x snap-mandatory scrollbar-hide"
                onScroll={(e) => hasMoreClosestToYou && handleScroll(e, loadMoreClosestToYou)}
              >
                <div 
                  className="flex flex-nowrap gap-4 min-w-max"
                  style={getScrollContainerStyle(!hasMoreClosestToYou)}
                >
                  {closestToYouOffers.map((offer) => (
                    <MobileRounder
                      key={`nearby-${offer.id}`}
                      {...offer}
                    />
                  ))}
                  {isLoadingClosestToYou && (
                    <div className="w-[285px] h-full flex items-center justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </section>

          <section className="hidden pb-30 self-stretch bg-white-100 flex flex-col items-start justify-start relative max-w-full">
            <p className="pl-6 font-bold font-subhead m-0 py-4 text-10xl">Events</p>
            {isLoadingTrending ? (
              <div className="w-full h-[200px] flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
              </div>
            ) : errorTrending ? (
              <div className="w-full h-[200px] flex items-center justify-center text-gray-600">
                {errorTrending}
              </div>
            ) : trendingOffers.filter(offer => offer.categories.includes("Nightlife")).length === 0 ? (
              <EmptyCarouselState type="events" />
            ) : (
              <div 
                className="w-full min-h-[300px] px-6 overflow-x-scroll overflow-y-hidden snap-x snap-mandatory scrollbar-hide"
                onScroll={(e) => hasMoreTrending && handleScroll(e, loadMoreTrending)}
              >
                <div 
                  className="flex flex-nowrap gap-4 min-w-max"
                  style={getScrollContainerStyle(!hasMoreTrending)}
                >
                  {trendingOffers
                    .filter(offer => offer.categories.includes("Nightlife"))
                    .map((offer) => (
                      <MobileRounder
                        key={`event-${offer.id}`}
                        {...offer}
                      />
                  ))}
                  {isLoadingTrending && (
                    <div className="w-[285px] h-full flex items-center justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </section>

          <footer className="mt-auto">
            <UserFooter
              dYWLogoW1="/dyw-logow-1@2x.png"
              propAlignSelf="stretch"
              propPosition="unset"
              propTop="unset"
              propLeft="unset"
              propWidth="unset"
            />
          </footer>
        </div>
      )}
    </>
  );
};

export default HomeScreen;
