import React, { useState } from 'react';

interface AppleToggleProps {
  initialState?: boolean;
  onToggle?: (isOn: boolean) => void;
}

const AppleToggle: React.FC<AppleToggleProps> = ({ initialState = false, onToggle }) => {
  const [isOn, setIsOn] = useState<boolean>(initialState);

  const handleToggle = () => {
    const newState = !isOn;
    setIsOn(newState);
    if (onToggle) {
      onToggle(newState);
    }
  };

  return (
    <div 
      onClick={handleToggle}
      className={`w-12 h-5.5 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ease-in-out ${
        isOn ? 'bg-blue-500' : 'bg-zinc-400'
      }`}
    >
      <div
        className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
          isOn ? 'translate-x-6' : 'translate-x-0'
        }`}
      />
    </div>
  );
};

export default AppleToggle;