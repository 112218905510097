import { FunctionComponent, useState, useRef, useEffect } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface BookingItemProps {
  offerID: string,
  orderDate: string;
  title: string;
  image: string;
  description: string;
  isMobile?: boolean;
}

const BookingItem: FunctionComponent<BookingItemProps> = ({
  offerID,
  orderDate,
  title,
  image,
  description,
  isMobile = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  
  const navigate = useNavigate();

  const toggleExpand = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && descriptionRef.current) {
        const currentContainerWidth = containerRef.current.offsetWidth;
        setContainerWidth(currentContainerWidth);
        const descriptionWidth = descriptionRef.current.scrollWidth;
        const descriptionHeight = descriptionRef.current.scrollHeight;
        if (isMobile) {
          setShowExpandButton(descriptionHeight > 40);
        } else {
          setShowExpandButton(descriptionWidth > currentContainerWidth * 0.6); // Adjust this value as needed
        }
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [description, isMobile]);

  const onViewDetailsClick = () => {
    navigate(`/offer/${offerID}`);
  };

  const imageClassName = `rounded-lg ${
    isMobile || containerWidth < 530 
      ? 'w-full mb-4 min-h-[130px]' 
      : 'w-[min(224px,40%)] mr-6 min-h-[130px]'
  }`;

  return (
    <div ref={containerRef} className={`font-subhead border-b border-gray-200 ${isMobile ? 'py-4 px-4' : 'py-6'}`}>
      <p className={`hidden text-gray-600 ${isMobile ? "text-xl" : 'text-2xl'} mb-2`}>
        Order Placed {orderDate}
      </p>
      <div className={`flex ${isMobile || containerWidth < 530 ? 'flex-col' : 'flex-row'} items-start`}>
        <img
          src={image}
          alt={title}
          className={imageClassName}
          crossOrigin="anonymous"
        />
        <div className="flex-1">
          <h3 className={`font-semibold ${isMobile ? 'text-lg' : 'text-xl'} mb-2`}>{title}</h3>
          <p ref={descriptionRef} className={`text-gray-700 ${isMobile ? 'text-sm' : 'text-base'} mb-4`}>
            {showExpandButton && !isExpanded ? `${description.slice(0, 100)}...` : description}
          </p>
          {showExpandButton && (
            <button
              onClick={toggleExpand}
              className="flex items-center bg-white ml-0 pl-0 mb-2 text-[#478abf] font-semibold"
            >
              {isExpanded ? (
                <>
                  Show Less <ChevronUp className="ml-1 w-4 h-4" />
                </>
              ) : (
                <>
                  Show More <ChevronDown className="ml-1 w-4 h-4" />
                </>
              )}
            </button>
          )}
          <button onClick={onViewDetailsClick} className="cursor-pointer bg-[#478abf] text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors">
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingItem;