import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, Circle, CircleDot } from "lucide-react";
import { FullScreenImagePreview } from "../General/FullScreenImagePreview";

type ImageSliderMobileProps = {
  images: {
    url: string;
    alt?: string;
  }[];
  onImageLoad?: () => void;
  className?: string;
};

export function ImageSliderMobile({ images, onImageLoad, className }: ImageSliderMobileProps) {
  const [imageIndex, setImageIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState<boolean[]>(new Array(images.length).fill(false));
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  useEffect(() => {
    images.forEach((image, index) => {
      const img = new Image();
      img.src = image.url;
      img.onload = () => {
        setImagesLoaded(prev => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
        if (index === 0 && onImageLoad) onImageLoad();
      };
    });
  }, [images, onImageLoad]);

  function showNextImage() {
    setImageIndex((index) => (index === images.length - 1 ? 0 : index + 1));
  }

  function showPrevImage() {
    setImageIndex((index) => (index === 0 ? images.length - 1 : index - 1));
  }

  if (!imagesLoaded.some(Boolean)) {
    return null; // Return nothing if no images are loaded
  }

  return (
    <>
      <div aria-label="Image Slider" className="relative rounded-sm w-screen h-[75vw]">
        <div className={`flex w-screen h-[75vw] ${className}`}>
          {images.map(({ url, alt }, index) => (
            imagesLoaded[index] && (
              <div key={url} className="relative w-full h-full flex-shrink-0 flex-grow-0">
                <img
                  src={url}
                  alt={alt}
                  aria-hidden={imageIndex !== index}
                  className="img-slider-img object-cover w-full h-full absolute top-0 left-0 block transition-all duration-300 ease-in-out cursor-pointer"
                  style={{ transform: `translateX(${-100 * imageIndex}%)` }}
                  onClick={() => setPreviewImage(url)}
                  //crossOrigin="anonymous"
                />
              </div>
            )
          ))}
        </div>
        <button
          type="button"
          onClick={showPrevImage}
          className="absolute top-0 bottom-0 -left-2 p-4 cursor-pointer transition-bg duration-100 ease-in-out bg-transparent hover:bg-black/20 focus-visible:bg-black/20 w-1/6"
          aria-label="View Previous Image"
        >
          <ChevronLeft className="stroke-white w-12 h-12" aria-hidden />
        </button>
        <button
          type="button"
          onClick={showNextImage}
          className="absolute top-0 bottom-0 right-0 p-4 cursor-pointer transition-bg duration-100 ease-in-out bg-transparent hover:bg-black/20 focus-visible:bg-black/20 w-1/6"
          aria-label="View Next Image"
        >
          <ChevronRight className="stroke-white w-12 h-12" aria-hidden />
        </button>
        <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-1">
          {images.map((_, index) => (
            <button
              type="button"
              key={index}
              className="rounded-full img-slider-dot-btn cursor-pointer w-3 h-3 transition-transform duration-100 ease-in-out hover:scale-110 focus-visible:scale-110"
              aria-label={`View Image ${index + 1}`}
              onClick={() => setImageIndex(index)}
            >
              {index === imageIndex ? (
                <CircleDot className="stroke-white fill-white w-full h-full" aria-hidden />
              ) : (
                <Circle className="stroke-white w-full h-full" aria-hidden />
              )}
            </button>
          ))}
        </div>
      </div>
      {previewImage && (
        <FullScreenImagePreview
          imageUrl={previewImage}
          alt={images[imageIndex].alt}
          onClose={() => setPreviewImage(null)}
        />
      )}
    </>
  );
}