import React, { useState } from 'react';

const AccountInfoForm: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    password: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here
    // // console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full mt-8 font-subhead text-copy">
      <div className="mb-8 items-center justify-center text-start font-subhead">
        <label htmlFor="firstName" className="block text-base mb-2 font-semibold font-subhead">First Name</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          className="w-full p-2 border border-silver-100 rounded"
        />
      </div>

      <div className="mb-4 items-center justify-center text-start font-subhead ">
        <label htmlFor="lastName" className="block text-base mb-2 font-semibold font-subhead font-semibold font-subhead">Last Name</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          className="w-full p-2 border border-silver-100 rounded"
        />
      </div>

      <div className="mb-8 items-center justify-center text-start font-subhead">
        <label htmlFor="email" className="block text-base mb-2 font-semibold font-subhead">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full p-2 border border-silver-100 rounded"
        />
      </div>

      <div className="mb-8 items-center justify-center text-start font-subhead">
        <label htmlFor="streetAddress" className="block text-base mb-2 font-semibold font-subhead">Street Address</label>
        <input
          type="text"
          id="streetAddress"
          name="streetAddress"
          value={formData.streetAddress}
          onChange={handleChange}
          className="w-full p-2 border border-silver-100 rounded"
        />
      </div>

      <div className="mb-8 items-center justify-center text-start font-subhead">
        <label htmlFor="city" className="block text-base mb-2 font-semibold font-subhead">City</label>
        <input
          type="text"
          id="city"
          name="city"
          value={formData.city}
          onChange={handleChange}
          className="w-full p-2 border border-silver-100 rounded"
        />
      </div>

      <div className="mb-8 items-center justify-center text-start font-subhead">
        <label htmlFor="state" className="block text-base mb-2 font-semibold font-subhead">State</label>
        <select
          id="state"
          name="state"
          value={formData.state}
          onChange={handleChange}
          className="w-full p-2 border border-silver-100 rounded"
        >
          <option className="font-subhead text-base text-zinc-800 max-w-[60vw]" value="">Select State</option>
          <option className="font-subhead text-base text-zinc-800 max-w-[60vw]" value="Florida">Florida</option>
          {/* Add other state options here */}
        </select>
      </div>

      <div className="mb-8 items-center justify-center text-start font-subhead">
        <label htmlFor="zipCode" className="block text-base mb-2 font-semibold font-subhead">ZIP Code</label>
        <input
          type="text"
          id="zipCode"
          name="zipCode"
          value={formData.zipCode}
          onChange={handleChange}
          className="w-full p-2 border border-silver-100 rounded"
        />
      </div>

      <div className="mb-8 items-center justify-center text-start font-subhead">
        <label htmlFor="phoneNumber" className="block text-base mb-2 font-semibold font-subhead">Phone Number</label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          className="w-full p-2 border border-silver-100 rounded"
        />
      </div>

      <div className="mb-8 items-center justify-center text-start font-subhead">
        <label htmlFor="password" className="block text-base mb-2 font-semibold font-subhead">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          className="w-full p-2 border border-silver-100 rounded"
        />
      </div>

      <div className="mb-8 items-center justify-center text-start font-subhead">
        <a href="/reset-password" className="text-main-teal hover:underline">Reset your password</a>
      </div>

      <button type="submit" className="w-full bg-main-teal text-white p-3 text-lg font-subhead mt-8 mb-16 rounded hover:bg-darkslateblue">
        Submit
      </button>
    </form>
  );
};

export default AccountInfoForm;