import React, { useState } from 'react';

interface SliderProps {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
}

const Slider: React.FC<SliderProps> = ({ min, max, step, value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value));
  };

  return (
    <div className="relative w-full">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer"
        style={{ position: 'relative', zIndex: 1 }}
      />
      <div className="relative w-full flex items-center" style={{ marginTop: '-0.55rem' }}>
        <div className="absolute pt-0 left-0 h-2.5 bg-[#478abf] rounded-lg" style={{ width: `${((value - min) / (max - min)) * 100}%`, zIndex: 2 }}></div>
        <div
          className="absolute bottom-5 left-0 transform -translate-x-1/2 p-2 bg-white border border-gray-300 rounded shadow"
          style={{ left: `${((value - min) / (max - min)) * 100}%`, zIndex: 3 }}
        >
          {value}%
        </div>
      </div>
    </div>
  );
};

export default Slider;