import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface Highlight {
  id: string;
  name: string;
}

interface AccessibilityButtonsProps {
  selectedHighlightIds: string[];
  onHighlightsChange: (highlightIds: string[]) => void;
}

const AccessibilityButtons: React.FC<AccessibilityButtonsProps> = ({ selectedHighlightIds, onHighlightsChange }) => {
  const [highlights, setHighlights] = useState<Highlight[]>([]);

  useEffect(() => {
    const fetchHighlights = async () => {
      try {
        const response = await axios.get<Highlight[]>(`${API_BASE_URL}/v1/catalog/highlights`);
        setHighlights(response.data);
      } catch (error) {
        console.error('Failed to fetch highlights:', error);
      }
    };

    fetchHighlights();
  }, []);

  const toggleButton = (highlightId: string) => {
    if (selectedHighlightIds.includes(highlightId)) {
      onHighlightsChange(selectedHighlightIds.filter(id => id !== highlightId));
    } else {
      onHighlightsChange([...selectedHighlightIds, highlightId]);
    }
  };

  return (
    <div className="flex flex-wrap gap-4 w-inherit bg-white">
      {highlights.map(highlight => (
        <button
          key={highlight.id}
          type="button"
          onClick={() => toggleButton(highlight.id)}
          className={`px-4 py-2 cursor-pointer rounded-full border ${
            selectedHighlightIds.includes(highlight.id)
              ? 'bg-blue-100 text-blue-800 border-blue-300'
              : 'bg-white text-gray-800 border-gray-300'
          }`}
        >
          {highlight.name}
        </button>
      ))}
    </div>
  );
};

export default AccessibilityButtons;