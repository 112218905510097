import { FunctionComponent, useState, useEffect, useCallback } from "react";
import { ChevronLeft } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import SideNav from "../../components/User/SideNav";
import UserFooter from "../../components/User/UserFooter";
import Header from "../../components/User/Header";
import Rounder from "../../components/General/Rounder";
import MobileRounderDynamic from "../../components/Mobile/MobileRounderDynamic";
import Map from "../../components/General/Map";
import { ChevronDown, SlidersHorizontal } from "lucide-react";
import FilterDropdown from "../../components/Mobile/FilterDropdown";
import axios from 'axios';
import { isTemplateSpan } from "typescript";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';


interface Offer {
  id: string;
  name: string;
  images: string[];
  price: number;
  latitude: number;
  longitude: number;
  categories: string[];
  promotionType?: string;
  percentageDiscount?: number;
  minimumPurchaseAmount?: number;
  discountValue?: number;
}

export interface SearchParams {
  query?: string;
  categories?: string[];
  highlights?: string[];
  priceRange?: string;
  dealType?: string;
  startDate?: string;
  endDate?: string;
  latitude?: number;
  longitude?: number;
  page?: number;
  size?: number;
  maxDistance?: number;
}

async function fetchPromotions(offerIds: string[]): Promise<any> {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(`${API_BASE_URL}/v1/promotions/by-offers`, {
      params: { offerIds: offerIds.join(',') },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    // console.log('Promotions response:', response.data); // Add this line
    return response.data;
  } catch (error) {
    console.error('Error fetching promotions:', error);
    return []; // Return an empty array instead of throwing an error
  }
}

function applyPromotionsToOffers(offers: Offer[], promotions: any[]): Offer[] {
  return offers.map(offer => {
    const promotion = promotions.find(promo => promo.offerIds.includes(offer.id));
    if (promotion) {
      return {
        ...offer,
        promotionType: promotion.type,
        percentageDiscount: promotion.type === 'PERCENTAGE_DISCOUNT' ? promotion.discountValue : undefined,
        minimumPurchaseAmount: promotion.minimumPurchaseAmount,
        discountValue: promotion.discountValue,
      };
    }
    return offer;
  });
}

const searchOffers = async (params: SearchParams) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/recommendations/search`, {
      params: {
        query: params.query,
        categories: params.categories?.join(','),
        highlights: params.highlights?.join(','),
        priceRange: params.priceRange,
        dealType: params.dealType,
        startDate: params.startDate,
        endDate: params.endDate,
        latitude: localStorage.getItem('userLatitude') ? parseFloat(localStorage.getItem('userLatitude') || "") : params.latitude,
        longitude: localStorage.getItem('userLongitude') ? parseFloat(localStorage.getItem('userLongitude')  || "") : params.longitude,
        page: params.page || 0,
        size: 12, //TOOO CHANGE
        maxDistance: params.maxDistance,
      },
    });

    if (!response.data || !Array.isArray(response.data.content)) {
      throw new Error('Invalid response format');
    }

    // Map only the current page's offers
    let offers = response.data.content.map((apiOffer: any) => ({
      id: apiOffer.id,
      name: apiOffer.name,
      images: apiOffer.displayImageReferences,
      price: apiOffer.price,
      latitude: apiOffer.locations[0]?.latitude ?? 0,
      longitude: apiOffer.locations[0]?.longitude ?? 0,
      categories: apiOffer.categories.map((cat: any) => cat.name)
    }));

    // Fetch and apply promotions for only the current page
    const offerIds = offers.map((offer: any) => offer.id);
    const promotions = await fetchPromotions(offerIds);
    offers = applyPromotionsToOffers(offers, promotions);

    return [offers, response.data.totalElements ?? 0];
  } catch (error) {
    console.error('Error in searchOffers:', error);
    throw error;
  }
};

const H21SearchResultsMapOpen: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const OFFERS_SHOWN = 12;
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [offers, setOffers] = useState<Offer[]>([]);
  const [totalOffers, setTotalOfferAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sortOption, setSortOption] = useState('For Me');
  const [isMapVisible, setIsMapVisible] = useState(true);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [itemsPerRow, setItemsPerRow] = useState(2);
  const [itemsPerRowDesktop, setItemsPerRowDesktop] = useState(2);
  const [mapCenter, setMapCenter] = useState({ lat: 26.6406, lng: -81.8723 });
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    const userLocation = localStorage.getItem('userLocation') || "Fort Myers, FL";
    
    if(userLocation == "Orlando, FL")
      setMapCenter({ lat: 28.5383, lng: -81.3792 });
    else
      setMapCenter({ lat: 26.6406, lng: -81.8723 });
  }, [searchParams]);

  const handleSearch = useCallback(async (params: SearchParams) => {
    try {
      setIsLoading(true);
      setError(null);
      setPage(0); // Reset page
      setHasMore(true); // Reset hasMore
      
      const [result, totalOfferAmount] = await searchOffers({
        ...params,
        page: 0,
        size: OFFERS_SHOWN
      });
      
      setOffers(result);
      setTotalOfferAmount(totalOfferAmount);
      setHasMore(result.length === OFFERS_SHOWN);
    } catch (error) {
      console.error('Error searching offers:', error);
      setError('Failed to search offers. Please try again later.');
      if (error instanceof Error) {
        console.error('Error details:', error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const initialSearchParams: SearchParams = {
      query: queryParams.get('search') || undefined,
      categories: queryParams.get('categories')?.split(',') || undefined,
      highlights: queryParams.get('highlights')?.split(',') || undefined,
      priceRange: queryParams.get('priceRange') || undefined,
      dealType: queryParams.get('dealType') || undefined,
      startDate: queryParams.get('startDate') || undefined,
      endDate: queryParams.get('endDate') || undefined,
      latitude: queryParams.get('latitude') ? parseFloat(queryParams.get('latitude') || '26.6406') : 26.6406,
      longitude: queryParams.get('longitude') ? parseFloat(queryParams.get('longitude') || '-81.8723') : -81.8723,
      page: queryParams.get('page') ? parseInt(queryParams.get('page') || '0', 10) : 0,
      size: queryParams.get('size') ? parseInt(queryParams.get('size') || '20', 10) : 20,
      maxDistance: queryParams.get('maxDistance') ? parseInt(queryParams.get('maxDistance') || '100000') : undefined,
    };
    setSearchParams(initialSearchParams);
    handleSearch(initialSearchParams);
  }, [location.search, handleSearch]);

  const updateSearchParams = useCallback((newParams: Partial<SearchParams>) => {
    const updatedParams = { ...searchParams, ...newParams };
    setSearchParams(updatedParams);
  
    const queryParams = new URLSearchParams(location.search);
    Object.entries(updatedParams).forEach(([key, value]) => {
      if (value !== undefined && value !== '') {
        if (key === 'query') {
          queryParams.set('search', String(value));
        } else if (Array.isArray(value)) {
          queryParams.set(key, value.join(','));
        } else if (isDate(value)) {
          queryParams.set(key, value.toISOString());
        } else if (typeof value === 'number' || typeof value === 'boolean') {
          queryParams.set(key, value.toString());
        } else if (typeof value === 'string') {
          queryParams.set(key, value);
        }
      } else {
        queryParams.delete(key);
      }
    });
  
    navigate(`/map?${queryParams.toString()}`, { replace: true });
  }, [searchParams, location.search, navigate]);
  
  // Custom type guard to check if value is a Date object
  const isDate = (value: any): value is Date => {
    return value instanceof Date && !isNaN(value.getTime());
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 550) {
        setItemsPerRow(2);
      } else {
        setItemsPerRow(3);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1340) {
        setItemsPerRowDesktop(3);
      } else {
        setItemsPerRowDesktop(2);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleScroll = useCallback(async () => {
    // Get footer element and its height
    //const footer = document.getElementById('footer');
    const footerHeight = 700 //footer ? footer.offsetHeight : 610;
    
    // Calculate scroll positions accounting for footer
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight - footerHeight;
    
    // Adjust threshold to be 100px + footer height from bottom
    const scrollPosition = windowHeight + scrollY;
    const scrollThreshold = documentHeight - 100;
  
    if (
      scrollPosition >= scrollThreshold && 
      !isLoadingMore && 
      hasMore && 
      !isLoading
    ) {
      try {
        setIsLoadingMore(true);
        const nextPage = page + 1;
        const params = {
          ...searchParams,
          page: nextPage,
          size: OFFERS_SHOWN
        };
        
        const [newOffers, totalCount] = await searchOffers(params);
        
        if (newOffers.length === 0) {
          setHasMore(false);
        } else {
          setOffers(prevOffers => [...prevOffers, ...newOffers]);
          setPage(nextPage);
          setHasMore(newOffers.length === OFFERS_SHOWN);
        }
      } catch (error) {
        console.error('Error loading more offers:', error);
      } finally {
        setIsLoadingMore(false);
      }
    }
  }, [page, isLoadingMore, hasMore, isLoading, searchParams]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const onGoBackClick = () => {
    navigate("/");
  };

  const onNextClick = () => {
    navigate('/merchant-hub-deals');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFilterClick = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
  };

  const handleSortOptionClick = (option: string) => {
    setSortOption(option);
    setIsDropdownOpen(false);
  };

  const toggleMapVisibility = () => {
    setIsMapVisible(!isMapVisible);
  };

  const onExploreClick = () => {
    navigate("/m01-fyp");
  };
  const onLikesClick = () => {
    navigate("/m03-likes");
  };
  const onItinerariesClick = () => {
    navigate("/m04-itineraries");
  };

  
  interface SearchBarProps {
    placeholder: string;
    iconSrc: string;
    id?: string;
    onClick: () => void;
    initialQuery?: string;
    onSearch: (query: string) => void;  // Modified to accept the query
    searchParams: SearchParams;
    updateSearchParams: (params: Partial<SearchParams>) => void;
  }
  
  const SearchBar: React.FC<SearchBarProps> = ({ 
    placeholder, 
    iconSrc, 
    id, 
    onClick, 
    onSearch, 
    searchParams  }) => {
    const [query, setQuery] = useState<string>('');
  
    useEffect(() => {
      setQuery(searchParams.query || '');
    }, [searchParams.query]);
  
    function handleInputChange(text: string) {
      setQuery(text);
      // Don't update search params here
    }
  
    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSearch(query);  // Pass the current query to onSearch
      }
    }
  
    function handleSearchClick() {
      onSearch(query);  // Pass the current query to onSearch
    }
  
    return (
      <>
      <div className="flex-grow min-w-[150px] flex items-center mr-2 px-2 mt-1 bg-white rounded-lg border border-blue-900 border-solid">
        <img
          loading="lazy"
          src={iconSrc}
          alt="Search icon"
          className="shrink-0 w-6 h-6 mt-0 [border:none] [outline:none] cursor-pointer"
          onClick={handleSearchClick}
          crossOrigin="anonymous"
        />
        <input
          type="text"
          placeholder={placeholder}
          className="cursor-text [border:none] [outline:none] pt-1 flex-grow px-2 text-base leading-7 text-blue-900 bg-transparent outline-none min-w-[100px]"
          id={id}
          name="search"
          value={query}
          onChange={(e) => handleInputChange(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <SlidersHorizontal onClick={onClick} className="stroke-blue-900 w-6 h-6 mq1050:hidden" />
      </div>
      <button 
        type="button"
        className="cursor-pointer bg-orange-500 text-base text-white rounded-lg font-subhead font-semibold px-5 py-1 mt-1" 
        onClick={handleSearchClick}
      >
        Search
      </button>
    </>
    );
  };

  const handleSearchClick = useCallback((query: string) => {
    updateSearchParams({ query, page: 0 });
  }, [updateSearchParams]);

  return (
    <>
    {/* Desktop (>=780px) */}
    <div className="hidden mq1050:flex flex flex-col">
      <Header />
      <main className="flex-1 flex min-h-screen font-subhead flex-row">
      <div className="hidden mq1050:flex" >
        <SideNav updateSearchParams={updateSearchParams} />
      </div>
        <div className="flex flex-row p-8 mq780:w-[100vw]">
          <form className="flex flex-col font-subhead w-full mb-20 max-w-[605px] lg-1340:max-w-[950px]">
            <div className="flex flex-col pt-0 pb-3 w-full font-subhead mq780:hidden">
              <div className="flex flex-row justify-start mq780:hidden">
                <ChevronLeft onClick={onGoBackClick} className="cursor-pointer w-6 h-6 pt-2.5 pr-3 stroke-black" />
                <p className="bg-transparent mt-3 font-semibold">Home</p>
              </div>
            </div>
            <div className="m-0 font-semibold flex flex-row gap-2 text-subhead text-5xl text-blue-900 !text-left !items-start">
            <h3 className="m-0 pt-5 font-bold pb-1 text-19xl">{localStorage.getItem("userLocation") || "Fort Myers, FL"}</h3>
              <ChevronDown className="hidden mt-[20px] stroke-blue-900 w-12 h-12" />
            </div>
            <div className="flex w-full mr-3 justify-between flex-row gap-3 mb-5">
              <SearchBar 
                placeholder="Search" 
                iconSrc="/icon--search.svg" 
                id="search-bar" 
                onClick={handleFilterClick}
                onSearch={handleSearchClick}
                searchParams={searchParams}
                updateSearchParams={updateSearchParams}
              />
            </div>
            {isMapVisible && 
              <Map 
                setHeight="50vh" 
                setZoom={10} 
                offers={offers} 
                setLat={mapCenter.lat}
                setLng={mapCenter.lng}
              />}
            <div className="flex flex-row justify-between w-full pb-0">
              <p className="font-subhead m-0 mt-5 text-5xl whitespace-nowrap">{`${totalOffers} Results`}</p>
              <div className="flex flex-row py-3 content-end justify-end gap-6 relative">
                <div className="hidden relative"> 
                  {/* TODO */}
                  <button
                    type="button"
                    onClick={toggleDropdown}
                    className="w-auto cursor-pointer pl-3 pr-4 bg-white font-subhead pb-3 text-base flex flex-col items-start justify-start p-3 pt-3.5 px-0 box-border text-black border border-solid border-zinc-600 px-3 rounded-lg"
                  >
                    <div className="w-full flex flex-row items-center justify-between gap-[5px] z-[2]">
                    <span className="flex-1 whitespace-nowrap tracking-[-0.01em] leading-[15.6px] font-medium">{sortOption}</span>
                      <img
                        className="w-3 h-[5px] relative cursor-pointer"
                        alt=""
                        src="/vector-3.svg"
                        crossOrigin="anonymous"
                      />
                    </div>
                  </button>
                  {isDropdownOpen && (
                    <ul className="absolute left pb-2 w-[180px] mt-2 bg-white border border-gray-300 rounded-lg shadow-lg z-10 list-none p-0">
                      <li
                        className="py-2 px-4 whitespace-nowrap cursor-pointer text-sm hover:bg-gray-100"
                        onClick={() => handleSortOptionClick('For Me')}
                      >
                        For Me
                      </li>
                      <li
                        className="py-2 px-4 whitespace-nowrap cursor-pointer text-sm hover:bg-gray-100"
                        onClick={() => handleSortOptionClick('Lowest Price')}
                      >
                        Lowest Price
                      </li>
                      <li
                        className="py-2 px-4 whitespace-nowrap cursor-pointer text-sm hover:bg-gray-100"
                        onClick={() => handleSortOptionClick('Closest to me')}
                      >
                        Closest to me
                      </li>
                      <li
                        className="py-2 px-4 whitespace-nowrap cursor-pointer text-sm hover:bg-gray-100"
                        onClick={() => handleSortOptionClick('Biggest Discount')}
                      >
                        Biggest Discount
                      </li>
                    </ul>
                  )}
                </div>
                <button onClick={toggleMapVisibility} className="hidden cursor-pointer w-[120px] p-2 py-3 text-base font-medium tracking-tight hover:bg-blue-800 text-center text-white bg-blue-900 rounded-lg">
                  {isMapVisible ? 'Close Map' : 'Open Map'}
                </button>
              </div>
            </div>
            {isLoading ? (
              <div>Loading offers...</div>
            ) : error ? (
              <div>{error}</div>
            ) : (
              <section className="ml-1 pb-10 self-stretch bg-white flex flex-col items-center justify-center relative max-w-full text-center text-29xl text-main-teal font-subhead">
                <div className={`w-full flex flex-wrap ${itemsPerRowDesktop == 2 ? "gap-6" : "gap-10"} ${(offers.length % 3) - 2 == 0 ? "justify-start" : "justify-between"}`}>
                  {offers.map((offer) => (
                    <div key={offer.id} className="w-[285px]">
                      <Rounder
                        key={offer.id}
                        id={offer.id}
                        images={offer.images}
                        name={offer.name}
                        categories={offer.categories}
                        price={offer.price}
                        longitude={offer.longitude}
                        latitude={offer.latitude}
                        promotionType={offer.promotionType}
                        percentageDiscount={offer.percentageDiscount}
                        buy_x={offer.minimumPurchaseAmount}
                        get_y={offer.discountValue}
                      />
                    </div>
                  ))}
                </div>
                {isLoadingMore && (
                  <div className="w-full flex justify-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
                  </div>
                )}
                {!hasMore && offers.length > 0 && (
                  <div className="hidden w-full text-center py-4 mt-16 text-11xl text-gray-500">
                    No more offers to load
                  </div>
                )}
              </section>
            )}
            <div className="hidden flex justify-end mt-10 w-inherit">
              <button onClick={onNextClick} className="cursor-pointer w-[110px] mt-20 p-2 py-3 text-base font-medium tracking-tight hover:bg-blue-800 text-center text-white bg-blue-900 rounded-lg">
                Next
              </button>
            </div>
          </form>
        </div>
      </main>
      <footer id="footer" className="mt-auto">
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>
    </div>








    {/* Mobile (<780px) */}
    <div className="mq1050:hidden w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead pt-[73px] mq780:pt-0 mq1050:pt-[73px]">
      <Header />
      <div className="hidden gap-3 px-3 text-[12px] text-subhead flex flex-row justify-between">
        <div onClick={onExploreClick} className="cursor-pointer w-20">
          <h3>Explore</h3>
        </div>
        <div className="text-blue-900 cursor-pointer w-20 flex flex-col relative">
          <h3>Map</h3>
          <div className="h-[1px] w-full bg-blue-900 z-10" />
        </div>
        <div onClick={onLikesClick} className="cursor-pointer w-20">
          <h3>Likes</h3>
        </div>
        <div onClick={onItinerariesClick} className="cursor-pointer w-20">
          <h3>Itineraries</h3>
        </div>
      </div>
      <div className="mt-[-5px] w-full border border-solid bg-neutral-300 border-neutral-300 min-h-[0.1px] max-md:max-w-full" />
      
      {/* Filter Menu */}
      {isMobileFilterOpen && <div className="mq780:pt-[73px] overfloy-y-auto z-50 absolute -mt-[73px]">
        <FilterDropdown 
          closeFunction={handleFilterClick} 
          updateSearchParams={updateSearchParams}
          currentSearchParams={searchParams}  
        />
      </div>}        
      <section className="px-3 self-stretch bg-white flex flex-col items-start justify-center relative max-w-full text-left text-main-teal font-subhead">
        <div className="m-0 font-semibold flex flex-row gap-2 text-subhead text-5xl text-blue-900 !text-left !items-start">
          <h3 className="m-0 pt-5 font-bold pb-1">{localStorage.getItem("userLocation") || "Fort Myers, FL"}</h3>
          <ChevronDown className="hidden mt-[20px] stroke-blue-900 w-9 h-9" />
        </div>
        <div className="flex w-full mr-3 justify-between flex-row gap-3 mb-5">
          <SearchBar 
            placeholder="Search" 
            iconSrc="/icon--search.svg" 
            id="search-bar" 
            onClick={handleFilterClick}
            onSearch={handleSearchClick}
            searchParams={searchParams}
            updateSearchParams={updateSearchParams}
          />
        </div>
        <div className="w-full items-center justify-center rounded-lg">
          <div className="shadow-sm"><Map setHeight="45vh" setZoom={10} offers={offers} /></div>
          <div className="flex flex-row justify-between pt-4">
            <div className="m-0 ml-1 text-black text-thin text-subhead text-xl mt-1">
              {`${totalOffers} Results`}
            </div>
            <div className="flex flex-row items-start justify-start py-0 pl-0 box-border text-center text-mini text-black">
              <button
                type="button"
                onClick={toggleDropdown}
                className="hidden w-auto cursor-pointer px-3 bg-white font-subhead py-2 text-base flex flex-col items-start justify-start p-3 px-0 box-border text-black border border-solid border-zinc-600 px-3 rounded-lg"
              >
                <div className="w-full flex flex-row items-center justify-between gap-[5px] z-[2]">
                    <span className="flex-1 whitespace-nowrap tracking-[-0.01em] leading-[15.6px] font-medium">{sortOption}</span>
                      <img
                        className="w-3 h-[5px] relative cursor-pointer"
                        alt=""
                        src="/vector-3.svg"
                        crossOrigin="anonymous"
                      />
                    </div>
                  </button>
                  {isDropdownOpen && (
                    <ul className="absolute right-0 w-[160px] bg-white border border-gray-300 rounded-lg shadow-lg z-10 list-none pb-2 p-0 mt-10">
                      <li
                        className="py-2 px-4 whitespace-nowrap cursor-pointer text-sm text-center hover:bg-gray-100"
                        onClick={() => handleSortOptionClick('For Me')}
                      >
                        For Me
                      </li>
                      <li
                        className="py-2 px-4 whitespace-nowrap cursor-pointer text-sm text-center hover:bg-gray-100"
                        onClick={() => handleSortOptionClick('Lowest Price')}
                      >
                        Lowest Price
                      </li>
                      <li
                        className="py-2 px-4 whitespace-nowrap cursor-pointer text-sm text-center hover:bg-gray-100"
                        onClick={() => handleSortOptionClick('Closest to me')}
                      >
                        Closest to me
                      </li>
                      <li
                        className="py-2 px-4 whitespace-nowrap cursor-pointer text-sm text-center hover:bg-gray-100"
                        onClick={() => handleSortOptionClick('Biggest Discount')}
                      >
                        Biggest Discount
                      </li>
                    </ul>
                  )}
            </div>
          </div>
        </div>
      </section>
      <section className="pb-10 self-stretch bg-white flex flex-col items-center justify-center relative max-w-full text-center text-29xl text-main-teal font-subhead px-4">
        <div className="w-full flex flex-wrap justify-between gap-4">
          {offers.map((offer) => (
            <div key={offer.id} className="w-[calc(50%-10px)]">
              <MobileRounderDynamic
                key={offer.id}
                id={offer.id}
                images={offer.images}
                name={offer.name}
                categories={offer.categories}
                price={offer.price}
                longitude={offer.longitude}
                latitude={offer.latitude}
                promotionType={offer.promotionType}
                percentageDiscount={offer.percentageDiscount}
                buy_x={offer.minimumPurchaseAmount}
                get_y={offer.discountValue}
              />
            </div>
          ))}
        </div>
        {isLoadingMore && (
          <div className="w-full flex justify-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main-teal" />
          </div>
        )}
        {!hasMore && offers.length > 0 && (
          <div className="hidden w-full text-center text-11xl mt-10 py-4 text-gray-500">
            No more offers to load
          </div>
        )}
      </section>
      <footer id="footer" className="mt-auto">
        {/* This will push the footer to the bottom */}
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>
    </div>
    </>
  
  );
};

export default H21SearchResultsMapOpen;