import React, { useState, useRef, useEffect } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  title: string;
  children: React.ReactNode;
  requireScroll?: boolean;
  customButtons?: React.ReactNode;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  scrollThreshold?: number;
}

const Modal: React.FC<ModalProps> = ({ 
  isOpen, 
  onClose, 
  onAccept, 
  title, 
  children,
  requireScroll = false,
  customButtons,
  primaryButtonText = "Accept",
  secondaryButtonText = "Close",
  scrollThreshold = 50
}) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (!contentRef.current || !requireScroll) return;
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
    const isNearBottom = distanceFromBottom <= scrollThreshold;
    if (isNearBottom && !isScrolledToBottom) {
      setIsScrolledToBottom(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsScrolledToBottom(!requireScroll);
    }
  }, [isOpen, requireScroll]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] flex flex-col">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl sm:text-2xl font-bold text-main-teal font-subhead">{title}</h2>
        </div>
        
        <div ref={contentRef} onScroll={handleScroll} className="overflow-y-auto flex-grow mb-4" style={{ maxHeight: requireScroll ? 'calc(90vh - 200px)' : 'auto' }}>
          {children}
        </div>

        <div className="flex justify-end space-x-4">
          {customButtons || (
            <>
              <button onClick={onClose} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors duration-200">
                {secondaryButtonText}
              </button>
              <button onClick={onAccept} disabled={!isScrolledToBottom} className={`px-4 py-2 rounded transition-colors duration-200 ${isScrolledToBottom 
                    ? 'bg-main-teal text-white hover:bg-opacity-90 cursor-pointer' 
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
              >
                {primaryButtonText}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
