import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

interface DynamicTitleProps {
  getTitle: () => string;
  description: string;
  path: string;
  children: React.ReactNode;
}

const DynamicTitleWrapper: React.FC<DynamicTitleProps> = ({ getTitle, description, path, children }) => {
  useEffect(() => {
    document.title = getTitle();
  }, [getTitle]);

  const title = getTitle();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={`https://doyouweekend.com${path}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`https://doyouweekend.com${path}`} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      {children}
    </>
  );
};

export default DynamicTitleWrapper;