import React, { FunctionComponent, useState, useEffect } from "react";
import ErrorBoundary from "../../components/Merchant/ErrorBoundary";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import MerchantHubSideNav from "../../components/Merchant/MerchantHubSideNav";
import StripeDashboard from "../../components/Stripe/StripeDashboard";

const Analytics: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating loading time
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  return (
    <ErrorBoundary>
      <div className="flex flex-col">
        <MerchantHubHeader />
        <main className="flex-1 flex min-h-screen font-subhead flex-row">
          <MerchantHubSideNav selected="Analytics"/>

          {/* Main Content */}  
          {isLoading ? (
            <div className="font-subhead p-8 text-3xl">Loading...</div>
          ) : (
            <section className="p-8 w-full">
              <StripeDashboard />
            </section>
          )}
        </main>
        <footer className="mt-auto">
          <Footer
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>  
      </div>
    </ErrorBoundary>
  );
};

export default Analytics;