import { FunctionComponent, useCallback } from "react";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import MerchantPage3 from "../../components/Merchant/MerchantSignup/MerchantPage3";

const M03MerchantPage: FunctionComponent = () => {
  const onItemClick = useCallback(() => {
    // Please sync "H4.1 Details Page - K1 Speed" to the project
  }, []);

  const onQuickAddContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='background']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onArrowFrontClick = () => {
    //Add functionality
  };

  const onArrowBackClick = () => {
    //Add functionality
  };

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
      <MerchantHubHeader />
      <div className="flex items-center justify-center my-20">
      <MerchantPage3 />
      </div>
      <footer className="mt-auto"> {/* This will push the footer to the bottom */}
          <Footer
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>  
    </div>
  );
};

export default M03MerchantPage;
