import { FunctionComponent, useMemo, useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";

export type ItineraryItemMobileType = {
  id: string;  // Add this line
  title: string;
  description: string;
  image: string;
  dealpage?: string;
  onRemove: (offerId: string) => void;
  onBooking: () => void;  // Add this line
};

const ItineraryItemMobile: FunctionComponent<ItineraryItemMobileType> = ({
  id,  // Add this line
  title,
  description,
  image,
  dealpage="/",
  onRemove,
  onBooking,
}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isHoursDropdownOpen, setIsHoursDropdownOpen] = useState(false);
    const [isContactDropdownOpen, setContactDropdownOpen] = useState(false);
    const [isLiked, setIsLiked] = useState(false);

    const navigate = useNavigate();

    const handleLikeClick = () => {
      setIsLiked(!isLiked); // Toggle the isLiked state
    };

    const toggleHoursDropdown = () => {
        setIsHoursDropdownOpen(!isHoursDropdownOpen);
    };

    const toggleContactDropdown = () => {
        setContactDropdownOpen(!isContactDropdownOpen);
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const onBookNowClick = () => {
        navigate(dealpage);
    }

    const onNewItineraryClick = () => {
        navigate("/itineraries")
    }

    const truncateString = (str: string, num: number): string => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    const truncatedDescription = useMemo(() => truncateString(description, 65), [description]); //length of 240 fits all layouts

    const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onRemove(id);
  };

    return (
        <section className="w-full max-w-full py-2 h-auto bg-zinc-50">
            <div className="flex flex-row gap-2 justify-between">
                <img 
                    src={image}
                    className="w-[16vh] h-[12vh] items-center justify-center p-5"
                    crossOrigin="anonymous"
                />
                <div className="flex flex-col text-start w-[70vw] pr-5">
                  <div className="flex flex-row justify-between">
                    <h1 className="w-auto font-subhead text-xl text-semibold text-black m-0 mb-1 mt-5">{title}</h1>
                    <img
                      className="hidden mt-3 mx-1 h-9 w-9 cursor-pointer"
                      alt=""
                      src={isLiked ? "/icon--heart1.svg" : "/icon--heart.svg"}
                      onClick={handleLikeClick}
                      crossOrigin="anonymous"
                    />
                  </div>
                    <p className="font-subhead text-xxs text-zinc-900 mt-1 leading-[20px]">{truncatedDescription}
                        <a href="/" className="hidden text-blue-900 text-sm font-semibold whitespace-nowrap">View Details</a>
                    </p>
                </div>
            </div>
            <div className="flex flex-row mt-2 gap-3 justify-between items-start px-5">
                <button className="w-[50%] rounded-lg cursor-pointer [border:none] py-[11px] px-2 bg-main-teal flex flex-row items-center justify-center relative whitespace-nowrap z-[1]">
                    <div className="h-full w-full absolute !m-[0] top-[0px] right-[1px] bottom-[0px] left-[0px] rounded-md bg-main-teal box-border border-[1px] border-solid border-main-teal hover:border-blue-400" />
                    <b onClick={handleRemove} className="py-1 w-[167px] relative text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-zinc-100 text-center z-[1]">
                      Remove
                    </b>
                </button>
                <button 
                    className="w-[50%] cursor-pointer [border:none] py-[11px] px-2 bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap z-[1]"
                    onClick={() => navigate(`/offer/${id}`)}
                >
                    <div className="h-full w-full absolute !m-[0] top-[0px] right-[1px] bottom-[0px] left-[0px] rounded-md bg-white box-border border-[1px] border-solid border-main-teal" />
                    <b className="w-[167px] relative py-1 text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-main-teal hover:text-blue-800 text-center z-[1]">
                        View
                    </b>
                </button>
            </div>
            <div className="self-stretch h-1 box-border mt-7 shrink-0 border-t-[1px] border-solid border-zinc-200 pb-4" />
        </section>
    );
};

export default ItineraryItemMobile;
