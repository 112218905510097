import { FunctionComponent, useCallback } from "react";
import Header from "../../components/User/Header";
import Footer from "../../components/User/UserFooter";
import { useNavigate } from "react-router-dom";
import QuizComponent1 from "../../components/Quiz/QuizComponent1";

const H71QuizPage: FunctionComponent = () => {

  return (
    <> 
    <Header /> 
    <main className="font-subhead flex pt-16 items-center justify-center pt-[100px] mq780:pt-10">
    <QuizComponent1 />
    </main>
    <Footer
      dYWLogoW1="/dyw-logow-1@2x.png"
      propAlignSelf="stretch"
      propPosition="unset"
      propTop="unset"
      propLeft="unset"
      propWidth="unset"
    />
    </>
  );
};

export default H71QuizPage;
