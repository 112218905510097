import axios from 'axios';
import { DateTime } from 'luxon';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

export interface BusinessSignUpData {
  businessName: string;
  categoryIds: string[];
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  description: string;
  highlightIds: string[] | null;
  openingHours: {
    schedule: {
      [day: string]: {
        openTime?: DateTime | null;
        closeTime?: DateTime | null;
        closed: boolean;
      };
    };
  };
  recaptchaResponse: string;
  profilePicture?: string | null;
}

interface ProcessedBusinessSignUpData extends Omit<BusinessSignUpData, 'openingHours'> {
  openingHours: {
    schedule: {
      [day: string]: {
        openTime?: DateTime | null;
        closeTime?: DateTime | null;
        closed: boolean;
      };
    };
  };
}

export const signUpBusiness = async (data: BusinessSignUpData) => {
  try {
    const formData = new FormData();

    // Convert string times to DateTime objects, then to ISO strings
    const merchantData: ProcessedBusinessSignUpData = {
      ...data,
      openingHours: {
        schedule: Object.entries(data.openingHours.schedule).reduce((acc, [day, hours]) => {
          acc[day] = {
            ...hours,
            openTime: hours.openTime ? hours.openTime : null,
            closeTime: hours.closeTime ? hours.closeTime : null,
          };
          return acc;
        }, {} as ProcessedBusinessSignUpData['openingHours']['schedule']),
      },
    };
    delete merchantData.profilePicture;

    const merchantBlob = new Blob([JSON.stringify(merchantData)], {
      type: 'application/json'
    });
    formData.append('merchant', merchantBlob, 'merchant.json');

    // Append profilePicture if it exists
    if (data.profilePicture) {
      const base64Response = await fetch(data.profilePicture);
      const blob = await base64Response.blob();
      formData.append('profilePicture', blob, 'profile.jpg');
    }

    const response = await axios.post(`${API_BASE_URL}/v1/users/register/merchant`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    
    // // console.log("Signup response:", response.data);

    if (response.data && typeof response.data === 'string') {
      // // console.log("JWT received:", response.data);
      localStorage.setItem('jwtToken', response.data);
    } else {
      console.warn("No JWT token found in the response or response is not a string");
    }
    
    return response.data;
  } catch (error) {
    console.error('Error signing up business:', error);
    throw error;
  }
};