import React, { useState } from 'react';
import { Plus, Minus } from 'lucide-react';

interface FAQItemProps {
    question: string,
    answer: string
}

const FAQItem: React.FC<FAQItemProps> = ({
    question,
    answer
}) => {  

  const [isFAQopen, setFAQOpen] = useState(true);

  const toggleButton = () => {
    setFAQOpen(!isFAQopen);
  };

  return (
    <div className={`h-auto flex flex-col bg-white rounded-lg mx-3 px-6 py-3 ${isFAQopen ? 'border border-solid border-blue-400' : ''}`}>
        <div className='flex flex-row justify-between w-full'>
            <h1 className="text-black text-start pr-5 text-lg mq480:text-xl font-bold font-subhead">{question}</h1>
            {!isFAQopen ? (
              <Plus onClick={toggleButton} className='pt-4 pl-4 cursor-pointer stroke-zinc-900 w-[30px] h-[30px] min-w-[20px] min-h-[20px] mq480:min-w-[30px] mq480:min-h-[30px]'/>
            ) : (
              <Minus onClick={toggleButton} className='pt-3 pl-4 cursor-pointer stroke-zinc-900 w-8 h-8'/>
            )}
        </div>
        {isFAQopen && 
        <div className="text-start ml-[-25px] h-auto text-base text-zinc-400 leading-[30px] bg-transparent max-w-[90%] px-6 py-3">
            {answer}
        </div>
        }
    </div>
  );
};

export default FAQItem;
