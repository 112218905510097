import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../../components/User/Header";
import UserFooter from "../../../components/User/UserFooter";
import { X, ArrowLeft } from "lucide-react";
import SideNavUserProfile from "../../../components/User/SideNavUserProfile";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

const CloseAccount: FunctionComponent = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [accountActive, setAccountActive] = useState(true);
  const [deletionRequestSent, setDeletionRequestSent] = useState(false);

  const isValidEmail = email === 'Confirm Deletion';

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCloseAccount = async () => {
    if (isValidEmail) {
      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          console.error('No authentication token found. Please log in.');
          return;
        }

        const response = await axios.post(
          `${API_BASE_URL}/v1/users/account-deletion/request`,
          {},  // empty payload
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        if (response.status === 200 || response.status === 204) {
          setDeletionRequestSent(true);
          // Optionally, you can set a timeout to hide the message after a few seconds
          // setTimeout(() => setDeletionRequestSent(false), 5000);
        }
      } catch (error) {
        console.error('Failed to send account deletion request:', error);
        // Optionally, you can set an error state here and display it to the user
      }
    }
  };

  return (
    <>
      {/* Desktop (>=780px) */}
      <div className="hidden mq780:flex flex-col min-h-screen">
        <Header />
        <div className="flex flex-1 bg-gray-100">
          <SideNavUserProfile activeItem="Close Account" />
          <div className="flex-1 p-8 font-subhead">
            <div className="hidden bg-white rounded-lg p-6 max-w-2xl mx-auto">
              <h2 className="text-2xl font-semibold text-blue-900 mb-6">Close Account</h2>
              {accountActive ? (
                <>
                  <p className="text-lg font-semibold mb-4">
                    Please enter the text 'Confirm Deletion' to finish closing your account.
                  </p>
                  <p className="text-base text-gray-600 mb-6">
                    Your account will be closed once you click 'Close Account'. Once your
                    account is closed, you will not be able to log in or have access to your
                    content.
                  </p>
                  <input
                    type="email"
                    placeholder="Confirm Deletion"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-[95%] px-3 py-2 border border-gray-300 rounded-md mb-6"
                  />
                  <button
                    onClick={handleCloseAccount}
                    className={`w-full py-2 px-4 cursor-pointer rounded-md ${
                      isValidEmail
                        ? 'bg-blue-800 text-white hover:bg-[#478abf]'
                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    }`}
                    disabled={!isValidEmail}
                  >
                    Close Account
                  </button>
                  {deletionRequestSent && (
                    <p className="text-red-500 mt-2 text-center">Deletion Request Received</p>
                  )}
                </>
              ) : (
                <>
                  <p className="text-xl font-semibold text-center mb-6">
                    Your Account has been closed.
                  </p>
                  <button
                    onClick={() => navigate('/')}
                    className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-[#478abf]"
                  >
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </div>

      {/* Mobile (<780px) */}
      <div className="mq780:hidden w-full relative bg-white overflow-hidden flex flex-col justify-between px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead min-h-screen">
        <div className="flex justify-between items-center px-4 mt-3 mb-1">
            <button onClick={handleBackClick} className="text-gray-500 pt-2 bg-white">
                <ArrowLeft className='w-7 h-7 stroke-gray-300' />
            </button>
            <h2 className="text-xl font-semibold">Close Account</h2>
            <ArrowLeft className='w-7 h-7 stroke-white' />
        </div>
        <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
        {accountActive ? 
        (<div className="px-5 h-[90vh] flex flex-col">
            <p className="w-[80vw] leading-7 ml-2 mt-10 text-gray-700 text-lg font-semibold text-start mb-4">
                Please enter 'Confirm Deletion' to finish deleting your account.
            </p>
            <div className="items-center justify-center">
                <p className="w-[80vw] leading-5 ml-2 mt-4 text-gray-700 font-base text-start mb-10">
                    Your account will be closed once you click 'Close Account'. Once your account is
                    closed, you will not be able to log in or have access to your content.
                </p>
            </div>
            <div className="hidden items-center justify-center">
                <input
                    type="email"
                    placeholder="janedoe@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-[80vw] px-3 py-3 border border-gray-300 text-base rounded-md mb-4 font-subhead"
                />
            </div>
            <div className="hidden w-full flex flex-col items-center justify-center">
                <button
                    onClick={handleCloseAccount}
                    className={`w-48 font-subhead font-semibold mt-6 py-3 px-4 rounded-md ${
                      isValidEmail
                      ? 'bg-blue-800 text-white hover:bg-[#478abf]'
                      : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    }`}
                    disabled={!isValidEmail}
                >
                    Close Account
                </button>
                {deletionRequestSent && (
                  <p className="text-red-500 mt-2">Deletion Request Received</p>
                )}
            </div>
        </div>)
        :
        (<div className="px-5 h-[90vh] flex flex-col">
            <div className="mt-10 flex flex-row items-center justify-center">
                <p className="w-full text-center text-xl leading-7 mx-5 ml-8 mt-10 text-gray-700 text-lg font-semibold mb-4 text-center">
                    Your Account has been closed.
                </p>
            </div>
            <div className="w-full flex items-center justify-center">
                <button
                    onClick={() => navigate('/')}
                    className="w-48 font-subhead font-semibold mt-10 py-3 px-4 rounded-md text-lg bg-blue-500 text-white"
                >
                    Close
                </button>
            </div>
        </div>)}
        <footer>
            <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
            />
        </footer>
      </div>
    </>
  );
};

export default CloseAccount;