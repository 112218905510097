import React from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import UserFooter from "../../components/User/UserFooter";
import Header from "../../components/User/Header";
import LogInForm from "../../components/User/LogInForm";

const H41LogInForm: React.FC = () => {
  const handleGoogleSignUp = () => {
    // console.log('Google sign up initiated');
    // Implement your Google sign-up logic here
  };

  const handleFacebookSignUp = () => {
    // console.log('Facebook sign up initiated');
    // Implement your Facebook sign-up logic here
  };

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
      <Header />
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ||""}>
        <LogInForm
          onGoogleSignUp={handleGoogleSignUp}
          onFacebookSignUp={handleFacebookSignUp}
        />
      </GoogleReCaptchaProvider>
      <UserFooter
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </div>
  );
};

export default H41LogInForm;