import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { EyeOff, Eye } from 'lucide-react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Modal from '../Merchant/Modal';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface LoginFormProps {
  onGoogleSignUp: () => void;
  onFacebookSignUp: () => void;
}

interface ResetPasswordModalProps {
  resetEmail: string;
  setResetEmail: React.Dispatch<React.SetStateAction<string>>;
  resetSuccess: boolean;
  onClose: () => void;
  onSend: () => void;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  resetEmail,
  setResetEmail,
  resetSuccess,
  onClose,
  onSend
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSend = async () => {
    setIsButtonDisabled(true);
    setErrorMessage('');
    try {
      await onSend();
    } catch (error) {
      setErrorMessage('Email not found. Please enter a correct email.');
    }
    setTimeout(() => setIsButtonDisabled(false), 3000);
  };

  return ReactDOM.createPortal(
    <div className="font-subhead fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg min-w-[250px] max-w-[420px] mx-3">
        <h2 className="text-xl mb-4">Reset Password</h2>
        <div className="flex flex-col mb-4">
          <input
            type="email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-[95%] p-2 border rounded mb-2"
          />
          {errorMessage && (
            <p className="text-red-500 text-sm mb-2">{errorMessage}</p>
          )}
          {resetSuccess && (
            <p className="text-green text-sm mb-4">Password Reset Email sent!</p>
          )}
          <button
            onClick={handleSend}
            disabled={!resetEmail || isButtonDisabled}
            className={`px-4 py-2 rounded ${
              resetEmail && !isButtonDisabled
                ? 'bg-main-teal text-white cursor-pointer'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Send
          </button>
        </div>
        <button
          onClick={onClose}
          className="w-full ml-1 text-center text-zinc-500 pl-0 ml-0 bg-white cursor-pointer"
        >
          Close
        </button>
      </div>
    </div>,
    document.body
  );
};

function LogInForm({ onGoogleSignUp, onFacebookSignUp }: LoginFormProps) {
  const [errorModal, setErrorModal] = useState({
    isOpen: false,
    title: '',
    message: ''
  });
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isReCaptchaReady, setIsReCaptchaReady] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);

  const handleSignUpClick = () => navigate('/signup');
  const handleToSClick = () => navigate("/terms-of-service");
  const handlePPClick = () => navigate("/privacy-policy");

  const handleGoogleSignUp = () => {
    window.location.href = `${API_BASE_URL}/oauth2/authorization/google`;
  };
  
  const handleFacebookSignUp = () => {
    window.location.href = `${API_BASE_URL}/oauth2/authorization/facebook`;
  };

  useEffect(() => {
    if (executeRecaptcha) {
      setIsReCaptchaReady(true);
    }
  }, [executeRecaptcha]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!executeRecaptcha) {
      setErrorModal({
        isOpen: true,
        title: 'Verification Error',
        message: 'ReCaptcha not yet available'
      });
      return;
    }
    try {
      const recaptchaToken = await executeRecaptcha('login');
      const response = await axios.post(`${API_BASE_URL}/v1/users/auth/login`, {
        email,
        password,
        recaptchaResponse: recaptchaToken,
      });
  
      if (response.status === 200 || response.status === 201) {
        const { token, userType } = response.data;
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('userType', userType);
        if(userType == "MERCHANT") {
          navigate('/merchant-hub-home');
        } else {
          navigate('/');
        }
      }
    } catch (error: any) {
      console.error('Login failed:', error);
      setErrorModal({
        isOpen: true,
        title: 'Login Error',
        message: error.response?.data?.message || 'Login failed. Please check your credentials and try again.'
      });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const errorType = params.get('error');
    const errorMessage = params.get('message');
  
    if (errorType || errorMessage) {
      const title = errorType === 'authentication_failed' ? 'Authentication Failed' : 
                   errorType === 'internal_server_error' ? 'Server Error' : 
                   'Authentication Error';
  
      setErrorModal({
        isOpen: true,
        title,
        message: errorMessage ? decodeURIComponent(errorMessage) : 
                 'An error occurred during authentication.'
      });
  
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, []);

  const sendPasswordResetRequest = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/users/auth/password-reset/request`,
        { email: resetEmail }
      );
  
      if (response.status === 200 || response.status === 204) {
        setResetSuccess(true);
      }
    } catch (error) {
      console.error('Failed to request password reset:', error);
      setErrorMessage('Failed to request password reset. Please try again.');
      throw error;
    }
  };

  return (
    <div className="min-h-[80%] bg-gray-200 flex justify-center items-center transform py-20">
      <div className="bg-white rounded-lg flex overflow-hidden py-10">
        {/* Profile Image */}
        <div className="hidden lg:block lg:w-1/2 pl-10" style={{ maxWidth: '512px' }}>
          <img
            alt="Family"
            src="family-vacation.png"
            className="w-full h-full object-cover"
            style={{ borderRadius: '50%', width: '400px', height: '400px' }}
            crossOrigin="anonymous"
          />
        </div>
        
        {/* Form Section */}
        <div className="lg:w-96 w-full pt-15 items-center justify-center max-w-[88vw] lg:px-20">
          <h2 className="text-4xl font-black text-center mb-2">Log in to DoYouWeekend</h2>
          <div className="text-center font-bold text-sm mb-4">
            New User? <a href="#" className="text-blue-900 hover:underline" onClick={handleSignUpClick}>Sign up</a>
          </div>
          <div className="text-center text-xs mb-0 mt-10">
            By Logging in, you agree to DoYouWeekend's{' '}
            <a onClick={handleToSClick} className="cursor-pointer text-blue-300">Terms of Service</a> and{' '}
            <a onClick={handlePPClick} className="cursor-pointer text-blue-300">Privacy Policy</a>
          </div>

          {/* Social Buttons */}
          <div className="flex flex-col mb-4 w-full">
            <button
              onClick={handleGoogleSignUp}
              className="font-black cursor-pointer flex gap-5 items-start px-16 py-3 mt-2.5 tracking-tight text-center bg-white rounded-lg border border-solid border-neutral-500 max-md:flex-wrap max-md:px-5 max-md:max-w-full"
            >
              <img src="/google-icon.png" alt="Google" className="shrink-0 self-start aspect-square w-[29px]" crossOrigin="anonymous" />
              <span className="flex-auto my-auto text-zinc-800 font-semibold font-subhead">Log in with Google</span>
            </button>
            {/* <button
              onClick={handleFacebookSignUp}
              className="font-black cursor-pointer flex gap-5 items-start px-16 py-3 mt-2.5 tracking-tight text-center bg-white rounded-lg border border-solid border-neutral-500 max-md:flex-wrap max-md:px-5 max-md:max-w-full"
            >
              <img src="/facebook-icon.png" alt="Facebook" className="shrink-0 self-start aspect-square w-[29px]" crossOrigin="anonymous" />
              <span className="flex-auto my-auto whitespace-nowrap text-zinc-800 font-semibold font-subhead">Log in with Facebook</span>
            </button> */}
          </div>
          
          <div className="text-center text-sm my-6">or</div>

          {/* Form Fields */}
          <form onSubmit={handleLogin} className="w-full">
            <div className="mb-6 flex flex-col gap-3 mr-5">
              <input 
                type="email" 
                id="email-input" 
                placeholder="Email" 
                className="w-full p-2 border border-light-gray rounded-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="w-full relative mb-4">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  className="w-full p-2 border border-light-gray rounded-md"
                  autoComplete="new-password"
                  id="new-password"
                  required
                />
                <span
                  className="absolute inset-y-0 right-0 -pr-4 flex items-center text-sm leading-5 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff className="stroke-grey-600 w-4 h-4" /> : <Eye className="stroke-grey-600 w-4 h-4" />}
                </span>
              </div>
              {resetSuccess ? (
                <p className="text-green zrxt-left text-xs ml-1 -mt-4">Password reset email has been sent</p>
              ) : (
                <p className="text-blue-500 text-xs ml-1 -mt-4 text-start cursor-pointer" onClick={() => setShowResetModal(true)}>
                  Forgot Password?
                </p>
              )}
            </div>
            {/* Log in Button */}
            <button 
              type="submit" 
              disabled={!isReCaptchaReady}
              className="cursor-pointer font-bold w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-700"
            >
              Log in
            </button>
            {errorMessage && (
              <p className="text-red-500 text-sm mt-2 text-center">{errorMessage}</p>
            )}
          </form>
        </div>
      </div>
        {showResetModal && (
        <ResetPasswordModal
          resetEmail={resetEmail}
          setResetEmail={setResetEmail}
          resetSuccess={resetSuccess}
          onClose={() => {
            setShowResetModal(false);
            setResetSuccess(false);
            setResetEmail("");
          }}
          onSend={sendPasswordResetRequest}
        />
      )}

    <Modal
      isOpen={errorModal.isOpen}
      onClose={() => setErrorModal(prev => ({ ...prev, isOpen: false }))}
      onAccept={() => setErrorModal(prev => ({ ...prev, isOpen: false }))}
      title={errorModal.title}
      customButtons={
        <button
          onClick={() => setErrorModal(prev => ({ ...prev, isOpen: false }))}
          className="px-4 py-2 bg-main-teal text-white rounded hover:bg-opacity-90 transition-colors duration-200"
        >
          OK
        </button>
      }
    >
      <div className="text-gray-700">{errorModal.message}</div>
    </Modal>
    </div>
  );
}

export default LogInForm;